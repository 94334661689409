/*
    ===============
        HOMEPAGE 
    ===============
*/

.maf-message-card{
    h2{
        font-size: 2.5em;   
        color: var(--maf-dark-blue); 
        letter-spacing: -0.71px;    
    }
    p{
        max-width: 544px;
        margin: 16px auto 32px; 
        font-weight: normal;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;      
    }
}

#maf-bleed{
    padding-left: 0px !important;
    @media (width: 768px) {
        padding-left: 16px !important;
    }
    @media (max-width: 767px) {
        margin-left: 16px;
    }

}

.maf-copy{
    max-width: 1140px;
    @media (max-width: 767px) {
        padding-left: 16px !important;
    }
    // margin-bottom: 3.5em;
}

.maf-message-category-card{    
    & > div{
        justify-content: space-between;
        margin: 0 0 3.5rem;
        .maf-left{            
            h2{
                width: 96%;
                font-size: 2.5em;
                margin-bottom: 0.4em;
                font-weight: normal;
                span{
                    font-family: "SourceSansPro Semibold";
                }
            }            
        }
        .maf-right{
            flex: 1.5;
            // margin-right: 9em;
            p{
                margin:  0;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .maf-message-card{
        h2{
            font-size: 3.5em;   
            letter-spacing: -1.00px;
            margin: 0 auto;
        }
        p{
            margin: 1.5em auto 2.66666em;
        }
    }
    .maf-copy{
        max-width: 1176px;
        margin: auto;
        // margin-bottom: 3.5rem;
    }
    .maf-message-category-card{    
        & > div{
            justify-content: space-between;  
            .maf-left{
                flex: 3;
            }
            .maf-right{
                flex: 1.5;
            }
        }
    }
}

@media only screen and (min-width: 1301px){
    .maf-message-category-card{    
        & > div{
            margin: 0 auto 3.5rem;
        }
    }
}

@include media-breakpoint-up(xxl) {
    .maf-message-category-card{
        // padding-left: 8.25em;
        & > div{
            &.maf-copy{
                // margin-bottom: 3.5em;
            }            
            .maf-left{            
                h2{
                    font-size: 3.5em;
                    margin-bottom: initial;
                    font-weight: normal;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.14;
                    letter-spacing: -1px;                  
                }
            }
        }
        .maf-component-category-cards{
            margin-top: 0;
            // margin-left: 132px;
        }
       
    }
}

@media only screen and (min-width: 265px) and (max-width: 768px) {

    .maf-copy{
        margin: auto;
        width: 100%  !important;
        // margin-bottom: 3rem;
    }

  }
  