// THIS IS ONLY FOR THE DESING SYSTEM LIBRARY
.ds-maf-text-light{
    color:#ffffff;
}

.maf-design-system {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    header{
        h1 {
            font-size: 2.3em;
            margin: 0;
            color: #ffffff;
            letter-spacing: 0.05em;
            text-transform: capitalize;
            font-family: "SourceSansPro Semibold";
            line-height: 1;
        }     
    }
    h2.maf-ds{
        font-size: 1.33em;
        margin: 0 0 1em;
        padding: 0.5em 1em;
        background: aliceblue;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: var(--maf-heading-h2);
        font-family: "SourceSansPro Semibold";
        line-height: 1;
    }
    h4.maf-ds{
        margin: 1em 0;
        padding: 0 0 0.5em;
        line-height: 1;
    }
    .maf-top-padding{
        padding-top: 10em;
        padding-bottom: 10em;
    }
} 

@include media-breakpoint-up(md) {
    .maf-design-system {
        .maf-top-padding{
            padding-top: 33em;
            padding-bottom: 43em;
        }
    }
}
