.filter-refinement {
  width: 100%;
  max-width: 1176px;
  height: auto;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
}

.bs-canvas-overlay-filter{
  display: none;
  opacity: .5;
  z-index: 999;
  top: 0;
}
.bs-canvas-overlay-filter.show{
  display: block;
}

.offcanvas-off-custom {
  -webkit-transition: none;
  transition: none;
}

.filter-section {
  padding: 0;
  margin-bottom: 24px;
  justify-content: flex-start;
  gap:8px;

  .custom-checkbox-filter input[type="checkbox"] {
    display: none;
  }

  /* Style for custom checkbox */
  .custom-checkbox-filter label::before {
    content: "";
    border: solid 1px #1d3557;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../img/ui-icons/icon_checks.svg") no-repeat center center;
    background-size: contain;
    cursor: pointer;
    border-radius: 4px;
  }

  /* Style for checked custom checkbox */
  .custom-checkbox-filter input[type="checkbox"]:checked + label::before {
    border: solid 1px #1d3557;
    background-color: #1d3557;
  }

  .filter-button {
    font-family: "SourceSansPro Regular";
    height: 38px;
    padding: 11px 24px;
    border: 1px solid #1d3557;
    color: #1d3557;
    border-radius: 100px;
    background-color: transparent;
    white-space: nowrap;
    font-size: 1rem;
    line-height: 1;
    display: flex;
    align-items: center;

    .filter-button-content {
      width: 55px;
      height: 16px;
      flex-grow: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 0;
    }

    @media (max-width: 768px) {
      .filter-section {       
        margin: 16px 0;
      }
      .filter-button-content-sort-m{
        gap: 3px !important;
        width: 46px !important;
      }
      .filter-button-content-all-m{
        gap: 3px !important;
        width: 52px !important;
      }
      
    }

    img {
      transition: transform 0.3s ease;
    }
  }

  .btn-focus {
    background-color: #1d3557;
    color: white;

    img {
      filter: brightness(0) invert(1);
      transform: rotate(-180deg);
    }
  }

  .btn-focus .rotate-0 {
    transform: rotate(0deg);
  }

  .refinement-option-button{
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
}
  .filter-options {
    margin-top: 6px;
    padding: 24px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 222px;
    z-index: 999;

    .options-list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      font-size: 1.125rem;
      color: #051424;
      font-family: "SourceSansPro Regular" !important;
      input {
        width: 20px;
        height: 20px;
      }
      .plp-check-text {
        gap: 8px;
        display: flex;
        align-items: center;
        height: 20px;
        width: 174px;
        line-height: 1;
        letter-spacing: normal;
        .sort-height {
          height: 20px;
          display: flex;
          align-items: center;
          .sort-label-text {
            width: 146px;
            height: 20px;
            flex-grow: 0;
            font-family: "SourceSansPro Regular" !important;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
          }
        }
      }
      .color-thumbnail {
        width: 18px;
        height: 18px;
        border-radius: 100%;
      }
      label {
        font-size: 1.125rem;
        color: #051424;
        font-family: "SourceSansPro Regular" !important;
      }
      input:checked + label {
        font-size: 1.125rem;
        color: #051424;
        font-family: "SourceSansPro Regular" !important;
      }
    }
  }
  #sort-button-desktop, #sort-button-mobile {
    width: 94px;
    .filter-button-content{
      width: 46px;
      min-width: 46px;
      max-width: 46px;
      gap: 3px;
      line-height: 1;
      letter-spacing: normal;
    }
  }
  #type-button {
    width: 103px;
    .filter-button-content{
      width: 55px;
      min-width: 55px;
      max-width: 55px;
      line-height: 1;
      letter-spacing: normal;
    }
  }
  #material-button {
    width: 125px;
    .filter-button-content{
      width: 77px;
      min-width: 77px;
      max-width: 77px;
      line-height: 1;
      letter-spacing: normal;
    }
  }
  #color-button {
    width: 107px;
    .filter-button-content{
      width: 59px;
      min-width: 59px;
      max-width: 59px;
      line-height: 1;
      letter-spacing: normal;
    }
  }
  #price-button {
    width: 104px;
    line-height: 1;
    letter-spacing: normal;
  }
  #collections-button {
    width: 145px;
  }
  #loe-button {
    width: 192px;
  }
  #af-button {
    width: 127px;
    .filter-button-content{
      width: 79px;
      min-width: 79px;
      max-width: 79px;
      gap: 3px;
      line-height: 1;
      letter-spacing: normal;
    }
  }
}

.gap-2 {
  gap: 0.375rem !important;
}

.gap-mobile-8 {
  gap: 0.5rem;
}

.filter-chips {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  &li:first-of-type{
    button{
      padding: 0;
    }    
  }
  @media only screen and (max-width: 1024px) {
    display: none; // Hide filter chips on mobile devices
  }
}

.chip {
  font-family: "SourceSansPro Regular";
  padding: 8px 12px;
  background-color: #e9eff3;
  border-radius: 100px;
  color: #1d3557;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .filter-chips {
    flex-wrap: wrap;
  }

  .chip {
    margin-bottom: 8px;
  }
}

.sidebar-header {
  height: 24px;
  width: 288px;
  font-size: 12px;
  line-height: 1.5;
  color: #457c9d;
  font-weight: bold;
  margin-bottom: 35px;
  font-family: "SourceSansPro Bold";
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .sidebar-header {
    margin-bottom: 34px;
  }
}
.sidebar-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .sidebar-option-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-left: 4px;
  }
  
  .sidebar-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    // width: 288px;
    height: 24px;
  }
  
  .sidebar-option-text {
    height: 19px;
    display: flex;
    font-size: 18px;
    font-weight: normal;
    color: #051424;
    line-height: 1;
    letter-spacing: normal;
    font-family: "SourceSansPro SemiBold";
  }
  .sidebar-checkbox {
    height: 20px;
    width: 20px;
  }
    
  @media (max-width: 768px) {
    .sidebar-option-container {
      margin-left: 1px;
      gap: 29px;
    }
  }  
}
.apply-result{
  left: 51.5px !important;
}
.sidebar-option-buttons {
  display: flex;
  gap: 24px;
  justify-content: center;
  padding: 20px;

  button {
    border-radius: 24px;
    height: 48px;
    padding: 14px 23px 14px 25px;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: #1d3557;
    background-color: white;
    border: 1px solid #1d3557;
    font-family: "SourceSansPro Semibold";

    &:last-child {
      color: white;
      background-color: #1d3557;
    }
  }
  button div {
    width: 57px;
  }
  .clearAllSidebar {
    width: 105px;
    padding: 14px 23px 14px 25px;
  }
  .clearAllDiv {
    padding: 14px 23px 14px 25px;
  }
  .seeResultsSide {
    width: 127px;
    padding: 14px 24px 14px 24px;
  }
  .sortCancelButton {
    width: 105px;
    padding: 14px 29px 14px 30px;
    div{
      width: 46px;
      height: 20px;
    }
  }
  .sortApplyButton {
    width: 88px;
    padding: 14px 4px;
    display: flex;
    justify-content: center;
    
    div{
      width: 40px;
      height: 20px;
    }
  }
  .resultsCanvas {
    width: 79px;
  }
}

.sidebar-backButton {
  cursor: pointer;
}

.filter-container-design {
  padding: 0;
  display: flex;
  justify-content: center;  
}

.mobile-text {
  display: none;
}
@media only screen and (max-width: 768px) {
  .desktop-text {
    display: none;
  }
  .mobile-text {
    display: inline;
  }
}

.custom-canvas-plp {
  margin-right: 28px;
  margin-top: 25px;
  padding: 0;
  justify-content: end;
  padding: 0;
}
.custom-canvas-plp-sort {
  margin-right: 28px;
  margin-top: 25px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-left: 28px;
}

.canvas-off-plp {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 35px;
  padding-top: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .custom-checkbox-filter input[type="checkbox"] {
    display: none;
  }

  /* Style for custom checkbox */
  .custom-checkbox-filter label::before {
    content: "";
    border: solid 1px #1d3557;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../img/ui-icons/icon_checks.svg") no-repeat center center;
    background-size: contain;
    cursor: pointer;
    border-radius: 4px;
  }

  /* Style for checked custom checkbox */
  .custom-checkbox-filter input[type="checkbox"]:checked + label::before {
    border: solid 1px #1d3557;
    background-color: #1d3557;
  }
}
.canvas-second-mobile {
  padding-bottom: 0;
  padding-right: 28px;
  padding-top: 25px;
  padding-left: 28px;
}

.color-thumbnail-m {
  width: 18px;
  height: 18px;
  border-radius: 100%;
}

@media only screen and (max-width: 768px) {
  .filter-section #af-button {
    max-width: 100px;
    .filter-button-content{
      width: 55px;
      min-width: 55px;
      max-width: 55px;
      gap: 3px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .loen {
    display: none !important;
  }
}

/* Custom radio button container */
.custom-radio-container input[type="radio"] {
  display: none;
}

/* Style the custom radio button */
.custom-radio-container input[type="radio"] + label {
  position: relative;
  display: inline-block;
  padding-left: 28px;
  cursor: pointer;
}

/* Style the custom radio button outer circle */
.custom-radio-container input[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
  padding: 4px;
  border: 1px solid #051424;
  border-radius: 100px;
}

/* Style the custom radio button inner circle (selected state) */
.custom-radio-container input[type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 12px;
  height: 12px;
  background-color: #1d3557;
  border-radius: 100%;
}

/* Style the custom radio button label text */
.custom-radio-container input[type="radio"] + label:after {
  content: "";
  position: relative;
  top: -2px;
}

/* Prevent the size change on click */
.custom-radio-container input[type="radio"]:focus + label:before {
  outline: none;
}

/* Clear All Chip Styling */
.clear-all{
  width: 79px;
}

#allFilters + .offcanvas-backdrop{
  background-color: transparent ;
}
.custom-background .offcanvas-backdrop{
  background-color: transparent ;
}

// #allFilters + .offcanvas-backdrop{
//   background-color: #000;
// }

.offcanvas-close-button{
  cursor: pointer;
}

.custom-filter-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: .5;
  height: 100vh;
  width: 100vw;
  z-index: 1030;
}

.offcanvas{
  &.show{
    z-index: 9999;
  }  
}