#accordionExample-CustomizeId{
  gap: 24px;
  @media (max-width: 767px) {
    gap: 16px;
  }
}
.customizeid-container {
  max-width: 1176px;
  margin: auto;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
 
  .customizeid-header {
    font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
    font-size: 56px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -1px;
    text-align: center;
    color: #051424;
    margin-top: 56px;
    margin-bottom: 40px;
    position: relative;
    span {
      color: #e63946;
      font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
    }
    @media (max-width: 592px) {
      font-size: 24px;
      margin-top: 32px;
      margin-bottom: 16px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      width: 690px;
      margin-left: auto;
      margin-right: auto;
    }
    .goback{
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.05;
      letter-spacing: normal;
      text-align: left;
      color: #1d3557;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 20px;
      cursor: pointer;
      @media (max-width: 767px) {
        position: unset;
        margin-top: 0;
        margin-bottom: 24px;
      }
      .gobackImage{
        width: 14px;
        height: 14px;    
        transform: rotate(270deg);  
      }
    }
  }
  .product-container {
    display: flex;
    justify-content: center;
    .product-container-row {
      @media (max-width: 1200px) {
        justify-content: center;
      }
      width: 100%;
      .product-container-col {
        @media (max-width: 767px) {
          flex: auto;
        }
      }
    }
    .customizeid-product-title {
      height: 24px;
      align-self: stretch;
      flex-grow: 0;
      font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-bottom: 26px;
      @media (max-width: 767px) {
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.86;
        letter-spacing: normal;
        margin-bottom: 16px;
      }
    }
    .customizeid-product-color {
      margin-bottom: 26px;
      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
      .title {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        margin-bottom: 16px;
        @media (max-width: 767px) {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
        }
        span {
          font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
          font-weight: normal;
        }
      }
      .colors {
        display: flex;
        flex-direction: row;
        gap: 8px;
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
        .color {
          cursor: pointer;
          width: 44px;
          height: 44px;
          flex-grow: 0;
          border-radius: 50%;
          @media (max-width: 767px) {
            width: 32px;
            height: 32px;
          }
        }
        .customize-color-active {
          height: 48px;
          width: 48px;
          padding: 2px;
          border-radius: 50%;
          background-color: #fff;
          border: 2px solid #1d3557;
          @media (max-width: 767px) {
            height: 36px;
            width: 36px;
            padding: 2px;
            border-radius: 50%;
            background-color: #fff;
            border: 2px solid #1d3557;
          }
          .color {
            width: 40px;
            height: 40px;
            @media (max-width: 767px) {
              width: 28px;
              height: 28px;
            }
          }
        }
      }
    }
    .customizeid-sizechart {
      margin-bottom: 26px;
      .title {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        margin-bottom: 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        @media (max-width: 767px) {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
        }
        span {
          font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
          font-weight: normal;
        }
        .content {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          text-decoration: underline;
        }
        .question-circle {
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          background-color: #fff; /* Circle color */
          border-radius: 50%;
          border: solid 1.5px #051424;
        }
        .question-circle::before {
          content: "?";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: black;
          font-size: 10px; /* Adjust the size */
          font-weight: bold;
          font-family: Arial, sans-serif;
        }
      }
      .size-containers {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 26px;
        flex-wrap: wrap;
        @media (max-width: 767px) {
          margin-bottom: 16px;
        }
        .customize-size-container-active {
          background-color: #fff;
          padding: 2px;
          border: 2px solid #1d3557;
          border-radius: 100px;
          width: 59px;
          height: 32px;
          @media (max-width: 767px) {
            height: 30px;
            width: 56px;
          }
          .size {
            height: 24px;
            width: 51px;
            @media (max-width: 767px) {
              height: 22px;
              width: 48px;
            }
          }
        }
        .size {
          cursor: pointer;
          display: flex;
          width: 59px;
          height: 32px;
          justify-content: center;
          align-items: center;
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          padding: 8px 16px;
          border-radius: 100px;
          background-color: #e9eff3;
          @media (max-width: 767px) {
            font-size: 14px;
            height: 30px;
            width: 56px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
          }
        }
      }
    }
    .customizeid-button {
      width: 608px;
      button {
        width: 100%;
        margin: 0;
      }
      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        width: 690px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .customizeid-col {
      max-width: 608px;
      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        max-width: 690px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }

    .product-Image-container {
      @media (max-width: 1200px) {
        justify-content: center;
        margin-bottom: 26px;
      }
      @media (max-width: 767px) {
        flex-direction: column-reverse;
        margin-bottom: 16px;
        gap: 16px;
      }
      .Product-image {
        width: 432px;
        height: 432px;
        flex-grow: 0;
        margin: 0 0 0 16px;
        padding: 36px;
        border-radius: 14px;
        background-color: #f0f9f9;

        @media (max-width: 767px) {
          margin: 0;
          width: 100%;
          height: 100%;
          padding: 16px;
        }

        @media only screen and (min-width: 768px) and (max-width: 1200px) {
          width: 578px;
          height: 428px;
          flex-grow: 0;
          margin: 0 0 2px 24px;
          padding: 32px 107px;
          border-radius: 14px;
        }

        img {
          height: 100%;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }

      .product-colors-images-container {
        position: relative;
        height: 428px; /* Fixed height for the container */
        overflow: hidden; /* Hide overflowing images */
        @media (max-width: 767px) {
          height: auto;
        }
        .images-wrapper-container {
          height: calc(
            428px - 40px
          ); /* Reduce container height to account for buttons (20px for each button) */
          overflow: hidden; /* Hide the overflowing images */
          @media (max-width: 767px) {
            height: auto;
          }
        }
        .images-wrapper {
          display: flex;
          flex-direction: column;
          transition: transform 0.5s ease; /* Smooth sliding transition */
          @media (max-width: 767px) {
            flex-direction: row;
            gap: 8px;
            flex-wrap: wrap;
            transform: none !important;
          }
        }

        .product-colors-image-border {
          border: solid 1px #457c9d;
        }

        .product-colors-image {
          cursor: pointer;
          width: 88px;
          height: 88px;
          flex-grow: 0;
          margin: 4px 0;
          padding: 12px;
          border-radius: 14px;
          background-color: #f0f9f9;
          @media (max-width: 767px) {
            margin: 0;
            width: 64px;
            height: 64px;
            flex-grow: 0;
            padding: 4px;
            border-radius: 8px;
          }
          .p-image {
            height: 100%;
          }
        }
        button {
          padding: 0;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (max-width: 767px) {
            display: none;
          }
        }
        .up-button {
          background: none;
          border: none;
          width: 100%;
          // position: absolute;
          z-index: 2;
          margin-bottom: 4px;
          img {
            width: 18px;
            height: 10px;
            object-fit: cover;
          }
        }
        .down-button {
          background: none;
          border: none;
          width: 100%;
          margin-top: 4px;
          // position: absolute;
          bottom: 0;
          z-index: 2;
          img {
            width: 18px;
            height: 10px;
            object-fit: cover;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.Customize-id-accordian {
  margin-bottom: 64px !important;
  @media (max-width: 767px) {
    margin-bottom: 80px !important;
  }
  @media (min-width: 1200px) {
    max-width: 1176px !important;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    max-width: 690px !important;
  }
  .row {
    margin: 0 !important;
  }
  .plp-Accordian-item {
    width: 100%;
    height: 68px;
    max-height: none;
    min-height: 68px;
    max-width: 100% !important;
    gap: 8px;
    cursor: pointer;
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      padding: 24px 32px;
    }
  }
  .plp-Accordian-button {
    height: 100%;
    padding: 0;
    margin-left: 0;
    justify-content: space-between;
    cursor:  pointer !important;
    .plp-Accordian-header-text {
      font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
    }
  }
  .plp-Accordian-header {
    width: 100%;
  }
  .plp-Accordian-header-text {
    min-width: none;
    margin: 0;
  }
  .plp-Accordian-body {
    width: 100%;
    margin-left: 0;
    flex-grow: 0;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    max-width: 100% !important;
    @media (max-width: 767px) {
      font-size: 14px;
    }

    .accordion-header-element {
      font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-top: 16px;
      margin-bottom: 8px;
    }

   
  }
  .read-more {
    flex-grow: 0;
    font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #457c9d;
    cursor: pointer;
    text-decoration: underline;
    @media (min-width: 1200px) {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .plp-Accordian-body {
      max-height: 126px;
      overflow: hidden;
      position: relative;
    }
    .plp-Accordian-body.expanded {
      max-height: 100%;
      overflow: visible;
    }
  }
  /* Target screens between 768px and 1200px */
  @media (min-width: 768px) and (max-width: 1200px) {
    .plp-Accordian-body {
      max-height: 128px;
      overflow: hidden;
      position: relative;
    }
    .plp-Accordian-body.expanded {
      max-height: 100%;
      overflow: visible;
    }
  }
}
