/*
    ========================
        BUTTON SCSS FILE
    ========================
*/

button,
a{       
    &.nav-link{
        &:focus-visible{
            border-color: var(--maf-dodger-blue);
            outline: -webkit-focus-ring-color auto 1px;
            box-shadow: 0px 0px 5px var(--maf-dodger-blue);
            background: none;
        }
    }
    &.btn{
        font-family: "SourceSansPro Semibold";
        min-width: 87px;
        border-radius: 1.5em;
        margin: 1em 0 0;
        max-height: 48px;
        min-height: 48px;
        height: 100%;
        padding: 0 1.5em;
        &.btn-success{
            &:hover,
            &:focus{
                color: var(--maf-success-text-hover);
            }

        }

        &.btn-flat{
            color: var(--maf-dark-blue);
            &:hover,
            &:focus{
                border: solid 1px var(--maf-iron);
                background: var(--maf-iron);
                color: var(--maf-dark-blue);              
            }
        }

        &.btn-warning{
            &:hover,
            &:focus{
                color: var(--maf-success-text-hover);
            }

        }

        &.btn-outline-primary{
            color:var(--maf-primary-outline-font);
            &:hover,
            &:focus{
                color: var(--maf-success-text-hover);  
                background-color:var(--maf-dark-blue);              
                svg{
                    path{
                        fill:var(--maf-primary-text);
                    }                    
                }
            }
        }
        &.btn-outline-primary-donate{
            color:var(--maf-primary-outline-font);
            --bs-btn-border-color: var(--maf-dark-blue);
            --bs-btn-hover-bg: #fff;
            &:focus{
                box-shadow: 0 8px 16px 0 rgba(29, 53, 87, 0.15);
                border: solid 1px #1d3557;
                background-color: rgba(29, 53, 87, 0.1);
                color: #1d3557;
            }
            &:hover{
                background-color:var(--maf-dark-blue);
                color: var(--maf-success-text-hover);                
            }
        }

        &.btn-light{
            color:var(--maf-primary-outline-font);
        }

        &.btn-sm{
            padding: 0.6875em 1.5em;
        }

        &.btn-maf-light-blue{
            background-color: var(--btn-maf-light-blue);
            color:$light;
        }

        &.btn-maf-lighter-blue{
            background-color: var(--btn-maf-lighter-blue);
            color:$dark;
        }

        &.btn-maf-lightest-blue{
            background-color: var(--btn-maf-lightest-blue);
            color:var(--dark);
        }        
        
        &:hover,
        &:focus{
            background-color: var(--maf-primary-hover);
            border-color: var(--maf-primary-hover);
            color:$light;
            border: solid 1px var(--maf-primary-hover);
            box-shadow: initial;
        }
        &:focus-visible {            
            border-color: var(--maf-dodger-blue);
            outline: -webkit-focus-ring-color auto 1px;
            box-shadow: 0px 0px 5px var(--maf-dodger-blue);
            background: none;
        }        
        
        &.btn-info{
            &:hover,
            &:focus{
                background-color: $primary !important;
                color:$light;
            }
        }
        
        &.btn-maf-light-blue{
            &:hover,
            &:focus{
                background-color: $primary !important;
                color:$light;
            }            
        } 
        &.btn-icon-only{
            min-width: auto;
            padding: 1em;
            line-height: 0;
            border-radius: 25px;
            i{
                margin: 0;
            }
        }
    }
    &.btn-primary{
        svg{
            width: 14px;
            height: 14px;
            margin: 0 0 0 0.5em;
            path{
                fill:var(--maf-primary-text);
            }
        }
        &:hover{
            border-color: var(--maf-primary-hover);
        }
    }
    &.btn-outline-primary{
        border-color: var(--maf-primary-hover);
    }    
    &.navbar-toggler{
        border: none;
    }
}

a{
    &.btn{
        padding: 0.5875em 1.5em;
        line-height: 1.7;
        font-family: "SourceSansPro Semibold";
        font-weight: 500;
        font-weight: normal;
    }
    &.maf-nav-link-chip{
        position:relative;
        display: inline-block;
        padding: 0.8127em;
        line-height: 0;
    }
}

/*
    =============================
        TEXT LINK ANIMATION 1
    =============================
*/

ul{
    &.maf-text-links-list{
        a,
        a:visited,
        a:hover{
            -webkit-backface-visibility:hidden;
            backface-visibility:hidden;
            position:relative;
            transition:0.5s color ease;
            text-decoration:none;
            &:after{
                text-decoration:none;
                content: "";
                transition:0.5s all ease;
                -webkit-backface-visibility:hidden;
                backface-visibility:hidden;
                position:absolute;
                bottom:-0.25em;
                height:1px;
                width:0;
                left:50%;
                -webkit-transform:translateX(-50%);
                transform:translateX(-50%);
            }
            &:hover{
                &:after{
                    width:100%;
                }
            }
        }
    }
}
