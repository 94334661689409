// [START] Primary Colors
.bg-maf-dark-blue{
    background: $maf-dark-blue;
    color: $light;
}

.bg-maf-light-blue{
    background: $maf-light-blue;
    color: $light;
}

.bg-maf-lighter-blue{
    background: $maf-lighter-blue;
    color: $dark;
}

.bg-maf-lightest-blue{
    background: $maf-lightest-blue;
    color: $dark;
}
.bg-maf-red{
    background: $maf-red;
    color: $light;
}
// [END] Primary Colors

// [START] Gray Scale Colors
.bg-maf-dark{
    background: $dark;
    color: $light;
}

.bg-maf-light-dark{
    background: $maf-light-dark;
    color: $light;
}

.bg-maf-lighter-dark{
    background: $maf-lighter-dark;
    color: $light;
}

.bg-maf-lightest-dark{
    background: $maf-lightest-dark;
    color: $dark;
}
// [END] Gray Scale Colors

// [START] Secondary Colors
.bg-maf-spruce{
    background: $maf-spruce;
    color: $light;
}

.bg-maf-iron{
    background: $maf-iron;
    color: $dark;
}

.bg-maf-white-smoke{
    background: $maf-white-smoke;
    color: $dark;
}

.bg-maf-mist{
    background: $maf-mist;
    color: $dark;
}
// [END] Secondary Colors

// [START] Extended Colors
.bg-maf-orangey-yellow{
    background: $maf-orangey-yellow;
    color: $dark;
}

.bg-maf-maize{
    background: $maf-maize;
    color: $dark;
}

.bg-maf-kelly-green{
    background: $maf-kelly-green;
    color: $dark;
}

.bg-maf-kelly-green{
    background: $maf-kelly-green;
    color: $dark;
}

.bg-maf-deep-sky-blue{
    background: $maf-deep-sky-blue;
    color: $dark;
}

.bg-maf-dodger-blue{
    background: $maf-dodger-blue;
    color: $light;
}

.bg-maf-bright-violet{
    background: $maf-bright-violet;
    color: $light;
}

.bg-maf-torch-red{
    background: $maf-torch-red;
    color: $light;
}

.bg-maf-mantis{
    background: $maf-mantis;
    color: $light;
}

.bg-maf-pink-raspberry{
    background: $maf-pink-raspberry;
    color: $light;
}

.bg-maf-sandstorm{
    background: $maf-sandstorm;
    color: $light;
}
// [END] Extended Colors

.bg-primary-subtle{
    background-color: $maf-lighter-blue !important;
}

.bg-secondary-subtle{
    background-color: $maf-iron !important;
}

.bg-info-subtle{
    background-color: $maf-lightest-blue !important;
}

.bg-dark-subtle{
    background-color: $maf-lightest-dark !important;
}

.bg-success-subtle{
    background-color: $maf-mantis !important;
}
