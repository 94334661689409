/*
    ========================
        HEADER SCSS FILE
    ========================
*/



#on-search-mobile {
  margin-top: 16px;
  #ProductList-Mobile {
    list-style-type: none;
    padding: 0;
    // height: 75vh;
    & > li {
      margin-bottom: 16px;
      .product-list-image {
        mix-blend-mode: darken;
      }
    }
    & > li:last-child {
      margin-bottom: 0 !important;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-height: 800px) {
      // height: 70vh;
    }
  }

  #ArticleList-Mobile {
    list-style-type: none;
    padding: 0;
    // height: 75vh;
    & > li:last-child {
      margin-bottom: 0 !important;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .article-list-item {
      height: 48px;
      padding: 15px 0;
      margin-bottom: 4px !important;
      .row{
        justify-content: space-between;
      }
      .article-title {
        width: 293px;
        height: 18px;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        padding: 0;
      }
      .article-arrow-right {
        height: 18px;
        width: 18px;
        padding: 0;
      }
      // .match-text{
      //     color: #457c9d;
      //     font-weight: 600;
      // }
    }

    & > li {
      margin-bottom: 16px;
    }
    @media (max-height: 800px) {
      // height: 70vh;
    }
  }
  .nav-link {
    font-size: 15px;
  }
  .View-All-Result-Mobile {
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    height: 26px;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.53;
    gap: 8px;
    img {
      width: 18px;
      height: 14px;
      color: #051424;
      object-fit: contain;
    }
  }
}

.searchMenu-Desktop-result-class{
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  transition: opacity 0.8s ease-in-out, max-height 0.5s ease-in-out;
  // transition: opacity 0.8s ease-in;
  
}

.searchMenu-Desktop-result-visible{
  opacity: 1;
  max-height:424px;
  visibility: visible;
}

#on-search-mobile{
  .search-result-tabs {
    padding-left: 0px !important;
    gap: 33px !important;
  }
  .nav-link {
    width: auto !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 7px !important;
  }
}

#searchMenu-Desktop, #searchMenu-Desktop-result {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
}

#searchMenu-Desktop-result,
#on-search-mobile {
  .search-result-tabs {
    border: none;
    margin-bottom: 8px;
    border-bottom: 1px solid #c0c4c8;
    --bs-nav-tabs-border-width: none;
    gap: 49px;
    padding-left: 26px;
    .active {
      border-bottom: 2px solid #051424;
      border-radius: 0;
    }
  }
  .nav-link {
    display: flex;
    gap: 2px;
    padding: 0;
    padding-bottom: 15px;
    width: 98px;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    justify-content: center;
    color: inherit;
    background: none;
    border-radius: 0;
    &:hover{
      background: none;
    }
  }
  .tab-content {
    padding: 0 !important;
    border: none !important;
    #no-search-result-product {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      color: #051424;
      @media (min-width: 1025px) {
        padding-left: 16px;
      }
    }
    #no-search-result-article {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      color: #051424;
    }
    // Loader Styling
    .loader {
      color: #051424;
      display: inline-block;
      position: relative;
      font-size: 16px;
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      box-sizing: border-box;
      @media (min-width: 1025px) {
        padding-left: 16px;
      }
    }
    .loader::after {
      content: "";
      width: 5px;
      height: 5px;
      background: #051424;
      position: absolute;
      bottom: 5px;
      right: -10px;
      font-size: 14px;
      box-sizing: border-box;
      animation: animloader 1s linear infinite;
      border-radius: 50%;
    }

    @keyframes animloader {
      0% {
        box-shadow: 10px 0 rgba(255, 255, 255, 0), 20px 0 rgba(255, 255, 255, 0);
      }
      50% {
        box-shadow: 10px 0 #051424, 20px 0 rgba(255, 255, 255, 0);
      }
      100% {
        box-shadow: 10px 0 #051424, 20px 0 #051424;
      }
    }
  }
  .p-list-container {
    height: 314px;
    border-bottom: 1px solid #c0c4c8;
    #ProductList-Desktop {
      padding-left: 8px;
      padding-right: 8px;
      height: 304px;
      overflow: hidden;
      margin-bottom: 0 !important;
      .product-list-image {
        mix-blend-mode: darken
      }
      .product-list-item {
        cursor: pointer;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 16px;
           &:hover{
            background-color: #f0f9f9;
           }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .View-All-Desktop {
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
    &:hover{
      // background-color: #e5e7e8;
      text-decoration: underline;
    }
  }
  .View-All-Desktop-visible{
    visibility: hidden;
  }
  .View-All-Result {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    
    &:hover {
      cursor: pointer;
    }
    img {
      width: 16px;
      height: 16px;
      color: #051424;
      object-fit: contain;
    }
  }

  .a-list-container {
    height: 314px;
    border-bottom: 1px solid #c0c4c8;
    #ArticleList-Desktop {
      height: 304px;
      padding-left: 8px;
      padding-right: 8px;
      overflow: hidden;
      margin-bottom: 0 !important;
      // padding: 0;
      .article-list-item {
        list-style-type: none;
        padding: 8px;
        border-radius: 16px;
        cursor: pointer;
        .row{
          justify-content: space-between;
        }
        &:hover{
          // background-color: #f0f9f9;
          text-decoration: underline;
        }
        .article-title {
          width: 318px;
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          padding: 0;
        }
        .article-list-right-arrow {
          height: 18px;
          width: 18px;
          padding: 0;
        }
      }
      .article-list-item:last-child {
        margin-bottom: 0 !important;
      }
      .article-arrow-right {
        width: 18px;
        height: 18px;
        aspect-ratio: 1;
        padding: 0;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

#default-search-mobile{
  .product-list-item{
    line-height: 1.5;
  }
}

#no-search-result{
  @media (min-width: 1025px) {
    padding-left: 16px;
  }
}


#no-search-result,
#no-search-result-mobile {
  width: 360px;
  // height: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #c0c4c8;
  font-family: "SourceSansPro Regular", helvetica, sans-serif;
  color: #051424;
  margin-bottom: 16px;
}

#no-search-result-mobile {
  padding-top: 16px !important;
}

.Mobile-Search-icon-container{
  padding: 0 !important;
  background-color: unset !important;
}

#Mobile-Search {
  width: 42px;

  &:hover {
    background: #e5e7e8;
    border-radius: 50%;
  }
}

.search-popup-Desktop {
  visibility: hidden;
  width: 392px;
  max-height: 0;
  background-color: #ffffff;
  padding: 16px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: max-height 0.3s ease;
  // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
  hr{
    margin-bottom: 16px !important;
    margin-top: 0 !important;
    margin-left: 16px;
    margin-right: 16px;
  }

}

#searchMenu-Desktop-result{
  width: 392px;
  background-color: #ffffff;
  padding: 0;
  padding-top: 16px;
  // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  
    nav{
        padding-left: 16px;
        padding-right: 16px;
    }
  
}

.visible-Search-popup{
  max-height: 594px;
  visibility: visible;
}

.search-topIdsList-desktop {
  padding-left: 8px !important;
  padding-right: 8px !important;
  
  
  li {
    padding: 8px !important;
    border-radius: 16px;
    cursor: pointer;
    
  }
  li:hover{
    background-color: #f0f9f9;
  }
  
}

.ProductList-Desktop-class {
  opacity: 0;
  transition: opacity 0.8s ease-in;
}

.ArticleList-Desktop-class {
  opacity: 0;
  transition: opacity 0.8s ease-in;
}

.ProductList-Desktop-class-visible{
  opacity: 1;
}

.View-All-Result{
  opacity: 0;
  transition: opacity 0.8s ease-in;
}

.View-All-Result-visible{
  opacity: 1;
}

#topIdsList{
  opacity: 1 !important;
}
 
#p-list{
  opacity: 1 !important;
}

#ProductList-Desktop{
  margin-bottom: 0 !important;
  list-style-type: none;
  padding: 0;
  li {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }  
}

.search-topIdsList {
  margin-bottom: 0 !important;
  list-style-type: none;
  opacity: 0;
  transition: opacity 0.8s ease-in;
  padding: 0;
  li {
    padding-bottom: 24px;
  }
}

.p-search-desktop {
  margin: 0 !important;
}

.p-head-desktop {
  font-size: 21px !important;
  opacity: 0;
  transition: opacity 0.8s ease-in;
  padding-left: 16px;
  padding-right: 16px;
}

.t-head-desktop {
  font-size: 21px !important;
  margin-bottom: 8px !important;
  opacity: 0;
  transition: opacity 0.8s ease-in;
  padding-left: 16px;
  padding-right: 16px;
}

.listItemVisible {
  opacity: 1 !important;
  a{
    text-decoration: none;
    font-family: 'SourceSansPro Regular';
    font-weight: normal;
    color: #051424;
  }
}

#p-list-Desktop{
  margin-bottom: 8px !important;
  padding-left: 8px;
  padding-right: 8px;
}

.search-p-list-desktop {
  li {
    height: auto !important;
    border-radius: 16px;
    cursor: pointer;
    // margin-bottom: 24px !important;
  }
  li:hover{
    // background-color: #f0f9f9;
    text-decoration: underline;
  }
  li:last-child {
    margin-bottom: 0 !important;
  }
}

.search-p-list {
  list-style-type: none;
  opacity: 0;
  transition: opacity 0.8s ease-in;
  padding: 0;
  li {
    height: 48px;
    padding: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    a{
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      text-decoration: none;

    }
  }
  li:last-child {
    margin-bottom: 0 !important;
  }
}

.Image-Container {
  max-width: 48px;
  // margin-right: 8px;
}

.Search-product-price {
  font-family: "SourceSansPro Semibold", helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #457c9d;
  margin-left: 8px;
}

.Search-product-name {
  font-family: "SourceSansPro Regular", helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #051424;
  margin-bottom: 8px;
  min-height: 17px;
  margin-left: 8px;
}

.truncated-text {
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides any overflow text */
  text-overflow: ellipsis; /* Displays an ellipsis (...) when text overflows its container */
}

.Search-product-image {
  width: 48px;
  height: 48px;
  border-radius: 14px;
  background-color: #e9eff3;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    aspect-ratio: 1/1;
    width: 36px;
    mix-blend-mode: darken;
  }
}

.t-head {
  flex-grow: 0;
  font-family: "SourceSansPro Semibold", helvetica, sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #051424;
  margin-bottom: 24px;
}

.p-search {
  margin: 0;
  margin-top: 16px;
  margin-bottom: 13px;
}

.p-head {
  margin-bottom: 8px;
  flex-grow: 0;
  font-family: "SourceSansPro Semibold", helvetica, sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #051424;
}

#Search-page {
  // display: none;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0; /* Initially positioned outside the viewport */
  z-index: 999999;
  padding: 24px 16px 0 16px;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out, visibility 0.5s ease-in-out; /* Transitions for transform, visibility, and opacity */
  visibility: hidden; /* Initially hidden */
  &.visibleSearch {
    transform: translateX(0); /* Apply transformation when visible */
    visibility: visible !important; /* Make the element visible */
  }
  .cancel {
    justify-content: end;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    cursor: pointer;
  }
  .default-search {
    display: none;
  }
  .visibleSearchMobile {
    display: block;
  }

  .maf-component-search-mobile {
    width: 276px;
    max-width: 100%;
    height: 40px;
    border-radius: 8px;
    z-index: 99;
    form {
      > div {
        background-color: var(--maf-search-input-background) !important;
        border-radius: 8px;
        border: solid 1px var(--maf-search-input-background);
        &:focus-within {
          border: solid 1px #828991;
        }
        .input-group-prepend {
          background-color: var(--maf-search-input-background);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .maf-search-button {
          min-height: unset;
          background: none;
        }
        .maf-search {
          padding-left: 6px;
        }
        .maf-search-button:hover {
          background-color: var(--maf-search-input-background) !important;
          border: none;
        }
        input {
          background-color: var(--maf-search-input-background) !important;
          box-shadow: none;
          cursor: text;
        }
        input:focus{
           color: #051424;
        }
        input::placeholder {
          flex-grow: 0;
          font-family: "SourceSansPro Regular", helvetica, sans-serif ;
          font-size: 17px ;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.53;
          letter-spacing: normal;
          text-align: left;
          color: #828991;
        }

        button {
          margin: 0;
          padding: 0;
          min-width: auto;
        }
      }
    }
  }
  hr{
    margin: 1rem 0;
  }
  input{
    &.form-control{
      padding: .375rem .75rem;
    }
  }
}
