$gray-light: lightgray;
$primary-color: #0f7ef8;

.Wizard-Payment-Information-container {
  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
  max-width: 1176px;
  margin: auto;
  margin-top: 56px;
  @media only screen and (min-width: 690px) and (max-width: 1200px) {
    max-width: 100%;
    margin-left: 72px;
    margin-right: 72px;
  }
  @media (max-width: 690px) {
    margin-left: 16px;
    margin-right: 16px;
  }
  .accountInforamtion-progressbar {
    display: flex;
    gap: 14px;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    @media (max-width: 690px) {
      gap: 8px;
      margin-bottom: 24px;
    }
    @media only screen and (min-width: 690px) and (max-width: 1200px) {
      margin-bottom: 32px;
    }
    .wizard-progress {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #828991;
      @media only screen and (min-width: 690px) and (max-width: 1200px) {
        font-size: 20px;
      }
      @media (max-width: 690px) {
        font-size: 14px;
        line-height: 1.14;
      }
    }
    .Wizard-account,
    .Wizard-payment {
      color: #0f7ef8;
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    }

    .Wizard-checked-circle::before {
      content: "✓";
      font-size: 12px;
      color: #fff;
      border: solid 1px #828991;
      background-color: #828991;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (min-width: 690px) and (max-width: 1200px) {
        font-size: 10px;
        width: 18px;
        height: 18px;
      }
      @media (max-width: 690px) {
        font-size: 8px;
        width: 14px;
        height: 14px;
      }
    }
    .Wizard-checked-circle-payment::before {
      border: solid 1px #0f7ef8;
      background-color: #0f7ef8;
    }

    .bar {
      width: 25px;
      height: 1px;
      flex-grow: 0;
      background-color: #828991;
      @media (max-width: 690px) {
        width: 16px;
      }
    }
    .Customize-bar {
      background-color: #0f7ef8;
    }
  }
  .navigation {
    margin-bottom: 16px;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1d3557;
    .back-container {
      display: flex;
      gap: 8px;
      justify-content: left;
      align-items: center;
      #Back-button-billing{
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
      }
    }
    .gobackImage {
      width: 14px;
      height: 14px;
      transform: rotate(270deg);
    }
  }
  .Payment-row {
    gap: 16px;
    .line {
      height: 1px;
      align-self: stretch;
      flex-grow: 0;
      margin: 15px 0 16px;
      background-color: #e5e7e8;
    }
    .type-heading {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      padding-left: 24px;
      @media (max-width: 690px) {
        font-size: 18px;
        padding-left: 16px;
      }
    }
    .order-summary-card {
      padding: 24px 0;
      border-radius: 14px;
      box-shadow: 0 4px 8px 2px rgba(55, 67, 79, 0.04),
        0 -2px 8px 2px rgba(55, 67, 79, 0.05);
      background-color: #fff;
      max-width: 495px;
      max-height: fit-content;
      order: 2;
      @media (max-width: 1200px) {
        order: unset;
        max-width: 100%;
        padding-bottom: 16px;
        padding-top: 16px;
      }
      .type-heading {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        padding-left: 24px;
        @media (max-width: 690px) {
          font-size: 18px;
          padding-left: 16px;
        }
      }

      .Product-section {
        padding-left: 24px;
        padding-right: 24px;
        @media (max-width: 690px) {
          padding-left: 16px;
          padding-right: 16px;
        }

        .product-card {
          .product-details {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            display: flex;
            gap: 16px;
            @media (max-width: 690px) {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.13;
              gap: 8px;
              width: 80% !important;
            }

            .product-image-container {
              height: 64px;
              width: 64px;
              min-width: 64px;
              border-radius: 12px;
              padding: 6px;
              // padding: 10px 8px;
              background-color: #f0f9f9;
              @media (max-width: 690px) {
                height: 56px;
                width: 56px;
                min-width: 56px;
                // padding: 8px 6px;
              }
              img {
                height: 100%;
                mix-blend-mode: multiply;
                background-size: cover;
                width: 100%;
              }
            }
            .product-name-desc {
              span {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                color: #434e5a;
                @media (max-width: 690px) {
                  font-size: 14px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1;
                }
              }
              .product-desc {
                margin-bottom: 0;
              }
            }
          }

          .discount-price-col {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: right;
            color: #434e5a;
            margin: 0;
            @media (max-width: 690px) {
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
            }
            .discount-price {
              text-decoration: line-through;
            }
            .offer-price {
              color: #e63946;
              font-size: 18px;
              @media (max-width: 690px) {
                font-size: 16px;
              }
            }
            .price-notSlashed{
              font-size: 18px;
              @media (max-width: 690px) {
                font-size: 16px;
              }
            }
          }
        }

        .product-price {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          margin-bottom: 16px !important;
          justify-content: space-between;
          @media (max-width: 690px) {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            margin-bottom: 16px !important;
          }
          .charges-types {
            display: flex;
            gap: 10px;
            align-items: baseline;
            @media (max-width: 690px) {
              gap: 4px;
            }
            a {
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.13;
              letter-spacing: normal;
              text-align: left;
              color: #457c9d;
              cursor: pointer;
              text-decoration: underline;
              @media (max-width: 690px) {
                font-size: 14px;
              }
            }
          }
          .applyCoupon {
            padding: 0;
            display: flex;
            justify-content: space-between;
            @media (max-width: 690px) {
              gap: 24px;
            }
            input {
              font-family: "SourceSansPro Regular", helvetica, sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              letter-spacing: normal;
              text-align: left;
              color: #051424;
              gap: 10px;
              border-radius: 4px;
              border: solid 1px #c0c4c8;
              background-color: #fff;
              padding: 10px;
              padding-left: 14px;
              height: 36px;
              width: 240px;
              @media (max-width: 690px) {
                width: 100%;
                height: 36px;
                font-size: 14px;
              }
            }
            input:focus {
              border: solid 1.5px #457c9d;
              outline: none !important;
            }
            input::placeholder {
              color: #c0c4c8 !important;
            }
            .apply {
              width: 78px;
              height: 36px;
              flex-grow: 0;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 10px;
              padding: 8px 20px;
              border-radius: 4px;
              border: none;
              background-color: #1d3557;
              font-family: "SourceSansPro Regular", helvetica, sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.8;
              color: #f8f7f5;
              @media (max-width: 690px) {
                font-size: 14px;
                padding: 8px 16px;
              }
            }
            .apply:disabled{
              background-color: #c0c4c8;
            }
            .apply:enabled:hover{
              background-color: #457b9d;
            }
          }

          .price,
          .offer-price {
            text-align: end;
          }
          .offer-price {
            color: #e63946;
          }
          .order-total {
            font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            font-size: 21px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            justify-content: space-between;
            @media (max-width: 690px) {
              font-size: 18px;
            }
          }
        }
        #priceSection {
          .coupon-row {
            display: flex;
            padding: 0;
            margin-bottom: 16px;
          }
        }
        .product-price-total {
          margin-bottom: 32px !important;
          @media (max-width: 690px) {
            margin-bottom: 16px !important;
          }
        }
        .contact-info {
          text-align: center;
          p {
            margin: 0;
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: #051424;
          }
          a {
            color: #457c9d;
            font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
    .order-summary-card,
    .user-details {
      @media (max-width: 1200px) {
        flex: auto;
      }
    }
    .user-details {
      .user-contacts,
      .billing-address,
      .payment {
        padding: 24px 0;
        border-radius: 14px;
        box-shadow: 0 4px 8px 2px rgba(55, 67, 79, 0.04),
          0 -2px 8px 2px rgba(55, 67, 79, 0.05);
        background-color: #fff;
        margin-bottom: 16px;
        @media (max-width: 690px) {
          padding-top: 16px;
        }
      }
    }
    .user-details {
      .user-contacts {
        padding-left: 24px;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-right: 16px;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        @media (max-width: 1200px) {
          padding: 24px;
        }
        @media (max-width: 690px) {
          font-size: 16px;
          padding: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
        }
        
        .contact {
          display: flex;
          justify-content: space-between;
          @media (max-width: 690px) {
            gap: 8px;
          }
          .Change {
            color: #051424;
            text-align: end;
            font-size: 16px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-decoration: none;
            cursor: pointer;
          }
          .Change:hover {
            color: #0f7ef8;
          }
          .contact-heading {
            @media (min-width: 1200px) {
              margin-right: 24px;
              max-width: 100px;
              min-width: 100px;
            }
          }
          .contact-heading-order {
            @media (max-width: 690px) {
              order: 3;
              flex: auto;
              width: 100% !important;
              text-align: left !important;
            }
          }
        }
      }
    }

    .billing-address {
      padding-bottom: 0px !important;
      .radio-container {
        margin-left: 24px;
        display: flex;
        align-items: center;
        gap: 7px;

        input {
          height: 20px;
          width: 20px;
          cursor: pointer;
        }
        .radio-label {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          margin: 0;
          cursor: pointer;
        }
      }
      .differentAddress {
        padding-bottom: 24px;
        @media (max-width: 690px) {
          padding-bottom: 20px;
        }
      }
      .waaf-box {
        padding-left: 24px !important;
        padding-right: 24px !important;
        padding-bottom: 16px !important;
        display: flex;
        gap: 16px;
        @media (max-width: 690px) {
          padding-left: 16px !important;
          padding-right: 16px !important;
          padding-bottom: 16px !important;
          gap: 16px;
        }

        .col {
          @media (max-width: 690px) {
            width: 100%;
            flex: unset;
          }
        }
        .box {
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          height: 51px !important;
          padding: 16px;
          border-radius: 8px;
          border: solid 1.5px #c0c4c8;
          background-color: #fff;
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          width: 100%;
          @media (max-width: 690px) {
            font-size: 14px;
          }
          :focus {
            border-color: #457c9d !important;
          }
          :active {
            border-color: #457c9d !important;
          }
        }
        input:focus {
          border: solid 1.5px #457c9d;
          outline: none !important;
        }
        input::placeholder {
          color: #c0c4c8 !important;
        }
        /* Hide the spinners in WebKit browsers (Chrome, Edge, Safari) */
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Hide the spinners in Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
          appearance: textfield;
        }
        select {
          color: #c0c4c8 !important;
          box-shadow: unset;
          cursor: pointer;
          outline: unset;
          align-items: flex-end !important;
          line-height: normal !important;
          height: auto !important;
          align-items: center;
          appearance: none; /* Removes the default arrow */
          -moz-appearance: none;
          -webkit-appearance: none;
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" height="16" width="16"><polyline points="6 9 12 15 18 9"/></svg>')
            no-repeat right 16px center;
          background-size: 16px 16px; /* Set the size of the arrow to 16px by 16px */
          background-color: #fff; /* Background color */

          :active {
            border-color: #457c9d !important;
          }
        }
        select:focus {
          border-color: #457c9d !important;
        }
        select.clicked {
          color: #051424 !important;
        }
        #Ordercountry {
          color: #051424 !important;
        }
        .password-verify-container {
          margin-top: 16px;
          display: none; /* Hidden by default */
          margin-left: 20px;
          @media only screen and (min-width: 690px) and (max-width: 1200px) {
            margin-top: 10px;
          }
          .password-requirements,
          .password-requirements-header {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: #434e5a;
            margin-bottom: 0;
            @media (max-width: 690px) {
              font-size: 14px;
              line-height: 1.29;
            }
          }
        }
        .password-verify-container.show {
          display: block; /* Show when required */
        }
      }
    }
    .override-padding {
      padding-bottom: 8px !important;
      @media (max-width: 690px) {
        padding-bottom: 0px !important;
      }
    }
    .payment {
      padding-bottom: 0px !important;
      .waaf-box {
        padding-left: 24px !important;
        padding-right: 24px !important;
        padding-bottom: 24px !important;
        display: flex;
        gap: 16px;
        @media (max-width: 690px) {
          padding-left: 16px !important;
          padding-right: 16px !important;
          padding-bottom: 16px !important;
          gap: 16px;
        }

        .col {
          @media (max-width: 690px) {
            width: 100%;
            flex: unset;
          }
        }
        .payment-input-label {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          @media (max-width: 690px) {
            font-size: 16px;
          }
        }
        .box {
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          height: 51px !important;
          padding: 16px;
          border-radius: 8px;
          border: solid 1.5px #c0c4c8;
          background-color: #fff;
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          width: 100%;
          @media (max-width: 690px) {
            font-size: 14px;
          }
          :focus {
            border-color: #457c9d !important;
          }
          :active {
            border-color: #457c9d !important;
          }
        }
        input:focus {
          border: solid 1.5px #457c9d;
          outline: none !important;
        }
        input::placeholder {
          color: #c0c4c8 !important;
        }
        /* Hide the spinners in WebKit browsers (Chrome, Edge, Safari) */
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Hide the spinners in Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
          appearance: textfield;
        }
      }
    }
    .terms-and-conditions {
      .custom-checkbox {
        line-height: 1;
        display: flex;
        gap: 14px;
        .checkboxField {
          width: 20px;
          height: 20px;
          min-width: 20px;
          border-radius: 6px;
          cursor: pointer;
        }
        span {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
        }
        a {
          font-size: 18px;
          line-height: 1;
          letter-spacing: normal;
          text-decoration: underline;
          color: #457c9d;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        }
      }
    }
   
    .submit-order {
      margin-top: 41px;
      margin-bottom: 64px;
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      @media (max-width: 690px) {
        margin-top: 40px;
        margin-bottom: 80px;
      }
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        margin: 0 !important;
        width: 173px;
        @media (max-width: 690px) {
          width: 100%;
        }
      }
    }
  }
  .error-message,
  .error-message-payment {
    color: #ff0004;
    font-size: 14px;
    display: none;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    margin: 0;
  }
}
