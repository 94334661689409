/*
    ======================
        TYPOGRAPHT SCSS
    ======================
*/

$font-family-base: "SourceSansPro Regular", helvetica, sans-serif;


h1,h2,h3,a,p,span, dd, button, label, font, li, quote, cite{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

h1, h2, h3, h4, h5, h6{
    line-height: 1;
}

h1{
    color :var(--maf-heading-h1);
    font-family: "SourceSansPro Semibold";
    font-size: 4.5em;
}

h1.maf-heading-md,
.maf-page-layout-detail-blog h1{
    color:var(--maf-heading-h1-small);
    font-family: "SourceSansPro Semibold";
    font-size:var(--maf-heading-h1-small-size);
}

h2.maf-heading-md,
.maf-page-layout-detail-blog h2{
    color:var(--maf-heading-h2-small);
    font-family: "SourceSansPro Regular"; 
    font-size:var(--maf-heading-h2-small-size);
}

h3.maf-heading-md,
.maf-page-layout-detail-blog h3{
    color:var(--maf-heading-h3-small);
    font-family: "SourceSansPro Semibold"; 
    font-size: var(--maf-heading-h3-small-size);
}

h2{
    color :var(--maf-heading-h2);
    font-family: "SourceSansPro Semibold";
    font-size: 3.5em;
}

h3{
    color :var(--maf-heading-h3);
    font-family: "SourceSansPro Semibold";
    font-size: 2.5em;
}

h4{
    color :var(--maf-heading-h4);
    font-family: "SourceSansPro Regular";
    font-size: 2.25em;
}

h5{
    color :var(--maf-heading-h5);
    font-family: "SourceSansPro Semibold";
    font-size: 1.5em;
}

h6{
    color :var(--maf-heading-h6);
    font-family: "SourceSansPro Bold";
    font-size: 1.3125em;
}

p{
    font-family: "SourceSansPro Regular";
    font-size: 1.125em;  
}

span.maf-subtitle{
    display: block;
    line-height: 1.5;
}

span.maf-subtitle-36{
    color :var(--maf-subtitle-36);
    font-family: "SourceSansPro Semibold";
    font-size: 2.25em;
}

span.maf-subtitle-21-SB{
    color :var(--maf-subtitle-21-SB);
    font-family: "SourceSansPro Semibold";
    font-size: 1.3125em;
}

span.maf-subtitle-21{
    color :var(--maf-subtitle-21);
    font-family: "SourceSansPro Regular";
    font-size: 1.3125em;
}

span.maf-subtitle-18{
    color :var(--maf-subtitle-18);
    font-family: "SourceSansPro Semibold";
    font-size: 1.125em;
}

span.maf-subtitle-16{
    color :var(--maf-subtitle-16);
    font-family: "SourceSansPro Bold";
    font-size: 1em;
}

span.maf-subtitle-14{
    color :var(--maf-subtitle-14);
    font-family: "SourceSansPro Semibold";
    font-size: 0.875em;
}

span.maf-byline,
cite.maf-byline{
    color :var(--maf-byline);
    font-family: "SourceSansPro Semibold";
    font-style: normal;
}

span.maf-strapline{
    color :var(--maf-strapline);
    font-family: "SourceSansPro Bold";
}

span.maf-testimonial,
blockquote.maf-testimonial{
    color :var(--maf-testimonial);
    font-family: "SourceSansPro Semibold";
}

// UI ICONS
i.maf-icons-light-user{
    min-height: 14px;
    min-width: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($user-light-icon);
    margin: 0em 0.5em;
    vertical-align: middle;  
    &.first-child{
        margin-left: 0;
    }
    &.last-child{
        margin-right: 0;
    }
}

// UI ICONS
i.maf-icons-dark-user{
    min-height: 14px;
    min-width: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($user-dark-icon);
    vertical-align: middle;  
}

i.maf-icons-chevron-down-dark,
span.maf-icons-chevron-down-dark{
    min-height: 18px;
    min-width: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($chevron-down-dark-icon);
    margin: 0em 0.5em;
    vertical-align: middle;  
}

i.maf-icons-chevron-left-dark,
span.maf-icons-chevron-left-dark{
    min-height: 18px;
    min-width: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($chevron-left-dark-icon);
    margin: 0em 0.5em;
    vertical-align: middle;  
}

i.maf-icons-chevron-right-dark,
span.maf-icons-chevron-right-dark{
    min-height: 18px;
    min-width: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($chevron-right-dark-icon);
    margin: 0em 0.5em;
    vertical-align: middle;  
}

i.maf-icons-chevron-up-dark,
span.maf-icons-chevron-up-dark{
    min-height: 18px;
    min-width: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($chevron-up-dark-icon);
    margin: 0em 0.5em;
    vertical-align: middle;  
}

i.maf-star-filled,
span.maf-star-filled{
    min-height: 18px;
    min-width: 18px;
    display: inline-block;
    background-repeat: no-repeat !important;
    background: url($star-filled-icon);
    margin: 0em 0.05em;
    vertical-align: middle;  
}

i.maf-star,
span.maf-star{
    min-height: 18px;
    min-width: 18px;
    display: inline-block;
    background-repeat: no-repeat !important;
    background: url($star-icon);
    margin: 0em 0.05em;
    vertical-align: middle;  
}

i.maf-star-grayed,
span.maf-star-grayed{
    min-height: 18px;
    min-width: 18px;
    display: inline-block;
    background-repeat: no-repeat !important;
    background: url($star-grayed-icon);
    margin: 0em 0.05em;
    vertical-align: middle;  
}

i.maf-icons-close-dark,
span.maf-icons-close-dark{
    min-height: 14px;
    min-width: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($close-icon-dark);
    margin: 0em 0.5em;
    vertical-align: middle;  
}

i.maf-icons-search{
    min-height: 18px;
    min-width: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($search-icon);
    vertical-align: middle;
}
i.maf-hamburger-icon{
    min-height: 14px;
    min-width: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($icons-menu);
    vertical-align: middle;
}
i.maf-icons-cart,
span.maf-icons-cart{
    min-height: 14px;
    min-width: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($cart-icon);
    vertical-align: middle;
}

i.maf-icons-heart-filled{
    min-height: 14px;
    min-width: 14px;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($heart-filled-icon);
    vertical-align: middle;
    margin-left: 0.33em;
}

i.maf-icons-arrow-right-light{
    min-height: 18px;
    min-width: 18px;    
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url($arrow-right-light-icon);
    vertical-align: middle;
    height: 100%;
}

i.maf-icons-arrow-right-dark,
span.maf-icons-arrow-right-dark{
    min-height: 16px;
    min-width: 16px;    
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url($arrow-right-dark-icon);
    vertical-align: middle;
    height: auto;
}

span.navbar-toggler-icon{
    min-height: 14px;
    min-width: 14px;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background: url($navbar-hamburger-icon);
    vertical-align: middle;
    
}
