// BOOTSTRAP VARIABLES OVERWRITE
$primary : #1d3557;
$secondary : #434e5a;
$info : #457b9d;
$success : #15b118;
$warning : #F5A623;
$danger : #EB0010;
$light : #ffffff;
$dark : #051424;

// CUSTOM MAF VARIABLES
$maf-red : var(--maf-red);

$maf-dark-blue : var(--maf-dark-blue);
$maf-light-blue : var(--maf-light-blue);
$maf-lighter-blue : var(--maf-lighter-blue);
$maf-lightest-blue : var(--maf-lightest-blue);
$maf-primary-blue-lightest: var(--primary-maf-blue-lightest);

$maf-dark : var(--maf-dark);
$maf-light-dark : var(--maf-light-dark);
$maf-lighter-dark : var(--maf-lighter-dark);
$maf-lightest-dark : var(--maf-lightest-dark);

$maf-spruce : var(--maf-spruce);
$maf-iron : var(--maf-iron);
$maf-white-smoke : var(--maf-white-smoke);
$maf-mist : var(--maf-mist);

$maf-orangey-yellow : var(--maf-orangey-yellow);
$maf-orangey-yellow-dark : var(--maf-orangey-yellow-dark);
$maf-maize : var(--maf-maize);
$maf-kelly-green : var(--maf-kelly-green);
$maf-deep-sky-blue : var(--maf-deep-sky-blue);
$maf-dodger-blue : var(--maf-dodger-blue);
$maf-bright-violet : var(--maf-bright-violet);
$maf-torch-red : var(--maf-torch-red);
$maf-pink-raspberry : var(--maf-pink-raspberry);
$maf-mantis : var(--maf-mantis);
$maf-sandstorm : var(--maf-sandstorm);

$modal-content-border-radius:20px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 960px,
  xl: 1200px,
  xxl: 1440px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1025px,
  xxl: 1440px
) !default;

$border-radius-lg: 32rem;
$border-radius-pill: 50rem;

//UI Icons
$arrow-right-light-icon: "../img/ui-icons/icons-arrow-right-light.svg";
$arrow-right-dark-icon: "../img/ui-icons/icons-arrow-right-dark.svg";
$chevron-icon: "../img/ui-icons/icons-chevron-up.svg";
$user-dark-icon: "../img/ui-icons/icons-user-dark.svg";
$user-light-icon: "../img/ui-icons/icons-user-light.svg";
$search-icon: "../img/ui-icons/icons-search.svg";
$cart-icon: "../img/ui-icons/icons-cart.svg";
$close-icon-dark: "../img/ui-icons/icons-close-dark.svg";
$heart-filled-icon: "../img/ui-icons/icons-heart-filled.svg";
$navbar-hamburger-icon: "../img/ui-icons/icons-menu.svg";
$chevron-down-dark-icon: "../img/ui-icons/icons-chevron-down-dark.svg";
$chevron-left-dark-icon: "../img/ui-icons/icons-chevron-left-dark.svg";
$chevron-right-dark-icon: "../img/ui-icons/icons-chevron-right-dark.svg";
$chevron-up-dark-icon: "../img/ui-icons/icons-chevron-up-dark.svg";
$star-filled-icon: "../img/ui-icons/icons-star-filled.svg";
$star-grayed-icon: "../img/ui-icons/icons-star-grayed.svg";
$star-icon: "../img/ui-icons/icons-star.svg";
$icons-menu: "../img/icons-menu.png";

//Social Media Icons
$facebook-icon: "../img/social-media/icons-facebook.svg";
$twitter-icon: "../img/social-media/icons-twitter.svg";
$instagram-icon: "../img/social-media/icons-instagram.svg";
$youtube-icon: "../img/social-media/icons-youtube.svg";

:root {
    //MAF Primary
    --maf-red: #e63946;

    // MAF Blue Shades
    --maf-dark-blue: #1d3557;
    --maf-light-blue: #457b9d;
    --maf-lighter-blue: #aac3d2;
    --maf-lightest-blue: #e9eff3;
    --primary-maf-blue-lightest: #e9eff3;

    // MAF Gray Shades
    --maf-dark: #051424;
    --maf-light-dark: #434e5a;
    --maf-lighter-dark: #828991;
    --maf-lightest-dark: #c0c4c8;   
    
    // MAF Secondary Colors
    --maf-spruce: #37434f;
    --maf-iron: #e5e7e8;
    --maf-white-smoke: #f8f7f5;
    --maf-mist: #f0f9f9;

    // MAF Extended Colors
    --maf-orangey-yellow: #f5a623;
    --maf-orangey-yellow-dark: #9C6307;
    --maf-maize: #f6d344;
    --maf-kelly-green: #15b118;
    --maf-deep-sky-blue: #2adafc;
    --maf-dodger-blue: #0772E4;
    --maf-bright-violet: #ab21ea;
    --maf-torch-red: #EB0010;
    --maf-pink-raspberry: #CF2A8D;
    --maf-mantis: #7cc366;
    --maf-sandstorm: #f7ca42;

    //Headings
    --maf-heading-h1: #457b9d;
    --maf-heading-h2: #457b9d;
    --maf-heading-h3: #051424;
    --maf-heading-h4: #051424;
    --maf-heading-h5: #051424;
    --maf-heading-h6: $dark;
    --maf-heading-h1-small: #457b9d;
    --maf-heading-h2-small: #457b9d;
    --maf-heading-h3-small: #051424;
    
    --maf-heading-h1-small-size: 2.5em;
    --maf-heading-h2-small-size: 2.25em;
    --maf-heading-h3-small-size: 1.5em;

    //Sub Headings
    --maf-subtitle-36: #051424;
    --maf-subtitle-21-SB: #051424;
    --maf-subtitle-21: #051424;
    --maf-subtitle-18: #051424;
    --maf-subtitle-16: #051424;
    --maf-subtitle-14: #051424;

    //Header and Navigation
    --maf-main-navigation-font: #051424;
    --maf-search-input-background: #f0f9f9;


    //Byline, Quote, Testemonial, Strapline
    --maf-byline: #1d3557;
    --maf-strapline: #457b9d;
    --maf-testimonial: #051424;
    
    //Inputs
    --maf-input-light-bg:none;
    --maf-input-light-bg:none;
    --maf-input-light-border:#ffffff;
    --maf-input-border-default:#c0c4c8;
    --maf-input-border-hover:#828991;
    --maf-input-border-disabled:#c0c4c8;

    //Cards
    --card-component-1-background:#f0f9f9;
    --card-member-quote:#457b9d;
    --card-member-cite:#1d3557;
    --card-member-cite-history:#457b9d;

    //Categories Cards
    --category-card-default-background:#f0f9f9;
    --category-card-hover-background:#e9eff3;

    //Collapse
    --collapse-1-description: #37434f;
    --collapse-1-bar-collapsed: #e5e7e8;
    --collapse-1-bar-expanded: #457b9d;

    //Buttons    
    --maf-primary-hover: #457b9d;
    --maf-primary-text: #ffffff;
    --maf-primary-outline-font: #1d3557;
    --maf-success-text-hover: #ffffff;
    --btn-maf-light-blue: #457b9d;
    --btn-maf-lighter-blue: #aac3d2;
    --btn-maf-lightest-blue: #e9eff3;

    // Links
    --maf-textlink-dark: #051424;
    --maf-textlink-light: #ffffff;

    //Social Media
    --maf-facebook-bg: #3b5998;
    --maf-twitter-bg: #1da1f2;
    --maf-instagram-bg: #c32aa3;
    --maf-youtube-bg: #ff0000;

    //Badges and Chips
    --maf-minicart-chip: #1d3557;

    //Footer
    --maf-footer-email-form: #1d3557;
    --maf-footer-email-placeholder: #ffffff;
    --maf-footer-email-input-border:#1d3557;

}

// $svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;
