.plp-Accordian-container {
  max-width: 1176px;
  margin: auto;
  @media only screen and (min-width: 768px) and (max-width: 1260px) {
    max-width: 880px ;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0 16px !important;
  }
  @media (max-width: 374px) {
    margin-left: 16px;
    margin-right: 16px;
  }
  .row{
    @media only screen and (min-width: 769px) and (max-width: 970px) {
      margin: 0 16px !important;
    }
  }

  .faq-column{
    @media (max-width: 1024px) {
        width: 100% !important;
      }
  }
  .accordion-column{
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      align-items: center  !important;
    }
    @media (max-width: 1024px) {
        width: 100% !important;
      }
  }

  .accordion-button::after {
    display: none;
  }

  .plp-Accordian-header-text {
    min-width: 700px;
    margin-right: 40px;
    @media only screen and (min-width: 1024px) and (max-width: 1260px) {
      min-width: 90% ;
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      min-width: 90% ;
    }
    @media only screen and (min-width: 375px) and (max-width: 768px)  {
      min-width: 90%;
      max-width: 90%;
      margin-right: 10px;
    }
    @media (max-width: 374px) {
      max-width: 90% ;
      min-width: 90% ;
      margin-right: 10px;
    }
  }

  //   /* Add custom arrow image */
  //   .accordion-button.collapsed::before {
  //     content: url('../../../static/img/plp-chevron-arrow/icons-chevron-up.png'); /* Specify the path to your up arrow image */
  //     transition: transform 0.3s ease; /* Add transition for smooth animation */
  //   }

  .collapsed {
    .plp-FAQ-Accordion-chevron {
      transform: rotate(180deg);
      
    }
    .plp-FAQ-Accordion-chevron-old
    {
      display: block !important;
    }
    .plp-FAQ-Accordion-chevron-new
    {
      display: none;
    }

  }

  #accordionExample {
    gap: 16px;
  }

  .plp-Accordian-item {
    width: 915px;    
    min-height: 88px;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 24px 36px;
    border-radius: 16px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
    background-color: #f0f9f9;
    cursor: text;
    height: auto !important;

    @media only screen and (min-width: 1024px) and (max-width: 1260px) {
      max-width: 85% ;
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      max-width: 100% ;
    }
    @media only screen and (min-width: 375px) and (max-width: 768px) {
      width: 100%;
      padding: 24px 16px;
      height: 85px;
      min-height: 85px;
    }

    @media (max-width: 374px) {
      max-width: 100% ;
      padding: 24px 16px;
      height: 85px;
      min-height: 85px;
      gap: 0;
    }

  }

  .accordion-collapse {
    @media (max-width: 374px) {
     margin-top: 8px;
    }
    
  }

  .accordion-collapse:nth-child(1){

@media (max-width: 374px) {
     margin-top: 12px;
    }
  }

 

  .plp-Accordian-item.active {
    background-color: #e9eff3; /* Background color when accordion is open */
    height: auto;
  }
  .collapsed{
    @media (min-width: 1024px) {
      margin-left: 20  !important;
    }
   
  }
  .plp-Accordian-button {
    height: 40px;
    padding: 0;
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: unset !important;
    box-shadow: none !important;
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1d3557 ;
    cursor: text !important;
    @media (max-width: 1024px) {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 18px;
      margin-left: 0px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #1d3557 ;
    }
    @media (max-width: 374px) {
      height: auto;
      padding-top: 0;
    }
  }
  .plp-Accordian-body {
    margin-left: 62px;
    padding: 0;
    width: 705px;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #434e5a;
    @media only screen and (min-width: 1024px) and (max-width: 1260px) {
      width: 80% ;
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      max-width: 80% ;
      margin-left: 30px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
    }
    @media (max-width: 768px) {
      width: 80%;
      flex-grow: 0;
      margin-left: 30px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
    }
    @media (max-width: 374px) {
      width: 85%;
    }
  }

  .plp-Accordian-header {
    // align-items: center;
    display: flex;
    width: 843px;
    @media only screen and (min-width: 1024px) and (max-width: 1260px) {
      max-width: 100% ;
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      max-width: 100% ;
    }
    @media (max-width: 768px) {
      width: 100%;
      align-items: baseline;
    }
    @media (max-width: 374px) {
      width: 100%;
    }
  }

   /* Adjusted margin when accordion button is clicked (focused) */
   .accordion-button:focus .plp-Accordian-number {
    // margin-right: 20px;
  }

  .plp-Accordian-number {
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    width: 42px;
    height: 40px;
    flex-grow: 0;
    // margin: 0 43px 0 0;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #37434f;
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      height: 100%;
      width: 22px;
      display: flex;
      align-items: center;
      margin-right: 8px;
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #37434f;
    }
    @media (max-width: 768px) {
      width: 22px;
      height: 21px;
      margin-right: 8px;
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #37434f;
    }
  }

  .plp-Accordian-circle {
    position: relative;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: #e9eff3;
    @media only screen and (min-width: 570px) and (max-width: 1024px) {
      margin-left: 32%;
    }
    @media (max-width: 1024px) {
      width: 80px;
      height: 80px;
    }
    .plp-Accordian-faq-circl-text-desktop {
      display: block;
      position: absolute;
      margin-left: 51px;
      margin-top: 48px;
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 36px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  .plp-Accordian-faq-circl-text-mobile {
    display: none;
    position: absolute;
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    margin-left: 27px;
    margin-top: -52px;
    @media only screen and (min-width: 570px) and (max-width: 1024px) {
      margin-left: 36%;
    }
    @media (max-width: 1024px) {
      display: block;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #1d3557;
    }
  }
  .plp-faq-arrow-container {
    display: flex;
    justify-content: end;
    margin-right: 28px;
    @media (max-width: 1024px) {
      margin-bottom: 11.4px;
      margin-top: -15px;
      margin-right: 0px;
      justify-content: center;
    }
    .plp-faq-arrow {
      width: 133px;
      object-fit: contain;
      height: 128.2px;
      margin-top: 15px;
      margin-right: -65px;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    .plp-faq-arrow-mobile {
      display: none;
      width: 46.5px;
      height: 52.9px;
      flex-grow: 0;
      object-fit: contain;
      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
  p,
  ul,
  ol{
    font-family: "SourceSansPro Regular";
    font-size: 1.125em;  
  }
}
