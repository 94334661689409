/* ------------------------------ [START] GLOBAL CSS ------------------------------ */
html,
body {
  /* height: 100%; */
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

/* ------------------------------ [START] CAROUSEL CSS ------------------------------ */

// header Styling
.fid-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;

  &__contact {
    display: flex;
    flex-direction: column;
    font-family: "SourceSansPro Regular";
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #051424;

    .phone-text-danger {
      color: #e63946;
      font-family: "SourceSansPro Bold";
      text-decoration: none;
    }
  }

  &__button {
    background-color: #1d3557;
    padding: 12px 20px;
    border-radius: 24px;
    .fiBtn {
      display: block; //may remove later
      line-height: 16px;
      font-size: 16px;
      color: #ffffff;
      font-family: "SourceSansPro Semibold";
      text-align: center;
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    padding: 12px 16px;
    align-items: center;

    &__logo {
      svg {
        height: 24px;
        width: 149px;
      }
    }
    &__contact {
      display: none;
    }
    &__button {
      width: 144px;
      height: 40px;
      .fiBtn {
        font-family: "SourceSansPro SemiBold";
        font-size: 14px;
        text-align: center;
        height: 18px;
      }
    }
  }
}

// Main Section Styling
.fid-main {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  align-items: center;
  margin: 30px auto 64px;
  max-width: 1176px;

  &__left {
    .main-heading {
      display: flex;
      flex-direction: column;
      color: #e63946;
      line-height: 72px;
      font-size: 72px;
      font-family: "SourceSansPro SemiBold";
      letter-spacing: -1.29px;
      margin-bottom: 36px;
      &__blue {
        color: #1d3557;
      }
    }

    .secondary-text {
      font-family: "SourceSansPro Bold";
      font-size: 12px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #457c9d;
      margin-bottom: 12px;
      max-width: 564px;
      height: 52px;

      @media (max-width: 845px) {
        height: auto;
      }
    }

    .secondary-heading {
      font-family: "SourceSansPro SemiBold";
      font-size: 24px;
      line-height: 24px;
      color: #051424;
      margin-bottom: 24px;
      max-width: 406px;
      height: 57px;
      &__red {
        color: #e63946;
      }
      @media (max-width: 845px) {
        height: auto;
      }
    }

    .shop-button {
      background-color: #1d3557;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 13px 26px 15px;
      max-width: 147px;

      &__text {
        font-family: "SourceSansPro SemiBold";
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        width: 70px;
        height: 20px;
        display: flex;
        align-items: center;
      }

      &__icon {
        width: 16px;
        height: 16px;
        flex-grow: 0;
        margin: 3px 0 1px 9px;
        object-fit: contain;
      }
    }
  }

  &__right {
    width: 358px;
    height: 429px;
    flex-grow: 0;
    margin: 0 0 0 81px;
    position: relative;

    &__img {
      width: 358px;
    }

    .members-enrolled {
      display: flex;
      max-width: 207px;
      height: 74px;
      border-radius: 16px;
      background-color: #f8f7f5;
      padding: 16px 18px;
      align-items: center;
      justify-content: center;
      gap: 29px;
      position: absolute;
      top: 49.8%;
      left: -80px;

      &__text {
        display: flex;
        flex-direction: column;
        font-family: "SourceSansPro SemiBold";
        font-size: 21px;
        color: #e63946;
        gap: 2px;

        .me-text {
          font-family: "SourceSansPro Regular";
          font-size: 14px;
          text-align: center;
          color: #051424;
          height: 14px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 32px;
    padding: 0 16px;
    margin-bottom: 56px;

    &__left {
      .main-heading {
        font-size: 56px;
        line-height: 56px;
        letter-spacing: -1px;
        text-align: center;
        margin-bottom: 28px;
      }

      .secondary-text {
        margin-bottom: 8px;
        text-align: center;
        align-items: start;
        height: 59px;
      }

      .secondary-heading {
        text-align: center;
        margin-bottom: 32px;
        max-width: none;
        height: auto;
      }

      .shop-button {
        max-width: none;
        margin-bottom: 54px;
      }
    }

    &__right {
      width: 343px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &__img {
        width: 330px;
        margin: 0 0 0 16px;
      }
      .members-enrolled {
        left: -1%;
      }
    }
  }

  @media (min-width: 1200px) {
    padding-inline: 0;
  }
}

// Protection Plan Section Styling
.fid-protection {
  position: relative;
  // background-image: url("../../../static/free-id-img/Frame.png");
  // background-repeat: no-repeat;
  // background-position: left;
  &__heading {
    font-family: "SourceSansPro SemiBold";
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -1px;
    color: #051424;
    margin-bottom: 48px;
    text-align: center;
    &__red {
      color: #e63946;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 24px;
    // padding: 0 32px;
    justify-content: center;
    margin-bottom: 64px !important;
    max-width: 1176px;
    margin: auto;

    .protection-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
      padding: 32px;
      background-color: #fff;
      box-shadow: 10px 10px 20px 0 rgba(240, 249, 249, 0.6);
      box-shadow: 10px -10px 20px 0 rgba(240, 249, 249, 0.6);
      border-radius: 16px;

      &__icon {
        width: 72px;
        height: 72px;
        border-radius: 100px;
        background-color: #f0f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__heading {
        font-family: "SourceSansPro SemiBold";
        font-size: 21px;
        line-height: 1;
        letter-spacing: 1px;
        color: #457c9d;
      }

      &__text {
        font-family: "SourceSansPro Regular";
        font-size: 18px;
        line-height: 24px;
        color: #051424;
        max-width: 312px;

        &__bold {
          font-family: "SourceSansPro SemiBold";
        }
      }
    }
  }

  &__desktop-bg {
    position: absolute;
    top: -225px;
    left: 0;
    z-index: -1;
    max-width: 100%;
    height: auto;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__mobile-bg {
    display: none;
  }

  @media (max-width: 1200px) {
    &__cards {
    margin: 0 16px;
    }
  }

  @media (max-width: 820px) {
    &__heading {
      margin-left: 16px;
      margin-right: 16px;
    }
    &__cards {
      margin: 0 16px;
      flex-wrap: wrap;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @media (max-width: 768px) {
    &__cards {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__heading {
      margin: 0 16px;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 32px;
      text-align: start;
      
    }

    &__cards {
      flex-direction: column;
      padding: 0px;
      margin-bottom: 56px !important;

      .protection-card {
        box-shadow: 0 8px 16px 0 rgba(69, 124, 157, 15%);
        width: 100%;
      }
    }

    &__desktop-bg {
      top: -50px; 
    }
  }

  @media (max-width: 560px) {
    &__cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      }
    &__desktop-bg {
      display: none;
    }

    &__mobile-bg {
      display: block;
      position: absolute;
      z-index: -1;
      top: -84px;
      left: 0;
      width: 100%;
      img{
        width: 100%;
      }
    }
  }
}

// Plans
.fid-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;

  &__heading {
    font-family: "SourceSansPro SemiBold";
    font-size: 56px;
    line-height: 64px;
    letter-spacing: -1px;
    color: #051424;
    margin-bottom: 24px;
    text-align: center;
  }

  &__text {
    font-family: "SourceSansPro Regular";
    font-size: 18px;
    line-height: 28px;
    color: #051424;
    text-align: center;
    max-width: 1176px;
    margin-bottom: 69px;

    @media (min-width: 768px) {
      padding: 0 16px;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    gap: 24px;

    .plan-card {
      height: 673px;
      width: 100%;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0 8px 16px 0 rgba(69, 124, 157, 15%);
      padding: 40px 32px 0;

      &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        .info-heading {
          font-family: "SourceSansPro SemiBold";
          font-size: 32px;
          line-height: 36px;
          color: #051424;
          width: 100%;
          text-align: center;
        }

        .info-price {
          font-family: "SourceSansPro SemiBold";
          font-size: 40px;
          line-height: 40px;
          text-align: center;
          color: #051424;

          &__small {
            font-size: 21px;
          }
        }

        .info-text {
          font-family: "SourceSansPro SemiBold";
          font-size: 24px;
          text-align: center;
          line-height: 24px;
          color: #051424;
          &__red {
            color: #e63946;
            font-size: 36px;
            line-height: 36px;
          }
        }
      }

      hr {
        margin: 39.5px 0;
        height: 1px;
        background-color: #c0c4c8;
      }

      &__benefits {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        padding: 0;

        li {
          height: 18px;
          list-style-type: none;
          font-family: "SourceSansPro Regular";
          color: #051424;
          font-size: 18px;
          display: flex;
          align-items: center;
          gap: 7px;
        }
      }

      &__note {
        font-family: "SourceSansPro Italic";
        font-size: 16px;
        line-height: 20px;
        color: #051424;
        width: 300px;
        height: 40px;
      }
    }
    .active-card {
      border: 2px solid #0f7ef8;
      position: relative;

      &__most-popular {
        font-size: 21px;
        line-height: 21px;
        color: #fff;
        font-family: "SourceSansPro SemiBold";
        text-align: center;
        background-color: #0f7ef8;
        padding: 12px 30px;
        border-radius: 100px;
        position: absolute;
        top: -3.5%;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
      }
    }
  }

  @media (max-width: 1260px) {
    &__cards {
      width: 880px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @media (max-width: 970px) {
    padding: 0 16px;
    &__cards {
      width: 100%;
      
    }
  }

 

  @media (max-width: 768px) {
    padding: 0 16px;
    margin-bottom: 50px;
    &__cards {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__heading {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 16px;
      width: 343px;
    }

    &__text {
      margin-bottom: 32px;
    }

    &__cards {
      flex-direction: column;
      width: 100%;
      margin: 0 16px;
      .plan-card {
        width: 100%;
        height: 100%;
        padding: 32px 16px;
      }
      .active-card {
        // margin-top: 23px;

        &__most-popular {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
  }

  @media (max-width:  660px) {
    padding: 0 16px;
    &__cards {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      .plan-card {
        width: 100%;
        height: auto;
        padding: 32px 16px;
      }
      .active-card {
        margin-top: 23px;
      }
    }
  }
}

// Get Started Section
.fid-getStarted {
  padding: 72px 24px;
  background-color: #f0f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 64px auto 16px;
  max-width: 1176px;

  &__left {
    &__heading {
      font-family: "SourceSansPro SemiBold";
      font-size: 40px;
      line-height: 40px;
      color: #457c9d;
      margin-bottom: 24px;
    }

    &__points {
      padding: 0;
      display: flex;
      gap: 24px;
      flex-direction: column;
      max-width: 472px;
      margin-bottom: 32px;

      li {
        list-style: none;
        display: flex;
        align-items: start;
        gap: 7px;
        font-family: "SourceSansPro Regular";
        font-size: 18px;
        line-height: 18px;
        color: #051424;
        svg {
          flex-shrink: 0;
        }
        a {
          font-family: "SourceSansPro Regular";
          font-size: 18px;
          line-height: 18px;
          color: #051424;
        }
      }
    }

    &__button {
        border-radius: 24px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1d3557;
        padding: 14px 40px;
        width: fit-content;
        text-decoration: none;
        div {
          font-family: "SourceSansPro SemiBold";
          font-size: 16px;
          line-height: 16px;
          color: #fff;
        }

       
      
    }
  }

  &__right {
    img {
      width: 480px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 16px;
    gap: 30px;

    &__left {
      &__heading {
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 30px;
      }

      &__points {
        gap: 16px;
        margin-bottom: 30px;
      }

      &__button {
         
          width: 100%;
        
      }
    }
  }

  @media (max-width: 480px) {
    &__right {
      img {
        width: 343px;
      }
    }
  }

  @media (min-width: 1200px) {
    padding-inline: 0;
  }
}

.fid-getStarted__bg {
  width: 100%;
  background-color: #f0f9f9;
}

.fid-custom-btn-hover {
  cursor: pointer;
  text-decoration: none;
  border-radius: 24px;
  &:hover {
    background-color: var(--maf-light-blue);
    color: var(--maf-success-text-hover);
  }
}

//   SCSS from Cards

$color_1: #051424;
$color_2: #e63946;
$color_3: #1e3557;
$color_4: #457c9d;
$color_5: inherit;
$font-family_1: "SourceSansPro Semibold", helvetica, sans-serif;
$font-family_2: "SourceSansPro Regular", helvetica, sans-serif;
$font-family_3: "SourceSansPro Semibold";
$background-color_1: black;
$background-color_2: #000000;
$background-color_3: #f8f7f5;

#footer-short {
  .maf-text-links-list {
    a{
      font-family: $font-family_2;
      color: $color_1;
    }
  }


}

.carousel.slide {
  max-width: 1176px;
  margin: auto;
}
div.carousel-indicators {
  [data-bs-target] {
    background-color: $background-color_1;
    width: 22px;
    height: 20px;
    border-radius: 100px;
  }
}
.carousel-inner {
  > div {
    position: relative;
  }
}
.carousel-inner::after {
  display: none;
}
.carousel-control-next-icon {
  background-color: $background-color_2;
}
.carousel-control-prev-icon {
  background-color: $background-color_2;
}
.maf-section-3 {
  max-width: 1176px;
  margin-left: auto;
  margin-right: auto;
  scroll-margin-top: 80px;

  .plp-product-column {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .product-title {
      margin-bottom: 0;
      margin-top: 20px;
      line-height: 24px;

      @media (max-width: 592px) {
        line-height: 1.08;
      }
    }

    .product-colors{
      a{
        display: flex;
      }
    }

    .plp-product-price {
      margin-bottom: 0;
    }

    .plp-product-color-count {
      margin-left: 0;
    }

    @media (max-width: 768px) { 
      gap: 6px;      
    }

    @media (max-width: 375px){

      .product-title {
        margin-top: 2px;
      }

      .product-colors{
        margin-bottom: 0;
      }
    }
  }
  .main-header {
    > div {
      h2 {
        text-align: center;
        font-size: 56px;
        font-family: $font-family_1;
        letter-spacing: -1px;
        color: $color_1;
        margin-bottom: 24px;
      }
      p {
        font-size: 18px;
        font-family: $font-family_2;
        line-height: 1;
        text-align: center;
        margin-bottom: 32px;
      }
      span {
        color: $color_2;
      }
    }
  }
  .carousel-inner {
    > div {
      background: #ffffff;
      h3 {
        width: 80%;
        margin: 0 auto;
        text-align: left;
        font-weight: bold;
        font-weight: normal;
      }
    }
  }
  p.price {
    font-size: 18px;
    font-family: $font-family_2;
    color: $color_1;
    font-weight: normal;
    margin-bottom: 0;
    span {
      font-size: 24px;
      color: $color_2;
      font-family: $font-family_1;

      @media (max-width: 375px) {
        font-size: 21px;
      }
    }
  }
  .ma-a-btn-container {
    width: 100%;
    text-decoration: none;
    margin-top: auto;
  }
  .ma-btn-wrap {
    width: 100%;
    height: 48px;
    border: 1px solid #1d3557;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      box-shadow: 0 8px 16px 0 rgba(29, 53, 87, 0.15);
      border: solid 1px #1d3557;
      background-color: rgba(29, 53, 87, 0.1);
      color: #1d3557;
    }
    &:hover {
      background-color: var(--maf-dark-blue);
      color: var(--maf-success-text-hover);
    }
  }
  .fi-product-img-blend {
    mix-blend-mode: darken !important;
  }
  .ma-btn {
    margin: 0;
    max-width: 276px;
    padding-top: 13px;
    padding-bottom: 15px;
    font-size: 16px;
    font-family: $font-family_3;
    text-decoration: none;
  }
}
.maf-section-5 {
  background-color: $background-color_3;
  padding: 2em 1em;
  h2 {
    font-weight: bold;
    font-weight: normal;
    color: $color_3;
  }
  ul {
    margin: 1em 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 2.3;
      &:before {
        content: "✓";
        margin: 0 0.5em 0 0;
        background: #ffffff;
        border: solid 1px #1e3557;
        color: $color_3;
        padding: 1px 4px;
        border-radius: 50%;
      }
    }
  }
}
.maf-component-cards.maf-component-cards-one .owl-carousel.off {
  @media only screen and (min-width: 768px) and (max-width: 970px) { 
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  @media (min-width: 970px) {
    display: grid;
    grid-template-columns: repeat(3,1fr);
  }


}
.maf-component-cards.maf-component-cards-one .card-body{
  @media (max-width: 768px) {
    max-width: 100%;
  }
}
.maf-component-cards.maf-component-cards-one .card-body .figure {
  @media (max-width: 768px) {
    max-width: 100%;
  }
  @media (max-width: 376px) {
    max-width: 310px;
  }
}

.maf-section-6 {
  @media (max-width: 1200px) {
    margin: 0 16px;
  }
  .owl-carousel {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 24px;
    @media (max-width: 1260px) {
      max-width: 880px;
    }
    .card-body {
      padding: 0 !important;
    }
    .card {
      max-width: 100% !important;
      background: #e9eff3;
      border-radius: 20px;
      min-height: 300px;
      text-align: center;
      min-width: auto !important;
    }
   
    img {
      border-radius: 20px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .carousel-fi-img {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .figure {
      @media (max-width: 768px) {
        margin-bottom: 24px !important;
      }
      margin-top: 16px !important;
      margin-left: 16px !important;
      margin-right: 16px !important;
      margin-bottom: 32px !important;
      img{
        aspect-ratio: 1;
      }

    }
    .text {
      width: 100%;
    }
    .line {
      border: solid 1px #d91c29;
      width: 23%;
      margin: 0 auto;
    }
    blockquote {
      color: $color_4 !important;
      font-size: 21px !important;
      line-height: normal !important;
      margin: 0 16px 12px !important;
      font-family: $font-family_1 !important;
    }
    .details {
      display: block;
      color: $color_4;
      font-style: normal;
      font-family: $font-family_2;
      margin-bottom: 32px;
    }
    .name {
      color: $color_1;
      font-size: 16px;
      font-family: $font-family_1;
      margin: 10px;
    }
  }
  .text-info {
    color: $color_4 !important;
  }
  h2 {
    font-size: 56px;
    color: $color_1 !important;
    font-family: $font-family_1;
    margin: 0 0 1em;
    text-align: center;
    margin-bottom: 48px;
    margin-top: 32px;
  }
  h3 {
    margin: 1em auto;
    text-align: center;
  }
  .owl-nav {
    text-align: center;
    margin-top: 24px !important;
    button {
      padding: 0.875em !important;
      max-width: 48px !important;
      max-height: 48px !important;
      border: solid 1px #1e3557 !important;
      border-radius: 25px !important;
      margin: 0 0.25em !important;
      img {
        display: block;
      }
    }
    .owl-carousel {
      .owl-nav {
        button.owl-next {
          background: 0 0;
          color: $color_5;
          font: inherit;
        }
      }
    }
  }
}
.maf-section-6 {
  .owl-carousel {
    .owl-nav {
      .owl-next {
        cursor: pointer;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }
}

.owl-carousel {
  .owl-nav {
    .owl-prev {
      cursor: pointer;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    button {
      background: 0 0;
      color: $color_5;
      font: inherit;
    }
  }
  button.owl-dot {
    background: 0 0;
    color: $color_5;
    font: inherit;
  }
}
.maf-section-7 {
  padding: 3em;

  .fi-lastnote {
    margin: 0 auto 16px;
    max-width: 1176px;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
}
@media only screen and (max-width: 1260px) {
  .carousel.slide {
    max-width: 880px;
    margin: auto;
  }
}
@media only screen and (max-width: 970px) {
  .carousel.slide {
    max-width: 100%;
    margin-left: 16px;
    margin-right: 16px;
    width: auto !important;
  }
}
@media only screen and (min-width: 265px) and (max-width: 768px) {
  .maf-section-3 {
    .main-header {
      > div {
        h2 {
          font-size: 40px;
          margin-bottom: 8px;
        }
      }
    }
    p.price {
      font-size: 16px;
      margin: 0;
    }
    .product-colors {
      margin-bottom: 6px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .maf-section-6 {
    .carousel-inner {
      display: block;
    }
    h2 {
      font-size: 40px;
      text-align: left !important;
      // margin-left: 16px;
      max-width: 277px;
      margin-bottom: 32px;
    }
  }
}
/* Media query for devices between 660px and 768px width */
@media (max-width: 768px) and (min-width: 660px) {
  #carouselMemberStory .carousel-inner {
      display: flex;
      flex-wrap: nowrap;
  }
  
  #carouselMemberStory .carousel-inner > .carousel-item {
      flex: 0 0 48%; /* Show two items side by side */
      margin-right: 0; /* Remove negative margin */
  }
}
@media only screen and (min-width: 768px) {
  .ma-hide-mobile {
    display: initial !important;
  }
  .ma-hide-desktop {
    display: none !important;
  }
  .carousel.slide {
    background: none;
    padding: 0;
  }
  #carouselMemberStory .carousel-inner {
    overflow: auto;
    > div {
      width: 33.33%;
    }
  }
  .carousel-control-next-icon {
    background: black;
  }
  .maf-section-6 {
    margin: 3em 16px;
    .carousel-inner {
      > div {
        background: #e9eff3;
        border-radius: 20px;
        min-height: 300px;
      }
    }
    h2 {
      text-align: center;
    }
    h3 {
      text-align: center;
    }
  }
  .retargeting-cta {
    text-align: center;
    a {
      margin: 0 auto;
    }
  }
  ul.slider-container {
    height: auto;
  }
  footer {
    .left {
      width: 100%;
      > p.detail {
        width: 50%;
        margin: 3em 0;
      }
      img {
        width: 49%;
      }
    }
    .top {
      width: 90%;
      margin: 0 auto;
      display: flex;
      > div {
        flex: 1;
      }
      .right {
        h3 {
          font-size: 1.35em;
          font-weight: bold;
          font-weight: normal;
          margin: 2em 0 1em;
        }
        p {
          &:nth-of-type(2) {
            line-height: 1.5;
            margin: 2em 0;
          }
        }
      }
    }
    .phone-icon {
      img {
        width: 4%;
      }
    }
    .legal {
      nav {
        font-size: 1em;
        a {
          font-weight: bold;
          margin: 0 4em;
          &:after {
            content: "";
          }
        }
      }
      .line {
        width: 90%;
        margin: 1em auto;
      }
    }
  }
}