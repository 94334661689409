.plp-card-container {
  overflow: hidden;
  .plp-product-row {
    max-width: 1176px;
    margin: auto;
    justify-content: center;
    gap: 24px;
    @media only screen and (min-width: 592px) and (max-width: 970px) {
      margin-left: 15px;
      margin-right: 16px;
    }
@media (max-width: 592px) {
      gap: 16px;
    }
    .plp-product-column {
      max-width: 276px;
      margin-bottom: 32px;
      // margin-left: auto;
      // margin-right: auto;
  @media (max-width: 970px) {
        max-width: 100%;
      }
      @media (max-width: 592px) {
        margin-bottom: 16px;
      }
      
    }
  }

  .cards-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 85px 26px;
    border-radius: 14px;
    background-color: #f0f9f9;
@media (max-width: 592px) {
      max-height: 100%;
      max-width: 100%;
    }
    @media (min-width: 1260px) {
      width: 276px;
    }
    @media (max-width: 970px) {
      max-width: 100%;
    }

    .plp-Image-container {
      width: 100%;
      max-height: 100%;
      margin: 16px;
  @media (max-width: 592px) {
        width: 100%;
        margin: 12px;
      }
      .plp-product-image {
        height: 100%;
        width: 100%;
        transition: opacity 0.3s ease-out;
        mix-blend-mode: normal;
      }
      .plp-product-hover-image {
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.4s ease-in;
        mix-blend-mode: normal;
      }
    }
  }
  .plp-Image-container-hover-feature:hover {
    cursor: pointer;
      .plp-product-hover-image {
        opacity: 1;
      }
      .plp-product-image {
        opacity: 0;
      }
  }
  .product-title {
    margin-top: 28px;
    margin-bottom: 8px;
    max-width: 274px;
    flex-grow: 0;
    font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    text-overflow: ellipsis;
@media (max-width: 592px) {
      max-width: 90%;
      flex-grow: 0;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.08;
      letter-spacing: normal;
      text-align: left;
      margin-top: 10px;
      margin-bottom: 6px;
    }
    .product-title-truncate {
      width: 100%;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
    }
  }

  .plp-review-star {
    display: flex;
    align-items: center;
    justify-self: flex-start;
    gap: 10px;
@media (max-width: 592px) {
      gap: 5px;
      margin-bottom: 6px;
    }
    margin-bottom: 8px;
    .star-images {
      display: flex;
      align-items: center;
      gap: 3px;
  @media (max-width: 592px) {
        gap: 2px;
      }
    }
    .plp-review-text-mobile{
      display: block;
      @media (min-width: 593px) {
       display: none;
      }
    }

    .plp-review-text-Desktop{
      display: none;
      @media (min-width: 593px) {
        display: block;
       }
    }

    .plp-review-text {
      flex-grow: 0;
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #434e5a;
  @media (max-width: 592px) {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
      }
    }
  }

  .plp-product-price {
    flex-grow: 0;
    font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #457c9d;
    margin-bottom: 8px;
@media (max-width: 592px) {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 6px;
      max-width: 130px !important;
    }
  }

  .product-colors {
    display: flex;
    gap: 8px;
    height: 20px;
    align-items: center;
@media (max-width: 970px) {
      gap: 4px;
      max-width: 130px !important;
     
    }
    .color {
      border-radius: 50%;
      width: 16px;
      height: 16px;
  @media (max-width: 592px) {
        width: 14px;
        height: 14px;

      }
    }
    .color-1 {
      flex-grow: 0;
      background-color: #1b2c8c;
    }
    .color-2 {
      flex-grow: 0;
      background-color: #b5343c;
    }
    .color-3 {
      flex-grow: 0;
      background-color: #c45ca3;
    }
    .plp-product-color-count {
      flex-grow: 0;
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #434e5a;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.plp-card-container .plp-product-row{
  justify-content: left !important ;
  gap: 0 !important;
  @media(min-width: 1441px){
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 24px !important;
  }
  @media(max-width: 1440px){
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 24px !important;
  }
  @media (max-width: 1260px) {
      max-width:  880px !important;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 24px !important;
  }
  @media (max-width: 970px) {
      max-width:  100% !important;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px !important;
  }
  @media only screen and (min-width: 660px) and (max-width: 820px) {
    max-width:  100% !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px !important;
  }

  @media only screen and (min-width: 820px) and (max-width: 970px) {
    max-width:  100% !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px !important;
}
  @media (max-width: 592px) {
      max-width:  100% !important;
      padding: 0  !important;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px !important;
      margin-left: 16px !important;
      margin-right: 15px !important;
  }
  @media (max-width: 344px) {
      max-width:  100% !important;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
  }
}
