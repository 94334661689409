/*
    ===========================================
        VIDEO PLAYER IN NAVIGATION COMPONENT
    ===========================================
*/

/* [START] VIDEO MODAL */
#videoModalHeader {
    .modal-dialog-centered{
        height: 100%;
        max-width: 1176px;
        margin: 0;
        .modal-content{
            width: 100%;
            height: 100%;
            .modal-body{
                height:100%;
                padding: 0;
                iframe{
                    border-radius: 20px;
                }
            }
            a.maf-btn-close{
                position: absolute;                
                z-index: 99;
                top: 1em;
                right: 1em;
                svg{
                    path{
                        fill: #fff; 
                    }
                }
            }
        }
    }
}


@include media-breakpoint-up(xl) {
    #videoModalHeader {
        .modal-dialog-centered {
            margin: auto;
            .modal-content{
                width: 100%;
                height: 100%;
                max-height: 662px;
            }
        }
    }
}
/* [END] VIDEO MODAL */
