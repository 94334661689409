.plp-Noresult-container {
    margin: auto;
    @media (max-width: 1440px) {
        padding-left: 15px;
        padding-right: 16px;
    }
    .maf-component-category-cards{
        margin-right: -16px;
    }
    .plp-product-row{
        @media (max-width: 592px) {
            padding-left: 0px !important;
            padding-right: 0px !important;

        }
    }
    .plp-card-header{
        height: 21px;
        flex-grow: 0;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        margin-top: 32px;
        margin-bottom: 32px;
        @media (max-width: 592px) {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            margin-top: 24px;
            margin-bottom: 16px;
        }
    }
    .no-result-text{
        display: flex;
        justify-content: center;
        margin-bottom: 72px;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #051424;
        margin-top: 48px;

        @media (max-width: 592px) {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            margin-bottom: 0px;
            justify-content: left;
            margin-top: 24px;
        }
      
    }
    
    .maf-component-category-cards{
        margin-bottom: 0px;
    }
    .items{
        display: flex;
        gap: 24px;
    }
    .item{
        margin-right: 0 !important;
    }
}