.container-404 {
    overflow: hidden;
  .container-404-information {
    max-width: 1176px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;


    .main-content-404 {
      max-width: 594px;
      text-align: center;
      height: 594px;
      background-image: url("../img/404Images/Group 2750.webp");
      background-repeat: no-repeat;
      background-position: center;
      margin: auto;

      @media (max-width: 595px) {
        margin: 0 16px;
        background-size: contain;
        height: auto;
      }
     

      .title {
        font-size: 72px;
        margin-top: 66px;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        color: #457c9d;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        @media (max-width: 595px) {
          margin-top: 32px;
          font-size: 56px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: -1px;
        }
      }

      .text {
        font-size: 72px;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        color: #1d3557;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        @media (max-width: 595px) {
          font-size: 56px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.07;
          letter-spacing: -1px;
        }
      }
      .help-text {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 21px;
        color: #000000;
        margin-top: 24px;
        margin-bottom: 36px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        @media (max-width: 595px) {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          margin-top: 32px;
          margin-bottom: 32px;
        }
      }
      .btn-primary-404 {
        margin: 0;
        font-family: "SourceSansPro Semibold";
        min-width: 87px;
        border: none;
        width: 147px;
        border-radius: 1.5em;
        max-height: 48px;
        min-height: 48px;
        height: 100%;
        letter-spacing: normal;
        padding: 0 1.5em;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        background-color: #1d3557;
        color: #fff;
        cursor: pointer;
        &:hover{
          background-color: #457c9d;
        }

      }
    }
    .contant-info-404 {
      background-color: #f0f9f9;
      border-radius: 16px;
      
      height: auto;
      max-width: 1176px;
      padding-left: 40px;
      padding-top: 40px;
      padding-bottom: 40px;
      @media (max-width: 1176px) {
        margin: 0 16px;
      }

      @media (max-width: 970px) {
        padding-right: 40px;
      }
      @media (max-width: 640px) {
        // min-height: 168px;
        padding: 20px;
      }
      @media (max-width: 595px) {
        margin-top: 20px;
      }
      @media (min-width: 375px) and (max-width: 375px) {
         height: 168px;
      }
      @media (min-width: 1440px) {
        max-height: 204px;
     }
      

      .question {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #457c9d;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 12px;
        @media (max-width: 640px) {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          gap: 10px;
        }
        .assistant-icon {
          width: 20px;
          height: 20px;
          font-size: small;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          /* padding: 6px 9px 5px; */
          border: solid 2px #f5a623;
          background-color: #f5a623;
          color: #fff;
          @media (max-width: 640px) {
            width: 15px;
            height: 15px;
            font-size: x-small;
          }
        }
      }
      .support {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        margin-bottom: 10px;
        @media (max-width: 640px) {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: normal;
        }
        @media (max-width: 420px) {
            max-width: 256px;
          }
        a {
          color: #457c9d;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          text-decoration: underline;
        }
      }
      .text {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        @media (max-width: 640px) {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: normal;
        }
      }
    }
  }

  #maf-bleed{
    .items{
        @media (max-width: 1176px) {
            margin-left:16px;
          }
    }
  }

  .plp-card-header {
    height: 21px;
    flex-grow: 0;
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    margin-top: 56px;
    margin-bottom: 32px;
    max-width: 1176px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1176px) {
        margin-left:16px;
      }
    @media (max-width: 592px) {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
}
