/*
    ================================
        MARQUEE BANNER COMPONENT
    ================================
*/

.maf-message-marquee-banner{
    background-color: $maf-mist;
    border-radius: 1.5em;
    margin-bottom: 4rem;
    .marquee-main{
        padding: 1.4066em;        
        overflow:auto;
        -ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
		cursor: -webkit-grab; 
		cursor: grab;
		&::-webkit-scrollbar {
			display: none;
		}
        .marquee-items{
            .marquee-item{
                margin-left: 1.5em;
                align-items: center;
                max-height: 27px;
                span{
                    &.icon{
                        max-height: 24px;
                    }
                    &.text{
                        font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
                        font-size: 1.3125em;
                        letter-spacing: -0.37px;
                        margin-left: 0.7619em;
                    }
                }
                &.marquee-item-1{
                    color: $maf-red !important;
                }
                &.marquee-item-2{
                    color: $maf-dark-blue !important;
                    .icon{
                        path{
                            color:$maf-dark-blue !important;
                        }                        
                    }
                }
                &.marquee-item-3{
                    color: $maf-red !important;
                    .icon{
                        path{
                            fill:$maf-red !important;
                        }                        
                    }
                }
                &.marquee-item-4{
                    color: $maf-dark-blue !important;
                    .icon{
                        path{
                            color:$maf-dark-blue !important;
                        }                        
                    }
                }
                &.marquee-item-5{
                    color: $maf-red !important;
                    .icon{
                        path{
                            color:$maf-red !important;
                        }                        
                    }
                }
                &.marquee-item-6{
                    color: $maf-dark-blue !important;
                    .icon{
                        path{
                            color:$maf-dark-blue !important;
                        }                        
                    }
                }                
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .maf-message-marquee-banner{
        margin: 0 16px;
        margin-bottom: 3.5rem;
    }    
}


