/*
    ========================
        FOOTER SCSS FILE
    ========================
*/

.maf-component, .maf-organism{
  position: relative;
}

figure{
  margin: 0;
}

.maf-organism-footer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  /* Set the text color for the input field */
  line-height: 1.5;
  font-family: 'SourceSansPro Regular';
  &.bg-maf-lightest-blue{
    background: var(--maf-lightest-blue);
    color: #051424;
  }
   #successMessage { 
     font-size: 1.3125em;
     margin: 0 0 1.3em;
     font-family: "SourceSansPro Bold", helvetica, sans-serif;
   } 

   #newsletter-footer {
     -webkit-text-fill-color:  white !important;
     font-size: 1.125em;
     font-weight: 600;
     -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
  i.maf-icons-arrow-right-light {
    min-height: 18px;
    min-width: 18px;
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(../img/ui-icons/icons-arrow-right-light.svg);
    vertical-align: middle;
    height: 100%;
  }

 .content-asset {
    p {
     font-size: 0.875em  !important;
    }
 }
 
   .hidden {
       display: none;
   }

   .maf-component-social-navigation {
     padding: 0;
     margin-bottom: 1em;
     @media (min-width: 768px) {
       margin-top: 24px;
     }
   }

 #newsletter-footer{
   background-color: #37434F;
 }
 figure {
   img {
     max-width: 198px;
   }
 }
 a {
   text-decoration: none;
 }
 h2 {
   font-size: 1em;
   line-height: 1.5em;
   font-family: "SourceSansPro Regular" !important;
 }
 &#footer-main {
   padding: 4em 1em 2em;
   margin: 4em 0 0;
   .maf-component-main-navigation {
     margin-left: 0;
     margin-right: 0;
     .footer-item {
       margin: 0 0 1em;
     }
   }
   .footer-item-c1 {
     .maf-footer-email-form {
       @media only screen and (min-width: 265px) and (max-width: 768px) {
         margin: 0 0 2.3em;
       }
       form {
         @media only screen and (min-width: 265px) and (max-width: 768px) {
           padding: 0;
         }
         h3 {
           font-size: 1.3125em;
           margin: 0 0 1.3em;
           font-family: "SourceSansPro Bold", helvetica, sans-serif;
           font-weight: bold;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           @media only screen and (min-width: 265px) and (max-width: 768px) {
             font-family: "SourceSansPro Semibold", helvetica, sans-serif;
           }
         }
         div {
           position: relative;
           max-width: 377px;
           input {
             border-color: var(--maf-footer-email-input-border) !important;
             min-height: 3em;
             &::placeholder {
               color: var(--maf-footer-email-placeholder);
               font-size: 1.125em;
             }
           }
           button {
             min-width: auto;
             min-height: 100%;
             position: absolute;
             top: 0;
             right: 0;
             background: none;
             border: none;
             height: 100%;
             margin: 0;
             height: 100%;
             padding: 0.8em 0.8em 0.8em 0;
           }
           .maf-icons-arrow-right-light{
             height: auto !important;
             display: block;
           }
         }
       }
     }
   }
   .maf-header-collapse {
     width: 100%;
     text-align: left;
     padding: 0;
     background: none;
     border: none;
     font-weight: normal;
     font-size: 1em;
     margin: 0 0 2em;
     font-family: "SourceSansPro Bold", helvetica, sans-serif !important;
   }
   figure {
     img {
       margin: 0 0 1em;
     }
   }

   p {
     font-size: 1.125em;
     margin: 0;
     color: #fff;
   }

   .maf-footer-legal {
     // padding: 1.5em 0 0.5em;
     ul {
       li {
         margin: 0 0.5625em;
         a {
           font-size: 0.875em;
         }
       }
     }

     p {
       font-size: 0.875em;
       margin: 0;
     }
   }

   ul {
     font-size: 1em; 
     li {
       margin: 0 0 1em;
       a {
         font-family: "SourceSansPro Regular", helvetica, sans-serif;
         text-decoration: none;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: 1.33;
         letter-spacing: normal;

       }
     }
     &.maf-text-links-list {
       a {
         &:after {
           background: var(--maf-textlink-light);
         }
       }
       a,
       a:visited,
       a:hover{
           -webkit-backface-visibility:hidden;
           backface-visibility:hidden;
           position:relative;
           transition:0.5s color ease;
           text-decoration:none;
           &:after{
               text-decoration:none;
               content: "";
               transition:0.5s all ease;
               -webkit-backface-visibility:hidden;
               backface-visibility:hidden;
               position:absolute;
               bottom:-0.25em;
               height:1px;
               width:0;
               left:50%;
               -webkit-transform:translateX(-50%);
               transform:translateX(-50%);
           }
           &:hover{
               &:after{
                   width:100%;
               }
           }
       }       
     }
   }

   .maf-component-social-navigation {
     padding: 0;
     margin-bottom: 1em;
     ul {
       li {
         line-height: 1;
         margin: 0 0 0 1em;
         &:first-of-type {
           margin: 0;
         }
         a {
           display: block;
           padding: 0.94em;
           margin: 0;
           max-width: 48px;
           max-height: 48px;
           line-height: 1;
           &:hover,
           &:focus-visible {
             border-radius: 25px;
           }
           div {
             min-height: 18px;
             min-width: 18px;
           }
         }
         &.nav-item-facebook {
           a {
             &:hover,
             &:focus-visible {
               background: var(--maf-facebook-bg);
             }
             div {
               background-image: url($facebook-icon);
             }
           }
         }
         &.nav-item-twitter {
           a {
             &:hover,
             &:focus-visible {
               background: var(--maf-twitter-bg);
             }
             div {
               background-image: url($twitter-icon);
             }
           }
         }
         &.nav-item-youtube {
           a {
             &:hover,
             &:focus-visible {
               background: var(--maf-youtube-bg);
             }
             div {
               background-image: url($youtube-icon);
             }
           }
         }
         &.nav-item-instagram {
           a {
             &:hover,
             &:focus-visible {
               background: var(--maf-instagram-bg);
             }
             div {
               background-image: url($instagram-icon);
             }
           }
         }
       }
     }
   }

   .maf-footer-contact-button {
     text-align: center;
     max-width: 377px;
     background-color: var(--maf-footer-email-form);
     padding: 0.5em 0;
     margin: 1.5em 0 2.3em;
     line-height: 1.5;  
     .maf-header {
       font-family: "SourceSansPro Bold", helvetica, sans-serif;
       font-size: 1.3125em;
     }
     @media only screen and (min-width: 265px) and (max-width: 768px) {
       margin-left: auto !important;
       margin-right: auto !important;
       }
   }

   .maf-phone-number {
     font-size: 1.125em;
     max-width: 377px;
   }

   .maf-hours {
     font-size: 0.875em;
   }
   .footer-phone{
     position: absolute;
     width: 100%;
     mix-blend-mode: color-burn !important;
     height: 100%;
     top: 0;
     left: 0;
   }

   .footer-phone:hover {
    mix-blend-mode: color-burn !important;
    color:unset !important;
   }

   .footer-phone:focus {
    mix-blend-mode: color-burn !important;
    color:unset !important;
   }

   .maf-footer-legal {
     line-height: 2.5;
   }
 }
 &#footer-short {
   padding: 2em;
   max-height: 277px;
   text-align: center;
   div{
     // display: flex;
     figure {
       margin: 0 0 1em;
     }
 
     p {
       font-size: 0.875em;
       margin: 0;
     }
 
     ul {
       li {
         font-size: 0.875em;
         margin: 1em 0.5625em;
         text-decoration: none;
       }
       &.maf-text-links-list {
         a {
           &:after {
             background: var(--maf-textlink-dark);
           }
         }
       }
     }
   }    
   .maf-footer-legal {
     line-height: 1.3;
   }
   .maf-footer-logo{      
     margin-right: 32px;
   }
 }

 .maf-element-back-to-top-button {
   position: absolute;
   right: 0;
   bottom: 0;
 }

 .maf-footer-legal {
   order: 1;
   margin: 0.5em 0 1em;
 }

 .maf-footer-copyright {
   order: 2;
 }
}

.elementor-default{
  .maf-organism-footer{
    h2{
      font-size: 1em;
      line-height: 1.5em;
      font-family: "SourceSansPro Regular" !important;
      letter-spacing: inherit;
    }
  }
}

@include media-breakpoint-up(md) {
 #footer-main {
   .maf-component-main-navigation {
     .footer-item {
       ul {
         display: block;
         height: auto !important;
         visibility: visible;
       }
     }
   }
 }
 #footer-short {
   div{
     figure {
       margin: 0;
     }
     .maf-footer-copyright {
        max-width: 430px;
        text-align: left;
        padding: 0;
     }
     .maf-footer-legal{
       margin: 0;
       padding: 0;
       line-height: 1;
     }
   }    
 }
}

@include media-breakpoint-up(lg) {
 .maf-organism-footer {
   &#footer-main {
     padding: 4em 2em 2em 2em;
     .maf-component-main-navigation {
       .footer-item {
         &.footer-item-c1 {
           margin: 0 4em 0 0;
           border-bottom: none;
           padding: 0;
         }
       }
     }
   }
   &#footer-short {
     div{
       figure {
         margin: 0;
       }
     }
   }
   .maf-footer-logo{
     max-width: 198px;
   }
   .maf-footer-legal {
     order: 2;
   }
   .maf-footer-copyright {     
     order: 1;
     p{
       font-weight: normal;
       font-stretch: normal;
       font-style: normal;
       line-height: normal;
       letter-spacing: normal;
     }
   }
 }
}

@include media-breakpoint-down(md) {
 .maf-component-main-navigation {
   .footer-item {
     padding-left: 0;
     padding-right: 0;
     ul {
       flex-wrap: nowrap;
     }
     div {
       button {
         background: none;
         border: none;
         margin: 0 0 1em !important;
         &.maf-header-collapse {
           &::after {
             content: url($chevron-icon);
             float: right;
             color: $light;
           }
           &.collapsed {
             &::after {
               rotate: 180deg;
             }
           }
         }
       }
     }
   }
 }
}
