.plp-result-container {
  max-width: 1176px;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .plp-result-text {
    height: 21px;
    flex-grow: 0;
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    @media (max-width: 1260px) {
      margin-right: 16px;
      margin-left: 16px;
    }

    @media (min-width: 970px) and (max-width: 1260px) {
      min-width: 880px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .plp-result-menu {
    max-width: 1176px;
    height: 32px;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    gap: 160px;
    border-bottom: 1px solid #c0c4c8;

    .tab-menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 13px;
      align-items: center;
      cursor: pointer;
      position: relative;

      .tab-btn {
        height: 16px;
        flex-grow: 0;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        border: 0;
        background-color: transparent;
        padding: 0;
      }

      .tab-hr {
        position: absolute;
        width: 160px;
        height: 3px;
        flex-grow: 0;
        background-color: #051424;
        margin: 0;
        opacity: 1;
        z-index: 30;
        bottom: 0;
      }
    }

    @media (max-width: 1260px) {
      margin-right: 16px;
      margin-left: 16px;
    }
    @media (max-width: 592px) {
      gap: 80px;
    }
    @media (min-width: 970px) and (max-width: 1260px) {
      min-width: 880px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .plp-result-filter {
    .filter-container-design {
      padding: 0;
    }
    .filter-section {
      margin-top: 0;
      .filter-options {
        z-index: 2;
      }
    }

    .filter-refinement {
      height: 38px;
    }
    @media (max-width: 1260px) {
      margin-right: 16px;
      margin-left: 16px;
    }
    @media (min-width: 970px) and (max-width: 1260px) {
      min-width: 880px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .plp-result-articles {
    .maf-search-show {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .maf-component-article-cards {
      .rp-articles-pagination {
        margin: 40px 0 64px 0;
        display: flex;
        gap: 16px;

        .article-page-item {
          height: 40px;
          width: 40px;
          border: 1px solid #c0c4c8;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;

          .article-page-link {
            width: 32px;
            height: 30px;
            // font-family: Inter;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: center;
            color: #828991;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background: none;
            margin-left: 0;

            &:focus {
              outline: none;
              box-shadow: none;
            }
            &:active {
              outline: none;
              box-shadow: none;
            }

            @media (max-width: 768px) {
              width: 16px;
              height: 14px;
              // font-family: SFProText;
              font-size: 12px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.67;
              letter-spacing: normal;
              text-align: center;
              padding:0;
            }
          }

          &.active {
            border: 1px solid #051424;
            .page-link {
              color: #051424;
            }
          }

          &.disabled {
            background-color: #828991;
            opacity: 0.5;
          }

          @media (max-width: 768px) {
            height: 24px;
            width: 24px;
          }
        }

        @media (max-width: 768px) {
          gap: 6px;
        }

      }
    }
  }
}

#plpResultPage::-webkit-scrollbar {
  display: none;
}