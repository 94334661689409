/*
    ================================
        PROMOTIONAL BANNER COMPONENT
    ================================
*/

.maf-promotional-banner{
    div{
        &.maf-banner-wrap{
            text-align: center;
            background: var(--maf-white-smoke);
            line-height: 1;
            padding: 15px 36px;
            min-height: 80px;
            .banner-text-header{
                margin-bottom: 5px;
                flex-grow: 0;
                font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
                font-size: 16px ;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                color: var(--maf-light-blue);
                a{
                    color: var(--maf-light-blue);
                }
            }
            h2{
                    margin-bottom: 5px;
                    flex-grow: 0;
                    font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    color: var(--maf-light-blue);
                    a{
                        color: var(--maf-light-blue);
                    }
            }
            p{
                margin-bottom: 0;
                font-family: "SourceSansPro Regular", helvetica, sans-serif;
                font-size: 0.875em;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                color: var(--maf-dark);
                a{
                    font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
                    font-weight: normal;
                    text-decoration: none;
                }
                span{
                    line-height: 1;
                    display: inline-flex;
                    vertical-align: top;
                    letter-spacing: 0;
                }
            }
        }
    }
    
    .modal{
        .modal-dialog{
            max-width: 880px;
            .modal-content{
                padding: 24px;
                max-width: 89.138%;
                margin: 0 auto;
                .modal-header{
                    background: none;
                    border: none;
                    padding: 0;
                    margin-bottom: 32px;
                    h2{
                        width: 100%;
                        margin: 0;
                        line-height: 1; 
                        font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
                        font-size: 0.875em;
                        color: var(--maf-dark);
                        text-align: left;
                    }
                    button{
                        &.btn-close{
                            width: 14px;
                            height: 14px;
                            padding: 0;
                            margin: 0;
                            min-width: 14px;
                            max-width: 14px;
                            color: var(--maf-dark);
                            opacity: 1;
                            &:focus-visible{
                                outline-color: blue;
                                outline: revert;
                                border-radius: initial;
                            }
                            &:focus{
                                box-shadow: none;
                            }
                        }
                    }
                }
                .modal-body{
                    padding: 0;
                    p{
                        text-align: left;
                        font-family: "SourceSansPro Regular", helvetica, sans-serif;
                        font-size: 0.8125em;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.23;
                        letter-spacing: normal;
                        text-align: left;
                        color: var(--maf-dark);                    
                    }
                }
            }
        }
    }
}

.rotating-banner-content{
    background-color: #f8f7f5;
    position: relative;
    min-height: 80px;
    .maf-promotional-banner-2{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}

@include media-breakpoint-up(lg) {
    .maf-promotional-banner{
        div{
            &.maf-banner-wrap{        
                padding: 19px 0;
                .banner-text-header{
                    font-size: 21px;
                }
                h2{
                    font-size: 21px;
                }
                p{
                    font-size: 1em;
                }
            }
        }
        .modal{
            .modal-dialog{
                .modal-content{
                    padding: 56px 85px 48px;
                    max-width: 100%;
                    .modal-header{
                        h2{
                            font-size: 1.5em;
                            text-align: center;
                        }                    
                    }
                    .modal-body{
                        p{
                            font-size: 1.125em;
                            line-height: 1.56;
                        }
                    }
                    .modal-content{
                        padding: 56px 85px 48px;
                    }
                }
            }
        }              
    }    
}
