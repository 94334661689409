/*
    ========================
        HEADER 2 SCSS FILE
    ========================
*/

#volume-button-howitwork {
  margin-right: 24px;
  margin-bottom: 24px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  border: 0px solid #ccc;
  cursor: pointer;
  z-index: 1; /* Ensure the button is above the video */
  width: 64px;
  height: 64px;
  padding: 20px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  z-index: 3;
}

.maf-organism-header-new{
  position: sticky;
  top: 0;
  overflow: visible;
  height: 100%;
  z-index: 9;
  nav.maf-organism-header-navbar {
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    z-index: 999;
    height: 4em;
    padding: 12px 16px;

    & > div.header-main-wrap {
      max-height: 40px;
      .maf-drop-down-group{
        display: flex;
        gap:13px;
      }
      .maf-search {
        color: var(----maf-lighter-dark);
        padding-left: 8px;
        font-size: 17px;
        padding: 6px 12px;
        cursor: pointer; /* Set cursor to pointer to indicate interactivity */
        line-height: normal;
        letter-spacing: normal;
        border-radius: 8px;      
      }

      .maf-element-logo {
        svg {
          max-width: 149px;
        }
      }

      .maf-search-button {
        padding: 0px !important;
        border: none !important;
        display: flex;
        align-items: center;
        cursor: text;
        background:none;
      }

      .maf-nav-icons-utility {
        gap: 8px;
        min-width: 206px;
        justify-content: flex-end;
        margin-right: 0 !important;
        margin-left: auto;
      }

      .maf-icons-dark-user {
        height: 14px;
        width: 14px;
        margin: 0px !important;
      }

      .maf-sidebar-userIcon,
      .maf-sidebar-hamburgerIcon {
        padding: 13px;
        line-height: 0;
        border: none;
        background: none;
        display: block;
      }

      .maf-sidebar-navlink {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 0px 12px 0px;
        color: var(--maf-dark);
        label {
          font-size: 0.825em;
          font-weight: 600;
          color: var(--maf-dark);
        }
      }

      .maf-sidebar-product-Rectangle {
        border-radius: 24px;
        background-color: var(--maf-mist);
      }

      .maf-buttonSize {
        min-height: auto;
      }

      .maf-nav-product-Image {
        width: 222px;
        aspect-ratio: 3/2;
        object-fit: contain;
        margin-bottom: 44px;
        margin-left: 79px;
      }

      .maf-product-Name {
        font-size: 1.313em;
        width: 240px;
        height: 54px;
        margin: 0 0 8px !important;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--maf-dark);
      }

      .maf-component-navigation-main {
        text-align: center;
        .navbar-nav {
          gap:13px;
          & > span{
            display: inline-flex;
            & > li{
              margin-left: 13px;
              &:first-of-type{
                margin-left: 0;
              }
            }
          }
          & > li{
            & > a{
              pointer-events:none;
            }            
            &:nth-of-type(1)
            a{
              pointer-events:initial;
            }            
          }
          .nav-item {            
            &:hover{
              background: var(--maf-mist);              
              border-radius: 4px;
            }
            .nav-link {            
              display: block;
              color: var(--maf-main-navigation-font);
              padding: 6px 12px;
              text-decoration: none;
            }
            .nav-link-container,
            .nav-link-container-how-it-work {
              position: relative;
              display: inline-block;
              font-size: 1em;
              font-family: "SourceSansPro Semibold", helvetica, sans-serif;
              padding: 6px 12px 0;
              cursor: pointer; /* Set cursor to pointer to indicate interactivity */
              line-height: normal;
              letter-spacing: normal;
              border-radius: 8px;
              & > span {
                display: block;
                padding-bottom: 6px;
                font-family: "SourceSansPro Semibold", helvetica, sans-serif;
              }
            }

            /* Style for the tooltip content */
            .tooltip-content {
              visibility: hidden;
              width: 100%; /* Set width to 100% of the viewport width */
              background-color: #fff;
              border-bottom-left-radius: 25px;
              border-bottom-right-radius: 25px;
              color: #fff;
              padding-right: 1.5em;
              padding-left: 1.5em;
              text-wrap: balance;
              position: fixed; /* Use fixed position to keep it relative to the viewport */
              z-index: 1;
              left: 0;
              opacity: 0;
              max-height: 0;
              transition: max-height 0.3s ease;
              padding-top: 6px;
              top: 47.5px;
              @include media-breakpoint-up(xxl) {
                padding-right: 24px;
                padding-left: 25px;
              }
            }

            .tooltip-content-24-7 {
              width: auto;
              left: auto;
              margin-left: -12px;
              color: #051424;
              padding: 16px !important;
              padding-top: 32px !important;
              border-bottom-left-radius: 10px !important;
              border-bottom-right-radius: 10px !important;
              // margin-top: 16px !important;
            }

            .list-contents {
              list-style-type: none;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              font-family: "SourceSansPro Regular", helvetica, sans-serif;
            }

            .tooltip-content-24-7 ul li {
              margin-bottom: 16px;
            }

            .tooltip-content-24-7 ul li a {
              text-decoration: none;
            }

            .tooltip-content-24-7 ul li a:hover {
              text-decoration: underline;
            }

            .maf-tooltip-body {
              margin: 1.5em 0 3.5em;
              visibility: hidden;
              opacity: 0;
              transition: opacity 0.8s ease-in;
              .maf-megamenu-ad {
                & > div {
                  max-width: 300px;
                  @include media-breakpoint-up(xxl) {
                    max-width: initial;
                  }
                }
              }
            }

            .nav-link-container:focus {
              outline: none;
              background-color: var(--maf-mist);
            }

            .nav-link-container-how-it-work:hover {
              outline: none;
              background-color: var(--maf-mist);
            }

            .nav-link-container:hover .tooltip-content {
              visibility: visible;
              opacity: 1;
              z-index: 2; /* or any appropriate z-index value */
              max-height: 471px;
              .maf-tooltip-body {
                visibility: visible;
                opacity: 1;
              }
            }

            .nav-tooltip-container:hover .nav-link-container .tooltip-content {
              visibility: visible;
              opacity: 1;
              z-index: 2; /* or any appropriate z-index value */
              max-height: 471px;
              .maf-tooltip-body {
                visibility: visible;
                opacity: 1;
              }
            }

            .nav-link-container:hover {
              outline: none;
              background-color: var(--maf-mist);
              border-radius: 8px;
            }

            .navbar-nav {
              list-style: none; /* Remove default list styles for navbar items */
              padding: 0;
              margin: 0;
            }

            .nav-item {
              display: inline-block; /* Display navbar items horizontally */
              margin-right: 20px; /* Add margin between navbar items */
            }

            .maf-header-navitem-product-Subtext {
              margin: 0 15px 9px 0;
              font-size: 1.313em;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $dark;
            }
            .maf-navItem-product-image {
              width: 53px;
              height: 32px;
              border-radius: 3.1px;
              background-color: var(--primary-maf-blue-lightest);
              background-size: cover;
              background-repeat: no-repeat;
            }
            .maf-mega-menu-bracelet {
              background-image: url("../img/placeholders/mega-menu/img-mega-menu/bracelets@2x.png");
            }
            .maf-mega-menu-necklace {
              background-image: url("../img/placeholders/mega-menu/img-mega-menu/necklaces@2x.png");
            }
            .maf-mega-menu-smartId {
              background-image: url("../img/placeholders/mega-menu/img-mega-menu/medical-ids-2.png");
            }
            .maf-mega-menu-women {
              background-image: url("../img/placeholders/mega-menu/img-mega-menu/for-women@2x.png");
            }
            .maf-mega-menu-men {
              background-image: url("../img/placeholders/mega-menu/img-mega-menu/for-men@2x.png");
            }
            .maf-mega-menu-kid {
              background-image: url("../img/placeholders/mega-menu/img-mega-menu/for-kids@2x.png");
            }
            .maf-mega-menu-accessories {
              background-image: url("../img/placeholders/mega-menu/img-mega-menu/accessories@2x.png");
            }
            .maf-mega-menu-push {
              background-image: url("../img/placeholders/mega-menu/img-mega-menu/push-button@2x.png");
            }
            .maf-mega-menu-allMedic {
              background-image: url("../img/placeholders/mega-menu/img-mega-menu/smart-ids@2x.png");
            }

            .maf-nav-productBox {
              width: 182px;
              padding: 3px;
              border-radius: 16px;
              height: 108px;
              @include media-breakpoint-up(xxl) {
                width: 276px;
                padding: 16px;
              }
            }
            .maf-nav-productBox a {
              text-decoration: none !important;
            }
            .tooltip-content :hover {
              visibility: visible;
              opacity: 1;
            }
            .maf-nav-productBox:hover {
              background-color: var(--maf-mist);
            }
            .maf-collapse-bar-headertext {
              justify-content: space-between;
            }
            .maf-nav-product-Items {
              justify-content: space-between;
              margin-bottom: 0.5em !important;
            }
            .maf-product-Rectangle {
              display: flex;
              height: 288px;
              padding: 24px 59px 44px 24px;
              border-radius: 24px;
              background-color: var(--maf-mist);
              margin-bottom: 1.5em;
              @include media-breakpoint-up(xxl) {
                width: 384px;
              }
            }
            .dropdown-menu{
              border-radius: 8px;
              // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
              background-color: #ffffff;
              border: none;
              // padding: 32px 16px 16px 16px;              
              &.maf-mega-menu{
                position: fixed;
                width: 100%;
                left: 0;
                top: 2.8em;
                &> div{
                  padding: 0;
                  transition:all 0.3s ease-in-out;
                  & > div{
                    margin-bottom: .794%;
                    transition:all 0.3s ease-in-out;
                    gap: 3.2%;
                    li{
                      transition:all 0.3s ease-in-out;
                      a.dropdown-item{
                        .maf-navItem-header{
                          justify-content: space-between;
                          display: flex;
                          margin-bottom: 1.64%;
                          .maf-navItem-category-header{
                            font-family: "SourceSansPro Regular", helvetica, sans-serif;
                            font-size: 1.3125em;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: var(--maf-main-navigation-font);
                            height: 27px;  
                            padding: 0;                  
                          }
                          .maf-navItem-product-image{
        
                          }
                        }
                        .maf-navItem-product-details{
                          font-family: "SourceSansPro Regular", helvetica, sans-serif;
                          font-size: 1em;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: normal;
                          letter-spacing: normal;
                          color: var(--maf-main-navigation-font);
                          white-space: normal;
                        }
                        &:hover{
                          text-decoration: none;
                        }
                      }
                    }
                  }                              
                }
                aside.maf-product-ad{                  
                  width: 38.096%;
                  &>div{                  
                    border-radius: 24px;
                    background-color: var(--maf-mist);
                    padding: 6.25%;
                    p{
                      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
                      font-size: 1.3125em;
                      font-weight: 600;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      color: #051424;
                      margin-bottom: 0.38096em;
                    }
                    div.maf-review-stars{
                      line-height: 1;
                    }
                    figure{
                      margin: 0;
                      margin-top: 4.168%;
                      text-align: center;                      
                      img{
                        max-width: 222px;
                        width: 100%;
                      }
                    }
                  }
                  a{
                    width: 100%;
                    margin-top: 1.5em;
                    padding: .5875em 1.5em;
                    line-height: 1.7;
                    font-family: "SourceSansPro Semibold";
                    font-weight: 500;
                    font-weight: normal;
                    border-color: var(--maf-primary-hover);
                    min-width: 87px;
                    border-radius: 1.5em;
                    max-height: 48px;
                    min-height: 48px;
                    height: 100%;
                    text-decoration: none;
                    color: var(--maf-primary-outline-font);
                    &:hover{
                      color:#ffffff;
                    }
                  }
                }
                &.show{
                  display: flex;
                  padding: 2.2227% 1.667% 3.5em;
                }
                .maf-mega-menu-list-items{
                  li{
                    max-width: 20vw;
                    &:hover{
                      background-color: var(--maf-mist);
                    }
                  }
                }                
              }
              li{
                // margin-bottom: .708%;
                padding: 1em;
                border-radius: 16px;
                // max-width: 276px;
                max-height: 108px;
                // margin-right: 2.8318%;
                // width: 28%;
                height: 100%;
                &:last-of-type{
                  padding-bottom: 1em;
                }
                a{
                  padding: 0;
                  font-size: 1em;
                  font-family: "SourceSansPro Regular", helvetica, sans-serif;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: var(--maf-main-navigation-font);
                  text-decoration: none;
                  &:hover{
                    background: none;
                    text-decoration: underline;
                  }
                }
                &:last-of-type{
                  margin-right: 0;
                }
              }
              &.dropdown-menu-single{
                padding: 0;                
                max-width: 189px;
                box-shadow: 0px 14px 16px 0 rgba(0, 0, 0, 0.24);
                li{
                  padding: 1em 1em 0.5em;
                  &:last-of-type{
                    padding-bottom: 1em;
                  }
                }
              }
            }
            &:hover{
              &> ul{
                &.dropdown-menu{
                  display: flex;
                  padding: 2.2227% 1.667% 3.5em;
                  border-radius: 0 0 24px 24px;
                  border-top: solid 14px #fff0;
                  &.dropdown-menu-single{
                    padding: 0;
                    display: block;
                    border-radius: 0 0 8px 8px;
                    border-top: solid 1em #fff0;
                    // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.24);
                    background-color: #fff;
                    border-color: #fff;
                    & > span{
                      box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, .24);
                      position: absolute;
                      z-index: -2;
                      background: #fff;
                      width: 100%;
                      height: 83%;
                      border-radius: 0 0 8px 8px;
                      bottom: 0;
                      left: 0;
                      li{
                        // padding: 1em 1em 0;
                        &:hover{
                          background: none;
                        }
                        &:last-of-type{
                          padding-bottom: 1em;
                        }
                      }
                    }                    
                  }
                }
              }
            }
          }
        }
      }

      .maf-component-navigation-utility {
        align-items: center;
        justify-content: flex-end;
        .navbar-nav {
          flex-direction: row;
          gap: 4px;
          .nav-item {
            display: flex;
            align-items: center;
            justify-content: center;
            a {
              &.maf-nav-link {
                border-radius: 25px;
                line-height: 0;
                padding: 0.8127em;
                position: relative;
                &:hover {
                  background: #e5e7e8;
                }
                &:focus-visible {
                }
              }
              &.btn {
                width: 114px;
                min-height: 40px;
                max-height: 40px;
                border-radius: 24px;
                font-size: 0.875em;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 24px;
              }
              &.btn-outline-primary-donate {
                color: var(--maf-primary-outline-font);
                --bs-btn-border-color: var(--maf-dark-blue);
                --bs-btn-hover-bg: #fff;
                line-height: 1.7;
                font-family: "SourceSansPro Semibold";
                font-weight: normal;
                min-width: 87px;
                height: 100%;
                z-index: 99;
                text-decoration: none;
                .maf-icon-filled-heart {
                  width: 14px;
                  height: 14px;
                  margin: 3px 0 1px 8px;
                  object-fit: contain;
                }
                &:hover {
                  border-color: var(--maf-dark-blue);
                  background: var(--maf-dark-blue);
                  color:var(--maf-success-text-hover);                  
                  path {
                    fill: #fff;
                  }
                }
              }
              &.minicart-link{
                border-radius: 25px;
                line-height: 0;
                padding: .8127em;
                position: relative;
              }
            }
            &.maf-login-icon{
              a{
                text-transform: capitalize;
              }
            }
          }
        }

        .maf-component-search {
          width: 152px;
          height: 38px;
          border-radius: 8px;
          z-index: 99;
          form {
            > div {
              background-color: var(--maf-search-input-background) !important;
              border-radius: 8px;
              border: solid 1px var(--maf-search-input-background);
              &:focus-within {
                border: solid 1px #828991;
              }
              .input-group-prepend {
                background-color: var(--maf-search-input-background);
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .maf-search-button:hover {
                background-color: var(--maf-search-input-background) !important;
                border: none;
              }
              input {
                background-color: var(--maf-search-input-background) !important;
                box-shadow: none;
                cursor: text;
                height: 40px;
                -webkit-appearance: none;
                padding: 6px 6px;
              }
              input::placeholder {
                color: var(--maf-lighter-dark);
                font-family: "SourceSansPro Regular", helvetica, sans-serif;
                font-size: 17px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.53;
                letter-spacing: normal;
                text-align: left;
                color: #828991;
              }
              button {
                margin: 0;
                min-width: auto;
                background: none;
                i.maf-icons-search{
                  min-height: 18px;
                  min-width: 18px;
                  display: inline-block;
                  background-repeat: no-repeat;
                  background: url(https://www.medicalert.org/static/img/ui-icons/icons-search.svg);
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
      .maf-nav-utility-mobile{
        .maf-login-icon{
          span{
            display: none;
          }
        }
        .maf-minicart-icon{
          .maf-chip{
            font-family: "SourceSansPro Black", helvetica, sans-serif;
            width: 19px;
            height: 19px;
            display: block;
            text-align: center;
            border-radius: 25px;
            text-align: center;
            position: absolute;
            top: -0.3em;
            right: -0.3em;
            line-height: 1.5;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            letter-spacing: 0 !important;
            &.maf-chip-minicart-quantity{
              font-size: .75em;
              background-color: var(--maf-minicart-chip);
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.maf-organism-header-new {

  // [START] CANVAS OVERLAY
	.bs-canvas-overlay,
  .bs-canvas-overlay-desktop {
    opacity: 0;
    z-index: 2;
    display: none;
  }

  .bs-canvas-overlay.show {
    opacity: 0.5;
    z-index: 1100;
    top: 0;
    display: block;
  }

  .bs-canvas-overlay, .bs-canvas {
    transition: all .4s ease-out;
    -webkit-transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -ms-transition: all .4s ease-out;
  }
  // [END] CANVAS OVERLAY

  .mr-0, .mx-0{
      height: 100% !important;
      margin-right: 0 !important;
  }

  .bs-canvas-right {
    right: 0;
    margin-right: -330px;
  }

  .bs-canvas{
      top: 0;
      z-index: 99999;
      overflow-x: hidden;
      overflow-y: auto;
      border: 0;
      width: 320px;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      padding: .75em 1em 1em;
      overflow-y: scroll;
      .canvas-header{
        padding: 0;
        margin-bottom: .75em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .close-secondary-sidebar{
          padding: .8125em;    
          line-height: 0;
        }
        .bs-canvas-close{
          &:hover{
            background:none;
          }
        }
        .canvas-title{
          font-size: 1.125em;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 0;
        }
        .maf-sidebar-iconBox{
          gap: 8px;
          .maf-sidebar-userIcon,
          .maf-btn-close{
            padding: .8125em;
            color: #051424;
            opacity: 1;
            line-height: 0;
          }
        }
      }
      .canvas-body{
        .mobile-main-nav-group{
          &.mobile-main-nav-ad{
            background-color: var(--maf-mist);
            padding: 24px 24px 28px;
            border-radius: 24px;
            margin-top: 32px;
            a{
              text-decoration: none;
              .maf-product-name{
                font-family: "SourceSansPro Semibold", helvetica, sans-serif;
                font-size: 1.3125em;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: var(--maf-main-navigation-font);                
              }
              .maf-product-review{
                margin-top: 8px;
              }
              figure.maf-product-image{
                text-align: center;
                margin-top: 41px;
                img{
                  height: auto;
                }
              }
            }               
          }
          &.mobile-main-nav-group-1,
          &.mobile-main-nav-group-3{
            .maf-sidebar-subheader{
              font-size: .75em;
              font-weight: bold;
              font-family: "SourceSansPro bold", helvetica, sans-serif;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: 1px;
              color: #457b9d;
              margin: 0 0 8px;
            }
            ul.navbar-nav{
              li.nav-item{
                margin: 0 0 4px;
                &:last-of-type{
                  margin: 0;
                }
                .nav-link{
                  padding: .797em 0;
                  a{
                    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
                    font-size: 1.125em;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #051424;
                    text-decoration: none;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  }
                }
              }
            }
          }
          &.mobile-main-nav-group-2{
            margin: 16px 0 0;
            a{
              &.btn{
                margin: 0;
                width: 100%;
                height: 40px;
                font-size: 0.875em;
                line-height: 1.5;
                min-height: 40px;                
                vertical-align: middle;
                padding: .5875em 1.5em;
                font-family: "SourceSansPro Semibold";
                font-weight: normal;
                border-radius: 1.5em;
                text-decoration: none;
                color: var(--maf-primary-outline-font);
                &:hover{
                  color: white;
                  path{
                    fill: white;
                  }
                }
                svg{
                  margin-left: 8px !important;
                }
              }              
            }
          }
          &.mobile-main-nav-group-3{
            margin-top: 32px;
          }       
        }
      }
  }  
  .maf-nav-panels-mobile {
    .canvas-header {
      .login-text-panel{
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        display: inline-block;
        margin-left: 8px;
        font-weight: 600;
        vertical-align: middle;  
        text-transform: capitalize;      
      }
    }
  }

  // [START] ANIMATION HEADER
  .dropdown:hover {
    .dropdown_menu--animated {
      display: block;
    }
  }
  
  .dropdown_menu--animated {
    display: none;
    li,
    aside{
      animation: fadeIn .8s ease-in;
    }
    &.dropdown_menu-slidedown {
      animation: growDown .3s ease;
      transform-origin: top center;  
    }
  }
  
  @-moz-keyframes growDown {
    0% {transform: scaleY(0);}
    100% {transform: scaleY(1);}
  }
  
  @-webkit-keyframes growDown {
    0% {transform: scaleY(0);}
    100% {transform: scaleY(1);}
  }
  
  @-o-keyframes growDown {
    0% {transform: scaleY(0);}
    100% {transform: scaleY(1);}
  }
  
  @keyframes growDown {
    0% {transform: scaleY(0);}
    100% {transform: scaleY(1);}
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; } 
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; } 
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; } 
    100% { opacity: 1; }
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; } 
    100% { opacity: 1; }
  }
  // [END] ANIMATION HEADER

}

@include media-breakpoint-up(md) {
  .maf-organism-header-new{

    nav.maf-organism-header-navbar {
      padding: 1em 0.5em;
      & > div.header-main-wrap {
        .maf-element-logo {
          z-index: 99;
          svg {
            max-width: 198px;
          }
        }
        
        .maf-component-navigation-main {
          text-align: left;
          justify-content: space-around;
          ul {
            .nav-item {
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
        .maf-component-navigation-utility {
          gap: 7px;
          min-width: 365px;
        }
      }
    }
  }

}
@include media-breakpoint-up(md) {
  .maf-organism-header-new{

    nav.maf-organism-header-navbar {
      padding: 1.25em 1em;
      & > div.header-main-wrap {
        .maf-element-logo {
          z-index: 99;
          svg {
            max-width: 198px;
          }
        }
        
        .maf-component-navigation-main {
          text-align: left;
          justify-content: space-around;
          ul {
            .nav-item {
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
        .maf-component-navigation-utility {
          gap: 7px;
          min-width: 365px;
          .maf-nav-icons-utility{
            .maf-login-icon-desktop{
              animation: fadeIn 2.3s forwards;
            }
            .maf-login-icon{              
              animation: fadeIn 3.3s forwards;
              &.dropdown{
                &:hover{
                  .dropdown-menu{
                    display: block;
                    &.dropdown-menu-single{
                      padding: 0;
                      display: block;
                      border-radius: 0 0 8px 8px;
                      max-width: 189px;
                      box-shadow: 0px 14px 16px 0 rgba(0, 0, 0, .24);
                      background-color: #fff;
                      border-color: #fff;
                      top: 2.6em;                                                
                      li{                        
                        padding: 1em 1em 0.5em;
                        max-height: 108px;
                        height: 100%;
                        &:last-of-type{
                          padding-bottom: 1em;
                        }
                        a{                          
                          padding: 0;
                          font-size: 1em;
                          font-family: "SourceSansPro Regular", helvetica, sans-serif;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: normal;
                          letter-spacing: normal;
                          text-decoration: none;
                          color: var(--maf-main-navigation-font);                        
                          &:hover{
                            background:none;
                            text-decoration: underline;
                          }
                        }
                      }
                    }
                  }
                }
                a{
                  gap:8px;
                  text-decoration: none;
                  color: inherit;
                  &:hover{
                    background: var(--maf-iron);
                    border: solid 1px var(--maf-iron);
                  }
                }
              }
            }
            .maf-minicart-icon{
              .maf-chip{
                font-family: "SourceSansPro Black", helvetica, sans-serif;
                width: 19px;
                height: 19px;
                display: block;
                text-align: center;
                border-radius: 25px;
                text-align: center;
                position: absolute;
                top: -0.3em;
                right: -0.3em;
                line-height: 1.5;
                display: flex !important;
                justify-content: center !important;
                align-items: center !important;
                letter-spacing: 0 !important;
                &.maf-chip-minicart-quantity{
                  font-size: .75em;
                  background-color: var(--maf-minicart-chip);
                  color: #fff;
                }
              }
            }
          }          
        }
      }
    }
  }

}

@media (max-width: 374px) {  
  .maf-organism-header-new{
    #maf-global-navbar{
      .header-main-wrap{
        .maf-element-logo{
          width: 45%;
          svg{
            width: 100%;
          }
        }
        .maf-nav-utility-mobile{
          .maf-component-navigation-utility{
            ul{
              li{
                width: 33px;
              }
            }
          }
        }
      }
    }
  }
}
