/*
    ===============
        LAYOUTS 
    ===============
*/

// [START] GENERAL LAYOUT
figure{
    margin: 0;
}

.maf-component,
.maf-organism{
    position: relative;
}

.container{
    max-width: 100%;
}

.maf-message{
    max-width: 1176px !important;
    margin: 0 auto;
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1 !important;
    background-color: #00000082;
    /* Adjust the opacity and color as needed */
    z-index: 999;
    backdrop-filter: blur(1px); /* Adjust the blur intensity as needed */
    overflow: hidden; /* Disable scrolling */

}

.overlay-nav{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000082;
    /* Adjust the opacity and color as needed */
    z-index: 999;
    backdrop-filter: blur(1px); /* Adjust the blur intensity as needed */
    overflow: hidden; /* Disable scrolling */
}

.modal{
    z-index: 999999;
}

@include media-breakpoint-up(lg) {
    .container{
        max-width: 1440px;
    }
}


// [END] GENERAL LAYOUT
