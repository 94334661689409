.Wizard-Order-Confirmation-Page {
  .Wizard-Order-Confirmation-Page-container {
    max-width: 648px;
    margin: auto;
    margin-top: 56px;
    @media only screen and (min-width: 690px) and (max-width: 1200px) {
      max-width: 100%;
      margin-left: 72px;
      margin-right: 72px;
    }
    @media (max-width: 690px) {
      margin-left: 16px;
      margin-right: 16px;
    }
    .Order-Confirmation-Page-progressbar {
      display: flex;
      gap: 14px;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      @media (max-width: 690px) {
        gap: 8px;
      }
      .wizard-progress {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #0f7ef8;
        @media only screen and (min-width: 690px) and (max-width: 1200px) {
          font-size: 20px;
        }
        @media (max-width: 690px) {
          font-size: 14px;
          line-height: 1.14;
        }
      }
      .Wizard-checked-circle::before {
        content: "✓";
        font-size: 12px;
        color: #fff;
        border: solid 1px #0f7ef8;
        background-color: #0f7ef8;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (min-width: 690px) and (max-width: 1200px) {
          font-size: 10px;
          width: 18px;
          height: 18px;
        }
        @media (max-width: 690px) {
          font-size: 8px;
          width: 14px;
          height: 14px;
        }
      }
      .bar {
        width: 25px;
        height: 1px;
        flex-grow: 0;
        background-color: #0f7ef8;
        @media (max-width: 690px) {
          width: 16px;
        }
      }
    }
  }
  .Order-Confirmation-Page-header {
    .header {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 40px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #051424;
      margin-bottom: 16px;
      @media (max-width: 690px) {
        font-size: 24px;
        margin-bottom: 8px;
      }
    }
    .Description {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #051424;
      margin-bottom: 40px;
      @media only screen and (min-width: 690px) and (max-width: 1200px) {
        margin-bottom: 32px;
      }
      @media (max-width: 690px) {
        font-size: 18px;
        margin-bottom: 24px;
      }
      .order-user-name-email{
        @media (max-width: 390px) {
          display: inline;

        }

      }
    }
  }

  .Order-Confirmation-Page-main-container {
    .header {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-bottom: 16px;
      @media (max-width: 690px) {
        margin-bottom: 8px;
      }
    }
    .Deatils {
      margin-bottom: 24px;
      @media (max-width: 690px) {
        margin-bottom: 16px;
      }
      .Box {
        padding: 24px;
        border-radius: 14px;
        background-color: #f8f7f5;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        @media (max-width: 690px) {
          padding: 16px;
        }
        .row {
          justify-content: space-between;
          padding: 0;
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
        .col {
          padding: 0;
        }
        .custom-col {
          text-align: end;
          font-family: "SourceSansPro Regular", helvetica, sans-serif;

        }
      }
    }
    .Order-Confirmation-Page-billing {
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      @media (max-width: 690px) {
        font-size: 16px;
      }

      .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px !important;
        .custom-col {
          text-align: end;
        }
      }
      .order-row {
        margin-bottom: 24px !important;
        @media (max-width: 690px) {
          margin-bottom: 40px !important;
        }
        .col {
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          font-size: 21px;
          @media (max-width: 690px) {
            font-size: 18px;
          }
        }
      }
    }
  }

  .Billing {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    button {
      margin-top: 0;
    }
  }
  .shoppingLink-conatainer {
    display: flex;
    justify-content: center;
    margin-bottom: 56px;

    @media (max-width: 1200px) {
      margin-bottom: 64px;
    }
    @media (max-width: 690px) {
      margin-bottom: 80px;
    }

    .shoppingLink {
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #1d3557;
      cursor: pointer;
    }
  }
}
