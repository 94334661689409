/*
    ========================
        BADGES SCSS FILE
    ========================
*/

.badge{
    font-size: 1em;
    font-family: "SourceSansPro Semibold", helvetica, sans-serif;
    min-width:62px;
    min-height:24px;
}

.maf-chip{
    font-family: "SourceSansPro Black", helvetica, sans-serif;
    width: 19px;
    height: 19px;
    display: block;
    text-align: center;
    border-radius: 25px;
    text-align: center;
    position: absolute;
    top: -0.3em;
    right: -0.3em;
    line-height: 1.5;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    letter-spacing: 0 !important;
    &.maf-chip-minicart-quantity{
        font-size: 0.75em;
        background-color: var(--maf-minicart-chip);
        color:$light;
    }
}
