#video-container {
  max-width: 1176px;
  width: 100vw;
  height: 53vw;
  position: relative;
  margin: 0 auto;
}
#player {
  width: 100%  !important;
  height: 100%;
  border-radius: 24px;
}
#howitwork {
  width: 100%  !important;
  height: 100%;
  border-radius: 24px;
}
.html5-video-player .video-click-tracking,.html5-video-player .video-stream {
  display: block;
  width: 100%  !important;
  left: 0px !important;
  height: 100%;
  position: absolute
}
.ytp-iv-video-content{
  width: 1176px !important;
}
.video-container-body {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 2.5rem;
  transition: opacity 0.5s ease, height 0.3s ease;
  iframe{
    border-radius: 24px;
  }

}
.video-container-body-nav{
  opacity: 0;
}
#video-container-body.visible{
  opacity: 1;
}
#volume-button {
  margin-right: 24px;
  margin-bottom: 24px;  
  position: absolute;
  bottom: 0px;
  right: 0px;
  border: 0px solid #ccc;
  cursor: pointer;
  z-index: 1; /* Ensure the button is above the video */
  width: 64px;
  height: 64px;
  padding: 20px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  z-index: 3; 
}

  
  .video-container-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000; /* Ensure the video container is on top of the overlay */
    width: 100vw; /* Set the width to cover the window */
    height: 100vh; /* Set the height to cover the window */
    overflow: hidden;

  }
  
  .overlay{
    z-index: 9999 !important;
  }
  /* Style the close button (you can customize this) */
  .close-button {
    position: absolute;
    display: none;
    top: 0;
    z-index: 999999;
    right: 5px;
    font-size: 24px;
    cursor: pointer;
  }
  
  body #video-container.mobile-home-page-video-modal{
    z-index: 99999; 
    position: fixed; 
    top: 0px; 
    width: 100%; 
    height: 100%;
  }
  
  #close-button-home.close-button{
    display: none;
  }
  
  .mobile-home-page-video-modal #close-button-home.close-button{
    display: block;
  }

  @media (min-width: 1024px) {
    #video-container {      
      height: 662px;
    }
  }

  @media (max-width: 390px) {
    #video-container {    
        position: relative;
        margin-left: 16px;
        margin-right: 16px;
      }
      #volume-button {
        margin-right: 8px;
        margin-bottom: 8px;  
    }
  }
  @media only screen and (min-width: 391px) and (max-width: 960px) {
    #video-container {
        // width: 90% ;
        position: relative;
        margin-left: 16px;
        margin-right: 16px;
      }
      #volume-button {
        margin-right: 8px;
        margin-bottom: 8px;  
    }
    .video-container-body{
      margin-bottom: 2rem;
    }
  }