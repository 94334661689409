aside.maf-offcanvas{

  // [START] DEFAULT SIDE BAR
  &.offcanvas-end{
    padding: 2rem 1.5rem 0;
    width: 100%;
    max-width: 328px;
    border: none;
    @include media-breakpoint-up(lg) {
      padding: 2.5rem 2.5rem 0;
      max-width: 552px;
      padding-right: 2em;
    }
  }  

  .offcanvas-header{
    padding: 0;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    text-transform: uppercase;

    .offcanvas-title{
      font-family: "SourceSansPro Semibold";
      font-size:1.125rem;      
      font-weight: initial;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;  
      @include media-breakpoint-up(lg) {
        font-size: 1.3125rem;
      }
    }

    .offcanvas-close-button{
      opacity: 1;
      min-width: 1.25rem;
      height: 1.25rem;
      padding: 0;
      margin: 0;
      color: #1d3557;
    }
  }
  .offcanvas-body{
    scrollbar-width: thin;    
    padding: 0;
    padding-right: 0.5em;
    p{
      margin-bottom: 1.5rem;      
      font-family: "SourceSansPro Regular";      
      font-weight: initial;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      line-height: 1.13;
      font-size: 1.125em;
      @include media-breakpoint-up(lg) {        
        &.sidebar-header-paragraph{
          font-size: 1.3125rem;
          line-height: 1.39;
        }
      }
    }        
    .offcanvas-subtitle{      
      font-family: "SourceSansPro Semibold";
      font-size: 1.5em;
      font-weight: initial;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #457c9d;
      margin: 0 0 1.5rem;
      @include media-breakpoint-up(lg) {
        font-size: 2.5em;
      }
    }

    .offcanvas-subtitle-2{
      font-family: "SourceSansPro Semibold";
      font-size: 1.125em;
      font-weight: initial;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #457c9d;
      margin: 0.5rem 0;
      @include media-breakpoint-up(lg) {
        font-size: 1.3125em;
        margin: 0 0 0.5rem;
      }
    }
  
  }  
  // [END] DEFAULT SIDE BAR

  // [START] ONLY TIPS FOR ENGRAVING SIDE BAR
  &.maf-offcanvas-tips-for-engraving{

    .offcanvas-body{
      p{
        margin-bottom: 2rem;
        line-height: 1.38889; 
      }
  
      ul{
        &.icons-list{
          padding: 0;
          list-style: none;
          margin-bottom: 24px;
        }
  
        li{
          display: flex;
          gap: 12px;
          align-items: center;
          margin-bottom: 24px;
          &:last-of-type{  
            margin-bottom: 0;
          }  
          figure{
            img{
              width: 30px;
              height: 28px;
            }
          }
          p{
            margin: 0;
            font-family: "SourceSansPro Semibold";
            font-size: 0.875rem;
            font-weight: initial;  
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: #1d3557;
            @include media-breakpoint-up(lg) {
              font-size: 1.125rem;
            }
          }           
        }      
      }
  
      .bottom-copy{
        margin-bottom: 40px;
        p{
          margin: 0 0 24px;
          font-family: "SourceSansPro Regular";
          font-size: 0.875rem;
          font-weight: initial;  
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          &:last-of-type{
            margin: 0;
          }
          a{
            font-family: "SourceSansPro Semibold";
            color: #051424;
          }
          @include media-breakpoint-up(lg) {
            font-size: 1.125rem;
          }
        }
      } 
      
    }

  }
  // [END] ONLY TIPS FOR ENGRAVING SIDE BAR

  // [START] ONLY NECKLACE SIZES SIDE BAR
  &.maf-offcanvas-sizing-necklace{
    .offcanvas-body{
      figure{
        margin-bottom: 40px;
        img{
          width: 100%;
        }
      }
    }

  }
  // [END] ONLY NECKLACE SIZES SIDE BAR

  // [START] ONLY BRACELETS SIZES SIDE BAR
  &.maf-offcanvas-sizing-bracelet{
    .offcanvas-body{      
      .maf-two-columns{
        & > div{
          margin-bottom: 1.5rem;
          @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
          }
          gap: 1em;
          figure{
            height: 100%;
            img{
              width: 100%;
              @include media-breakpoint-up(lg) {
                min-width: 200px;
                max-width: 200px;
              }
            }
          }
        }     
      }
      table{
        text-align: center;
        width: 100%;
        background: #f8f8f8;
        border-radius: 1rem;
        margin: 0 auto 1.5rem;
        max-width: 430px;
        padding: 1.5rem 0;
        tr{
          th{
            font-family: "SourceSansPro Bold";
            -webkit-text-stroke: 0 #c0c4c8;            
            font-size: 0.875em;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #457c9d;
            padding-top: 1.5rem;
            &:nth-child(odd){
              padding-left: 0.75rem;
            }
            &:nth-child(even){
              padding-right: 1.25rem;
            }
            @include media-breakpoint-up(lg) {
              font-size: 1.125em;
              &:nth-child(odd){
                padding-left: 0.249rem;
              }
              &:nth-child(even){
                padding-right: 2.3rem;
              }
            }
          }
          td{           
            span{
              border-bottom: solid 1px #a9a9a9;
              width: 100%;
              display: block;
              margin: 0 auto;
              padding:.3125rem 0 .375rem;              
              font-size: 0.75em;
              max-width: 100px;    
              font-family: "SourceSansPro Bold";
              font-size: 0.75em;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.17;
              letter-spacing: normal;
              color: #1d3557;
              @include media-breakpoint-up(lg) { 
                font-size: 1.125em;               
                max-width: 160px;
                padding: .75rem 0 .4375rem;
              }    
            }
            &.no-border{
              span{
                border-bottom: none;
              }              
            }
            &:nth-child(odd){
              padding-left: 0.75rem;
            }
            &:nth-child(even){
              padding-right: 1.25rem;
            }                 
            @include media-breakpoint-up(lg) {
              &:nth-child(odd){
                padding-left: 0.249rem;
              }
              &:nth-child(even){
                padding-right: 2.25rem;
              }                 
            }             
          }
          &:last-of-type{
            td{
              padding-bottom: 1.5rem;
            }            
          }
        }

      }
    }

  }
  // [END] ONLY BRACELETS SIZES SIDE BAR


  input#onetime_donation_other_field:before {
      margin-right: 2px;
      content: "$";    
    }

    
}
