/*
    ==========================
        INPUTS & FORMS SCSS
    ==========================
*/

form{
    .form-floating{        
        &>label{
            padding: 0 .75rem;            
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            align-items: center;
            max-height: 48px;
            &:focus + label{
                font-size: 4px;                
            }            
        }
        &>.form-control{         
            min-height: calc(1.5rem + calc(var(--bs-border-width) * 2));
            max-height: 48px;
            max-width: 378px;            
            label{
                font-size: 0.875em;
            }
            &:not(:placeholder-shown)~label{
                font-size: 0.875em;
            }
            &:focus{
                box-shadow: none;
            }
        }
        &>.form-select{
            min-height: calc(1.5rem + calc(var(--bs-border-width) * 2));
            max-height: 48px;
            max-width: 378px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .footer-error{
        color: white !important;
    }
    
    .invalid-feedback{
        font-style: normal;
        margin-left: 1em;
    }
    .input-icons{
        svg{
            position: absolute;
            height: 100%;
            margin-left: 1em;
            z-index: 9;
        }
        input{
            &.form-control{
                padding-left: 2.43em;
                min-height: calc(1.5rem + calc(var(--bs-border-width) * 2));
                padding-top: 1.825rem;
            }         
        }
        label{
            padding-left: 3.1em;
        }
        &>label{
            &:focus + label{
                padding-left: 3.1em;
            }            
        }
    }
}

input,
select{
    &:focus {        
        &+label {
          font-size: 0.875em;
        }
    }
    font-family: $font-family-base;    
    &:-webkit-autofill {        
        background-color: none !important;        
        transition: background-color 5000s ease-in-out 0s;
    }

    &.maf-element-input-light{
        color:$light;
        background:var(--maf-footer-email-input-bg);
        border-color:var(--maf-footer-email-input-border); 
        &:focus {
            color:white;
            background:var(--maf-footer-email-input-bg);
            border-color:var(--maf-footer-email-input-border); 
        }
    }    
    &.form-control{
        font-size: 1.125em;
        border-color: var(--maf-input-disabled);
    }
    &:hover{
        border-color:var(--maf-input-hover-border);
    }
    &:disabled{
        border-color: var(--maf-input-disabled);
        color: var(--maf-input-disabled);
        background: none !important;        
        &:hover,
        &:focus{
            border-color:var(--maf-input-disabled);
        }
    }
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
