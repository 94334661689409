.container-banner {
  height: 640px;
  margin-top: -72px;
  border-radius: 0 0 32px 32px;
  padding: 20px;
  background-image: url('../img/placeholders/hero-banner/ma-mesh-gradient-bg_2024-01-12/ma-mesh-gradient-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 4rem;
  
  @media (max-width: 358px) {
    overflow-x: auto;
  }
  @media (max-width: 768px) {
    margin-bottom: 3.5rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1300px) {
    height: auto  !important;
    /* Your styles for screens between 768px and 1024px go here */
  }

  .banner-container {
    max-width: 1176px;
    margin: auto !important;
  }

  .maf-container {
    margin-bottom: 80px;
    padding: 0px;
    padding-left: 69.4px;
    @media only screen and (min-width: 768px) and (max-width: 1300px) {
      padding-left: 0px;
      /* Your styles for screens between 768px and 1024px go here */
    }

  }
  .maf-etalic {
     font-family: "SourceSansPro Italic";
  }
  .maf-banner {
    margin-top: 83px;
    @media (max-width: 768px) {
      margin-top: 0;
    }
    @media only screen and (min-width: 768px) and (max-width: 1300px) {
      display: flex;
      justify-content: left;
      margin-top: 0px !important;
      width: 100%;
      /* Your styles for screens between 768px and 1024px go here */
    }
    
  }
  .maf-ipad-view {
    @media only screen and (min-width: 768px) and (max-width: 1300px) {
      display: flex;
      justify-content: left;
      width: 100%;
    }
  }
  .maf-ipad-text {
    @media only screen and (min-width: 768px) and (max-width: 1300px) {
      // display: none  !important;
      
    }
  }
  .maf-ipad-texview {
    @media only screen and (min-width: 768px) and (max-width: 1300px) {
      display: block  !important;
      width: 300px;
      margin-left: 19px;;
    }
  }
  .banner-1 {
    width: 430px;
    height: 493px;
    margin-left: auto;
    margin-right: auto;    
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      width: 100%;
      max-width: 349px;
      height: 395px;
    }

    @media (max-width: 375px) {
      height: 333px;
    }      
    
    @media only screen and (min-width: 768px) and (max-width: 1300px) {
      display: flex;
    }
        
  }

  @media (max-width: 1300px) {
    height: auto;
    padding: 0 16px 0 !important;
  }

  .content-section {
    margin-top: 32px;
    .maf-head-text {
      // min-width: 424px;
      font-size: 4.5em;
      font-weight: normal;
      width: 476px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -1.29px;
      margin: 47px 0px 24px 0px;
      text-transform: none;
      font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      color: #1d3557;
      .empowering-red {
        color: #e63946;
      }
      @media only screen and (min-width: 265px) and (max-width: 767px)  {
        font-size: 3.5em;
        font-weight: normal;
        width: 100%;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.0715;
        letter-spacing: -1px;
        // margin: 0 16px 24px;
        margin: 0 0 24px;
        color: #1d3557;        
      }
      @media only screen and (min-width: 768px) and (max-width: 1300px)  {
        // width: 343px;
        // display: flex;
        width: 100%;
        height: auto;
        font-size: 56px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: -1px;
        margin: 10px 16px 0px;
        gap: 10px;
      }
      @media only screen and (min-width: 1024px) and (max-width: 1300px)  {
      .empowering-red {
        color: #e63946 !important;
      }
    }
      @media only screen and (min-width: 1301px) and (max-width: 1400px) {
        margin: 32px 0px 36px;
     }
    }

    .maf-medium-text {
      max-width: 376px;
      // height: 54px;
      margin: 24px 0 0 0px;
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 21px;
      line-height: normal;
      letter-spacing: normal;
      color: #051424;

      @media only screen and (min-width: 265px) and (max-width: 767px)  {
        max-width: 343px;
        // height: 81px;
        // margin: 0 16px 32px;
        margin: 0;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #051424;
      }
      @media only screen and (min-width: 760px) and (max-width: 1300px)  {
        width: 550px;
        // height: 81px;
        margin: 10px 16.5px 32px;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #051424;
      }
      @media only screen and (min-width: 1301px) and (max-width: 1400px) {
        margin: 24px 0 0 0px;
      }
    }

    .button-custom {
      width: 169px;
      height: 48px;
      margin: 32px 0 0 0px;
      // margin-left: 16px;
      svg{
        width: 16px;
        height: 16px;
      }

      @media only screen and (min-width: 265px) and (max-width: 1300px) {
        width: 169px;
        height: 48px;
        margin: 0px 189.5px 0px 16.5px;
      }

      @media only screen and (min-width: 1301px) and (max-width: 1400px) {
        margin-left: 60px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1300px)  {
        margin-left: 16px !important;
      }
    }

    .mobile-description-container {
      padding-left: 19px;
      .maf-comment-text {
        width: 343px;
        height: 112px;
        font-family: "SourceSerifPro-Regular";
        font-size: 1.125em;
        color: #051424;

        @media only screen and (min-width: 265px) and (max-width: 1300px)  {
          width: 343px;
          height: 112px;
          margin: 0px 13px 41px 0px;
          font-size: 1.125em;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.56;
          letter-spacing: normal;
          margin-top: -60px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1300px)  {
          display: none;
          
        }

      }
    }
  }

  .pt {
    padding-top: 64px;
  }

  .rectangle-box {
    width: 192px;
    height: 72px;
    border-radius: 16px 16px 0 0;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;

    .name{
      font-family: "SourceSansPro Bold";
      font-size: 1em;
      display: block;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
    }
    .member{
        font-family: "SourceSansPro Regular";
        font-size: 0.875em;
        display: block;
        margin-top: 8px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
    }
  }

  .text-style-1 {
    font-family: "SourceSansPro Regular";
      font-size: 14px;

  }
  .maf-comment-head {
    display: flex;
    align-items: end;
    height: 90px;
    width: 31px;
    font-family: "SourceSerifPro Bold";
    font-size: 4.5em;
    font-weight: bold;
    color: red;
    margin-top: 24px;
    margin-bottom: 10px;

  }
  .maf-comment-text {
    width: 224px;
    height: 158px;
    font-family: "SourceSerifPro-Regular";
    font-size: 1.125em;
    margin-bottom: 40px;
    margin-right: 184px;
    margin-top: -60px;
    line-height: 1.56;
    letter-spacing: normal;
    color: #051424;

    @media (max-width: 1300px) {
      font-size: 1.125em;
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px)  {
      margin-right: 0px;
      margin-top: -60px;
    }

    #quotes{
      opacity: 0;
    }
    
  }
  
  .maf-heading{
    @media (max-width: 1300px) {
      order:1;
      .button-custom{
        margin: 32px 0 0;
      }
    }
  }

  .maf-image{
    @media (max-width: 1300px) {
      order:3;
    }
  }

  .maf-quote{
    @media (max-width: 1300px) {
      order:2;
      .maf-container{
        padding: 0;
        margin-bottom: 0;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 1300px)  {
      padding-left: 16px !important;
    }
  }

}
