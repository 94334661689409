/*
    ============================
        CARDS COMPONENTS ONE
    ============================
*/
.cards-header-text{
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -1px;
    font-weight: normal;
}

.maf-message-card{
    margin-bottom: 4rem;
}    

.post-slide{
    width: fit-content !important;
    height: auto;
    max-height: auto; 
}

#member{
    font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
}

#member {
    .maf-blue
    {
        #membersince
        {
             font-family: "SourceSansPro Regular", helvetica, sans-serif;
        }
        #membercondition{
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
        }
    }
}

.maf-testimonial span,
.maf-testimonial blockquote{
    // color :var(--maf-testimonial);
    font-family: "SourceSansPro Semibold";
}

.maf-component-cards{
    .card{
        height: 100%; 
        background-color: var(--card-component-1-background);
        border: none;
        border-radius: 1.5em;
        max-width: 342px;
        max-height: auto;        
    }    
    &.maf-component-cards-one{
        max-width: 1176px;
        padding: 0;
        .owl-carousel{
            margin: auto;
            gap: 24px;
            .owl-stage-outer{    
                // max-height: 673px;
                padding: 0;
                padding-right: 100% !important;
                .owl-stage{
                    display: flex;
                    margin: 0 0 1.5em;
                    .owl-item{
                        display: flex;
                        flex: 1 0 auto;
                        justify-content: center;
                        // width : 342px !important;
                    }
                }
            }
            .owl-nav{
                text-align: center;
                // margin-top: 1.5em;
                button{
                    padding: 0.875em !important;
                    max-width: 48px;
                    max-height: 48px;
                    border: solid 1px #1e3557;
                    border-radius: 25px;
                    margin: 0 0.25em;
                    img{
                        display: block;                        
                    }
                }
            }
            &.off {
                display: flex;
            }        
        }
        .card-body{       
            text-align: center;
            max-width: 342px;
            figure{
                max-width: 310px;
                margin: 0 auto;
                img{
                    border-radius: 1.5em;
                }
            }            
            blockquote{
                font-size: 1.3125em;
                color:var(--card-member-quote);
                margin: 2em 0 0;
                line-height: 1.286;                
                max-width: 345px;
                margin: 1.5238em auto;
                margin-bottom: 0 ;
            }
            p{
                font-size: 1em; 
                line-height: normal;
                margin-top: 16px;
                margin-bottom: 0 !important;
            }            
            cite{
                margin-top: 1.5em !important;
                display: block;
                font-size: 1em;
                font-style: normal;
                color:var(--card-member-cite);
                line-height: 1.2813;
                font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
                span.maf-blue{
                    display: block;
                    color:var(--card-member-cite-history);
                    font-family: "SourceSansPro Regular", helvetica, sans-serif;
                }
            }
            div{
                &.maf-card-button{
                    margin-top: 2em !important;
                    a{
                        font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
                        border-radius: 24px;
                        padding: 0.8125em 0;
                        margin-top: 0;
                        line-height: normal;
                        font-size: 1em;
                        border: solid 1px #e5e7e8 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                    }
                }
            }
            
        }
    }
}

@include media-breakpoint-up(sm) {
    .maf-component-cards{    
        &.maf-component-cards-one{
            .owl-carousel{
                width: auto;
                .card-body{    
                    max-width: 500px;  
                    figure{
                        max-width: 100%;
                    }
                    // p{
                    //     margin-bottom: 24px !important;
                    // }
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .engraving-plan-cards{
        min-width: auto !important;
    }
}

@include media-breakpoint-up(lg) {
    .card{
        min-width: 100%;
        min-height: 723px;
    }
    .post-slide{
        max-height: 723px;
    }
    .maf-component-cards{    
        &.maf-component-cards-one{
            .owl-carousel{
                width: auto;
                .card-body{    
                    max-width: 100%;  
                    figure{
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .maf-message-card{
        margin: 0 16px;
        margin-bottom: 3.5rem;
    }    
}
