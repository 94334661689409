/*
    ========================
  COLLAPSE COMPONEMNT SCSS FILE
    ========================
*/
.maf-message-collapse {
   margin-bottom: 4rem;
  .maf-collapse-parentText.sticky {
    position: sticky;
    top: 0;
  }
  .maf-collapse-parentText {
    position: sticky;
    position: -webkit-sticky;
    top: 74px;
    // height: 40%;
    justify-content: center;
    align-items: center;
  }
 .maf-collapse-headertext{
    margin-bottom: 2rem;
    }
  .maf-collapse-parentText {
    word-wrap: break-word;
    h2.maf-collapse-subtext {
      font-weight: bold;
      font-size: 3.5em;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: #051424;
      line-height: 1.14;
      letter-spacing: -1px;
      margin-bottom: 16px;
    }
    p.maf-collapse-textContent {
      max-width: 576px;
      font-size: 1.125em;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      margin-bottom: 0;
    }
  }
  .maf-collapse-component-row {
    overflow: hidden;
  }

  .maf-collapse-component {
    .maf-collapse-rectangle {
      & > div {
        justify-content: end;
      }
      .maf-collapsible {
        margin-bottom: 1.6875em !important;
        height: 7em; // Set a specific height
        overflow: hidden; // Hide overflow to avoid unwanted scrollbars
        cursor: text;
        transition: height 0.3s ease-in; // Add transition for smooth effect
        .maf-collapse-bar-container {
          display: contents;
          .maf-collapse-bar {
            width: 0.5rem;
            border-radius: 0.5rem;
            background-color: var(--collapse-1-bar-collapsed);
            max-height: 212px;
          }
        }

        .maf-collapse-content-container {
          padding-top: 1em;
          padding-left: 1em;
          .maf-collapse-bar-headertext {
            max-height: 6em;
            justify-content: space-between;
            h3 {
              padding: 0;
              margin: 0;
              font-size: 1.75em;
              line-height: 1.11;
              letter-spacing: normal;
            }
            .maf-collapse-image {
              max-width: 136px;
              max-height: 83px;
              border-radius: 0.5rem;
            }
          }
          .collapse {
            display: none;
            padding: 0;
            padding: 0.625rem;
            .maf-collapse-bar-description {
              font-size: 1.125em;
              line-height: 1.56;
              letter-spacing: normal;
              color: var(--collapse-1-description);
            }
          }
        }

        &.expanded {
          height: 13.5rem !important;
          .collapse {
            display: block;
            padding: 0;
            margin-top: 1.5em;
            transition: height 0.3s ease-out; /* Add transition for smooth effect */
          }
          .maf-collapse-bar {
            background-color: var(--collapse-1-bar-expanded);
          }
        }

        &.ultimo{
          margin-bottom: 0 !important;
        }
        // &:hover {
        //   height: 11rem; // Set the height on hover
        //   .collapse {
        //     display: block;
        //     padding: 0;
        //     margin-top: 1.5em;
        //     transition: height 0.3s ease-out; /* Add transition for smooth effect */
        //   }
        //   .maf-collapse-bar {
        //     background-color: var(--collapse-1-bar-expanded);
        //   }
        // }
      }
    }
  }
}
$space-height: 200px; // Define a variable for the height

.blank-space {
    width: 100%;
    height: $space-height; // Use the variable for height
    // Add any additional styling within the block
}
@media (max-width: 920px) {
  .maf-message-collapse {
    margin-bottom: 3.5rem;
    .maf-collapse-parentText {
      position: initial;
      height: 100%;
    }
  }
  .maf-collapse-rectangle {
    & > div {
      justify-content: start !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .maf-message-collapse {
    margin-bottom: 4rem;
    .maf-collapse-parentText {
      position: initial;
      height: 100%;
      .maf-collapse-bar-headerSubtext {
        font-size: 1.5em;
      }

      h2.maf-collapse-subtext {
        font-size: 2.5em;
      }
    }

    .maf-collapse-component {
      .maf-collapse-rectangle {
        & > div {
          justify-content: start;
        }
        .maf-collapsible {
          .maf-collapse-content-container {
            padding-right: 0;
            .maf-collapse-bar-headertext {
              h3 {
                font-size: 1.5em;
              }
            }
            .collapse {
              margin-top: 1em;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .maf-message-collapse{
      margin: 0 16px;
      margin-bottom: 3.5rem;
  }    
}