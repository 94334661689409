/*
    ============================
        CARDS COMPONENTS ONE
    ============================
*/

@supports(display: grid) {

	.maf-card-right-text{
		color: #37434f;
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		font-family: "SourceSansPro Regular", helvetica, sans-serif;

	}

	.category-card-protected-button{
      margin-bottom: 3.5rem !important;
	  @media only screen and (min-width: 265px) and (max-width: 768px) {
		margin-bottom: 3rem !important;
		margin-top: 1.5rem !important ;
	  }
	}

	.maf-message-category-card{
		// max-width: 1176px;
		margin: auto;
	}	
  .maf-component-category-cards {	
	transition: margin-left 0.3s ease;
	margin-bottom: 3.5rem;
	.items {
		position: relative;
		width: 100%;
		overflow-x: scroll;
		overflow-y: hidden;
		white-space: nowrap;
		transition: all 0.2s;
		will-change: transform;
		user-select: none;
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
		cursor: -webkit-grab; 
		cursor: grab;
		&::-webkit-scrollbar {
			display: none;
		}
		.item {
			display: inline-block;
			margin-right: 1.5em;
			.category-card{
				width: 216px;
				min-width: 216px;
				text-decoration: none;
				border-radius: 1em;
				padding: 0.5em;
				background-color: var(--category-card-default-background);
				figure{
					a{
						text-decoration: none;
						img{
							border-radius: 1em;
							width: 100%;
						}
						figcaption{
							font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
							font-size: 1.3125em;
							padding: 0.9524em .5em;
							display: -webkit-box;
							display: -webkit-flex;
							display: -ms-flexbox;				
							display: flex;
							justify-content: space-between;
							align-items: center;
							color: #051424;

							span {
								font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
							}
						}						
					}
				}
				&:hover{
					background-color: var(--category-card-hover-background);
				}
			}
		}			
		&.active{
			cursor: grabbing;
			cursor: -webkit-grabbing;
		}
	}	
  }
  .category-card-header-text{
	line-height: 1.14;
	letter-spacing: -1px;
	padding: 0;
	span{
		display: block;
	}
 }

}

@include media-breakpoint-down(lg) {

	.maf-component-category-cards{
		margin-bottom: 2rem;
	}

    .maf-message-category-card{
        margin: 0 ;
		.maf-left{
			padding-left: 0 !important;
		}
		.maf-right{
			padding-left: 0 !important;
		}
    }    
}

@include media-breakpoint-up(lg) {
    .maf-message-category-card{
		.maf-right{
			padding-right: 0 !important;
			padding-left: 0 !important;
			margin-top: 6px;
		}
    }    
}
