/*
    ======================
        FONTS FILE SCSS
    ======================
*/

// Sans Serif
@font-face {
    font-family: "SourceSansPro Regular";
    src:  url('../../static/fonts/SourceSansPro/sourcesanspro-regular-webfont.ttf') format('truetype'),
          url('../../static/fonts/SourceSansPro/sourcesanspro-regular-webfont.woff') format('woff');

};
  
@font-face {
    font-family: "SourceSansPro Italic";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-italic-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-italic-webfont.woff') format('woff');
};

@font-face {
    font-family: "SourceSansPro Black";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-black-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-black-webfont.woff') format('woff');
};

@font-face {
    font-family: "SourceSansPro Black Italic";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-blackitalic-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-blackitalic-webfont.woff') format('woff');
};

@font-face {
    font-family: "SourceSansPro Semibold";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-semibold-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-semibold-webfont.woff') format('woff');
};

@font-face {
    font-family: "SourceSansPro Semibold Italic";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-semibolditalic-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-semibolditalic-webfont.woff') format('woff');

};

@font-face {
    font-family: "SourceSansPro Bold";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-bold-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-bold-webfont.woff') format('woff');
};

@font-face {
    font-family: "SourceSansPro Bold Italic";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-bolditalic-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-bolditalic-webfont.woff') format('woff');
};

@font-face {
    font-family: "SourceSansPro Light";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-light-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-light-webfont.woff') format('woff');
};

@font-face {
    font-family: "SourceSansPro Light Italic";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-lightitalic-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-lightitalic-webfont.woff') format('woff');
};

@font-face {
    font-family: "SourceSansPro Extralight";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-extralight-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-extralight-webfont.woff') format('woff');
};

@font-face {
    font-family: "SourceSansPro Extralight Italic";
    src: url('../../static/fonts/SourceSansPro/sourcesanspro-extralightitalic-webfont.ttf') format('truetype'),
         url('../../static/fonts/SourceSansPro/sourcesanspro-extralightitalic-webfont.woff') format('woff');
};

// Serif
@font-face {
    font-family: "SourceSerifPro Regular";
    src: url('../../static/fonts/SourceSansPro/SourceSerifPro-Regular.woff') format('woff');         
};

@font-face {
    font-family: "SourceSerifPro Black";
    src: url('../../static/fonts/SourceSansPro/SourceSerifPro-Black.woff') format('woff');         
};

@font-face {
    font-family: "SourceSerifPro SemiBold";
    src: url('../../static/fonts/SourceSansPro/SourceSerifPro-SemiBold.woff') format('woff');         
};

@font-face {
    font-family: "SourceSerifPro Bold";
    src: url('../../static/fonts/SourceSansPro/SourceSerifPro-Bold.woff') format('woff');         
};

@font-face {
    font-family: "SourceSerifPro Light";
    src: url('../../static/fonts/SourceSansPro/SourceSerifPro-Light.woff') format('woff');         
};

@font-face {
    font-family: "SourceSerifPro ExtraLight";
    src: url('../../static/fonts/SourceSansPro/SourceSerifPro-ExtraLight.woff') format('woff');         
};
