#join-button{
  visibility: hidden;
}
.button-anchor {
  display: block;
  width: fit-content;
  @media (max-width: 690px) {
    width: auto;
  }
}
.bundle-offer-page {
  #join-button {
    height: 40px;
    max-height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 14px 20px;
    border-radius: 24px;
    visibility: visible;
    @media (max-width: 690px) {
      font-size: 14px;
      width: 170px;
      padding: 0;
    }
    @media (max-width: 430px) {
      width: 100px;
    }
  }
}
.Cart-Everflow-Bundle-offer-landing-page {
  .maf-section-6 .owl-nav {
    margin-top: 0 !important;
  }
  .terms-and-offer {
    max-width: 1176px;
    margin-left: auto;
    margin-right: auto;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #051424;
    margin-bottom: 64px;
    @media only screen and (min-width: 690px) and (max-width: 1200px) {
      max-width: 100%;
      margin-left: 72px;
      margin-right: 72px;
    }
    @media (max-width: 690px) {
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 80px;
    }
  }
  .bundle-Offer-landing-page-section {
    max-width: 1176px;
    margin: auto;
    margin-top: 68px;
    @media only screen and (min-width: 690px) and (max-width: 1200px) {
      max-width: 100%;
      margin-left: 72px;
      margin-right: 72px;
    }
    @media (max-width: 690px) {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 32px;
    }
    .main-container-banner {
      margin: 0;
      margin-bottom: 53px;
      @media (max-width: 690px) {
        margin-bottom: 63px;
      }
      
      justify-content: space-between;
      .text-container {
        max-width: 620px;
        @media only screen and (min-width: 996px) and (max-width: 1200px) {
          width: 45%;
        }
        @media (max-width: 996px) {
          width: 100%;
        }

        .heading {
          align-self: stretch;
          flex-grow: 0;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          font-size: 72px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: -1.29px;
          text-align: left;
          color: #1d3557;
          margin-bottom: 24px;
          @media (max-width: 690px) {
            font-size: 56px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.07;
            letter-spacing: -1px;
            text-align: center;
            color: #1d3557;
          }
          .red {
            color: #e63946;
          }
        }
        .text-offer {
          align-self: stretch;
          flex-grow: 0;
          font-family: "SourceSansPro Bold", helvetica, sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: 1px;
          text-align: left;
          color: #457c9d;
          margin-bottom: 24px;
          @media (max-width: 690px) {
            font-size: 12px;
            line-height: 2;
            letter-spacing: 1px;
            text-align: center;
          }
        }
        .Description {
          align-self: stretch;
          flex-grow: 0;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          margin-bottom: 24px;

          @media (max-width: 690px) {
            font-size: 24px;
            line-height: 1;
            text-align: center;
          }
          span {
            color: #e63946;
            font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          }
        }
        #memberbutton {
          width: 234px;
          height: 52px;
          flex-grow: 0;
          padding: 0;
          gap: 10px;
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          @media (max-width: 690px) {
            width: 100%;
            margin-bottom: 40px !important;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .image-container {
        margin-left: auto;
        position: relative;
        display: flex;
        justify-content: end;
        @media only screen and (min-width: 691px) and (max-width: 960px) {
          justify-content: center;
          margin-top: 32px;
        }
        img {
          width: 420px;
          height: 348px;
          object-fit: contain;
          @media (max-width: 690px) {
            width: 100%;
            height: 286px;
          }
        }
        .icon-container {
          display: flex;
          padding: 16px;
          border-radius: 16px;
          background-color: #f0f9f9;
          width: 269px;
          height: 60px;
          gap: 8px;
          position: absolute;
          top: 236px;
          right: 216px;
          align-items: center;
          @media only screen and (min-width: 691px) and (max-width: 960px) {
            left: 50px;
          }
          @media (max-width: 690px) {
            top: 259px;
            right: 0;
            font-size: 18px;
            padding: 14px 16px 14px 16px;
            height: auto;
            width: auto;
          }
          .arrowImage{
            width: 58px !important;
            min-height: 48.4px !important;
            position: absolute;
            /* top: -33px; */
            right: 53px;
            bottom: 52.56px;
            @media (max-width: 690px) {
              display: none;
            }

          }
          .arrowImageMobile{
            display: none;
            @media (max-width: 690px) {
              display: block;
              display: block;
              width: 60.8px !important;
              min-height: 106.8px !important;
              position: absolute;
              bottom: 39.2px;
              right: 220.2px;
            }


          }
          img {
            width: 28px;
            height: 28px;
            @media (max-width: 690px) {
              width: 24px;
              height: 24px;
            }
          }
          .text {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 21px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            span {
              color: #e63946;
              font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            }
          }
        }
      }
    }
    .cards-container {
      .cards-header {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-size: 56px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: -1px;
        text-align: center;
        color: #051424;
        margin-bottom: 32px;
        @media (max-width: 690px) {
          font-size: 40px;
          line-height: 1.2;
          text-align: center;
          margin-bottom: 24px;
        }
        span {
          color: #e63946;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        }
      }
      .cards-Description {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: center;
        color: #051424;
        margin-bottom: 32px;
        @media (max-width: 690px) {
          font-size: 16px;
          line-height: 1.25;
          text-align: center;
          margin-bottom: 24px;
        }

        .first {
          margin-bottom: 32px;
          @media (max-width: 690px) {
            margin-bottom: 24px;
          }
          .count {
            font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            color: #e63946;
          }
          .people {
            font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          }
        }
        .second {
          span {
            font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            color: #457c9d;
          }
        }
      }
      .cards-box {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 24px;
        margin-bottom: 56px;
        @media only screen and (min-width: 769px) and (max-width: 1200px) {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          max-width: 100%;
        }
        @media only screen and (min-width: 570px) and (max-width: 768px) {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          max-width: 100%;
        }
        @media (max-width: 690px) {
          margin-bottom: 40px;
        }

        .cards {
          padding: 24px;
          border-radius: 16px;
          box-shadow: 10px 10px 20px 0 rgba(240, 249, 249, 0.6),
            10px -10px 20px 0 rgba(240, 249, 249, 0.6);
          background-color: #fff;
          min-height: 255px;
          max-width: 276px;
          @media (max-width: 690px) {
            max-width: 100%;
            min-height: 242px;
            box-shadow: 0 8px 16px 0 rgba(69, 124, 157, 0.15);
          }

          .image-div {
            width: 72px;
            height: 72px;
            flex-grow: 0;
            padding: 13px 8px;
            background-color: #f0f9f9;
            border-radius: 100px;
            margin-bottom: 16px;
            img {
              width: 56px;
              height: 46px;
              flex-grow: 0;
              object-fit: contain;
            }
          }
          .title {
            font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #457c9d;
            margin-bottom: 16px;
            @media (max-width: 690px) {
              font-size: 21px;
              margin-bottom: 12px;
            }
          }
          .Description {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            @media (max-width: 690px) {
              font-size: 18px;
            }
            span {
              font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            }
          }
        }
      }
    }
  }

  .maf-bundle-Offer-landing-page-section {
    margin-bottom: 56px;
    margin-top: 56px;
    @media (max-width: 690px) {
      margin-bottom: 40px;
      margin-top: 40px;
    }
    @media only screen and (min-width: 690px) and (max-width: 1200px) {
      max-width: 100%;
      margin-left: 72px;
      margin-right: 72px;
    }
    .cards-header-text {
      margin-top: 0 !important;
    }
    .maf-component-cards {
      .card-body {
        padding: 16px !important;
        figure {
          margin: 0 !important;
          max-width: 100% !important;
        }
      }
    }
    .owl-carousel {
      img {
        max-width: 344px;
        max-height: 344px;
      }
      .text {
        @media (max-width: 690px) {
          margin-top: 24px;
        }
        margin-top: 32px;
        margin-bottom: 16px;
      }
      .details {
        margin-bottom: 0 !important;
      }
      blockquote {
        margin: 0 !important;
        margin-bottom: 12px !important;
      }
    }
  }

  .bundle-Offer-landing-page-section-banner {
    width: 100%;
    align-self: stretch;
    display: flex;
    justify-content: center;
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: #f0f9f9;
    @media (max-width: 690px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .banner-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 64px;
      max-width: 1176px;
      flex-wrap: wrap;
      @media only screen and (min-width: 690px) and (max-width: 1200px) {
        margin-left: 72px;
        margin-right: 72px;
      }
      @media (max-width: 690px) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
    .banner-image {
      width: 341px;
      height: 329px;
      flex-grow: 0;
      object-fit: contain;
    }
    .banner-description {
      max-width: 548px;
      .header {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-size: 36px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #1d3557;
        margin-bottom: 24px;
        span {
          color: #e63946;
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        }
      }
      .heading {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        margin-bottom: 24px;
      }
      .points-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        @media (max-width: 690px) {
          gap: 16px;
        }
        button {
          width: 234px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          @media (max-width: 690px) {
            width: 100%;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
        .points {
          display: flex;
          gap: 8px;
          img {
            width: 18px;
            height: 18px;
            min-width: 18px;
          }
          .text {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            span {
              color: #457c9d;
              font-family: "SourceSansPro Semibold", helvetica, sans-serif;
            }
          }
        }
      }
    }
  }
}
