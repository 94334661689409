/*
    ============================
        ARTICLE CARDS COMPONENT
    ============================
*/

@media screen and (min-width: 1024px) {
    .maf-component-article-cards{    
            .maf-search-show {
            padding: 0;
        }
        .maf-article-cards {
            margin-right: auto;
            margin-left: auto;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 24px;
            height: 168px;
            flex-grow: 0;
            margin-bottom: 24px;
            border-radius: 16px;
            background-color: #f0f9f9;
        }
        .maf-article-cards:hover{
            background-color: #e9eff3;
        }
        .maf-article-image-desktop {
            flex: 0 0 auto;
            width: 120px;
            height: 120px;
            flex-grow: 0;
            margin: 0 16px 0 0;
            border-radius: 14px;
        }
        .maf-article-image-mobile {
            display: none;
        }
        .maf-article-text {
            flex: 1 1 auto;
            margin-right: auto;
            height: 115px;
            flex-grow: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-self: flex-start;
            gap: 8px;
        }
        .maf-article-card-title {
            flex-grow: 0;
            font-family: SourceSansPro SemiBold;
            font-size: 	1.3125em;
            height:22px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color:#051424;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .maf-article-card-date {
            align-items: end;
            height: 16px;
            font-family: SourceSansPro Regular;
            font-size: 1em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color:#051424;
        }
        .maf-article-card-category {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 0;
            gap: 5px;
            font-family: SourceSansPro Regular;
            font-size: 1em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color:#051424;
        }
        .maf-article-card-description {
            width: auto;
            height: 37px;
            flex-grow: 0;
            font-family: SourceSansPro Regular;
            font-size: 1.1250em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color:#051424;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .maf-article-cards-title-date {
            display: flex;
            height:21px;    
            justify-content: space-between;
        }
        .article-image-lg {
            width: 120px;
            height: 120px;
            border-radius: 14px;
        }
        .maf-article-card-date-mobile {
            display: none;
        }
        .maf-article-card-categoryDiv {
            display: flex;
            color:#051424;
        }
        .category:not(:last-child)::after {
            content: ", ";
        }
        .category{
            color:#434e5a;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            font-family: SourceSansPro Regular;
            // margin-right: 5px;
        }
        .category:hover{
            text-decoration: underline;
        }
  }
}

@media screen and (max-width: 767px) {
    .maf-component-article-cards{
            .maf-search-show {
            margin-right: 16px !important;
            margin-left: 16px !important;
        }
        .maf-article-cards {
            align-items: center;
            width: auto;
            flex-grow: 0;
            margin-bottom: 24px;
            border-radius: 16px;
            padding: 16px 16px 15px 16px;
            background-color: #f0f9f9;
        }
        .maf-article-cards:hover{
            background-color: #e9eff3;
        }
        .maf-article-section {
            gap: 24px;
            padding: 0;
        }
        .maf-article-image-desktop {
            display: none;
        }
        .maf-article-image-mobile {
            margin-bottom: 10px;
            aspect-ratio: 1/1;
        }
        .maf-article-card-title {
            font-family: SourceSansPro Semibold;
            font-size: 1.2500em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color:#051424;
            margin-bottom: 8px;
            max-height: 48px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .article-image-mb {
            height: 100% !important;
            border-radius: 14px;
            height: 100%;
            width: 100%;
        }
        .maf-article-card-date {
            display: none;
        }
        .maf-article-card-category {
            display: flex;
            flex-wrap: wrap;
            font-family: SourceSansPro Regular;
            gap: 5px;
            font-size: 1em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color:#051424;
            margin-bottom: 8px;
            height: 16px;
        }
        .maf-article-card-description {
            font-family: SourceSansPro Regular;
            font-size: 1.1250em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color:#051424;
            height: 37px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .maf-article-card-date-mobile {
            height: 20px;
            display: flex;
            align-items: center;
            font-family: SourceSansPro Regular;
            font-size: 1em;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color:#051424;
            margin-bottom: 8px;
        }
        .maf-article-card-categoryDiv {
            color:#051424;
            display: flex;
        }
        .category:not(:last-child)::after {
            content: ", ";
        }
        .category{
            color:#434e5a;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            font-family: SourceSansPro Regular;
            margin-right: 5px;
        }
        .category:hover{
          text-decoration: underline;
        }
    }
}   
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .maf-component-article-cards{
            .maf-search-show {
            padding: 0;
           
        }
        .maf-article-cards {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: auto;
            padding: 24px;
            height: 168px;
            flex-grow: 0;
            margin-bottom: 24px;
            border-radius: 16px;
            background-color: #f0f9f9;
        }
        .maf-article-cards:hover{
            background-color: #e9eff3;
        }
        .maf-article-image-desktop {
            width: 120px;
            height: 120px;
            flex: 0 0 auto;
            height: 120px;
            flex-grow: 0;
            margin: 0 16px 0 0;
            border-radius: 14px;
        }
        .maf-article-image-mobile {
            display: none;
        }
        .maf-article-text {
            flex: 1 1 auto;
            width: auto;
            height: 115px;
            flex-grow: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-self: flex-start;
            gap: 8px;
        }
        .maf-article-card-title {
            flex-grow: 0;
            font-family: SourceSansPro SemiBold;
            font-size: 21px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            height:22px;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .maf-article-card-date {
            align-items: end;
            height: 16px;
            font-family: SourceSansPro Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
        }
        .maf-article-card-category {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 0;
            gap: 5px;
            font-family: SourceSansPro Regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
        }
        .maf-article-card-description {
            height: 44px;
            flex-grow: 0;
            font-family: SourceSansPro Regular;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .article-image-lg {
            width: 120px;
            height: 120px;
            border-radius: 14px;
        }
        .maf-article-card-date-mobile {
            display: none;
        }
        .maf-article-card-categoryDiv {
            display: flex;
        }
        .category:not(:last-child)::after {
            content: ", ";
        }
            .category{
                color:#434e5a;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                text-align: left;
                font-family: SourceSansPro Regular;
                margin-right: 5px;
            }
    }
}
