.Wizard-accountInforamtion {
  .special-size-container {
    max-width: 1176px;
    margin: auto;
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 16px;
    padding: 24px;
    border-radius: 12px;
    border: solid 1px #c0c4c8;
    background-color: #fff;

    @media only screen and (min-width: 690px) and (max-width: 1200px) {
      max-width: 100%;
      margin-left: 72px;
      margin-right: 72px;
      margin-top: 32px;
    }
    @media (max-width: 690px) {
      margin-left: 16px;
      margin-right: 16px;
      padding: 16px;
      margin-top: 32px;
    }
    .container {
      display: flex;
      gap: 16px;
      padding: 0;
      @media (max-width: 690px) {
        gap: 8px;
      }
      .special-size-parent-containers {
        .header {
          font-family: "SourceSansPro Semibold", helvetica, sans-serif;
          font-size: 21px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          margin-bottom: 16px;
          @media (max-width: 690px) {
            font-size: 18px;
          }
        }
        .title {
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          margin-bottom: 16px;
          display: flex;
          gap: 8px;
          flex-wrap: wrap;
          align-items: center;
          @media (max-width: 767px) {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
          }
          span {
            font-family: "SourceSansPro SemiBold", helvetica, sans-serif;
            font-weight: normal;
          }
          .content {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: #051424;
            text-decoration: underline;
          }
          .question-circle {
            display: inline-block;
            width: 16px;
            height: 16px;
            position: relative;
            background-color: #fff; /* Circle color */
            border-radius: 50%;
            border: solid 1.5px #051424;
          }
          .question-circle::before {
            content: "?";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: black;
            font-size: 10px; /* Adjust the size */
            font-weight: bold;
            font-family: Arial, sans-serif;
          }
        }
      }
    }
    .special-image-container {
      width: 89px;
      height: 89px;
      min-width: 89px;
      min-height: 89px;
      flex-grow: 0;
      padding: 8px;
      border-radius: 12px;
      background-color: #f0f9f9;
      @media (max-width: 690px) {
        width: 52px;
        height: 52px;
        min-width: 52px;
        min-height: 52px;
        padding: 4px;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
    .special-size-containers {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 26px;
      flex-wrap: wrap;
      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
      .special-customize-size-container-active {
        background-color: #fff;
        padding: 2px;
        border: 2px solid #1d3557;
        border-radius: 100px;
        width: 59px;
        height: 32px;
        @media (max-width: 767px) {
          height: 30px;
          width: 56px;
        }
        .size {
          height: 24px;
          width: 51px;
          @media (max-width: 767px) {
            height: 22px;
            width: 48px;
          }
        }
      }
      .size {
        cursor: pointer;
        display: flex;
        width: 59px;
        height: 32px;
        justify-content: center;
        align-items: center;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        padding: 8px 16px;
        border-radius: 100px;
        background-color: #e9eff3;
        @media (max-width: 767px) {
          font-size: 14px;
          height: 30px;
          width: 56px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
        }
      }
    }
  }

  // Plans
  .fid-plans {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 56px;
    max-width: 1176px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 767px) {
      margin: 0 16px;
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-bottom: 44px;
    }

    .btn-container{
      display: none;
      @media only screen and (min-width: 768px) and (max-width: 1200px) {
        display: flex;
        justify-content: center;
        gap: 16px;
        margin-top: 24px;
      }
      .btn{
        min-width: 48px;
        max-width: 48px;
        height: 48px;
        max-height: 48px;
        border: solid 1px #1d3557;
        background-color: white;
        padding: 15px;
        border-radius: 50%;
        display: flex ;
        align-items: center;
        margin: 0;
        :focus{
          background-color: white;
        }
        :hover{
          background-color: white;
        }
      }


    }

    .fid-header-plans {
      align-self: stretch;
      flex-grow: 0;
      font-family: "SourceSansPro SemiBold";
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #051424;
      margin-bottom: 16px;
      @media only screen and (min-width: 690px) and (max-width: 1200px) {
        max-width: 100%;
        margin-left: 72px;
        margin-right: 72px;
      }
      @media (max-width: 690px) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }

    &__heading {
      font-family: "SourceSansPro SemiBold";
      font-size: 56px;
      line-height: 64px;
      letter-spacing: -1px;
      color: #051424;
      margin-bottom: 24px;
      text-align: center;
    }

    &__text {
      font-family: "SourceSansPro Regular";
      font-size: 18px;
      line-height: 28px;
      color: #051424;
      text-align: center;
      max-width: 1176px;
      margin-bottom: 69px;

      @media (min-width: 768px) {
        padding: 0;
      }
    }

    &__cards {
      display: flex;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      gap: 24px;

      .plan-card {
        height: 673px;
        width: 100%;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0 8px 16px 0 rgba(69, 124, 157, 15%);
        padding: 40px 32px 0;
        position: relative;

        &__info {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;

          .info-heading {
            font-family: "SourceSansPro SemiBold";
            font-size: 32px;
            line-height: 36px;
            color: #051424;
            width: 100%;
            text-align: center;
          }

          .info-price {
            font-family: "SourceSansPro SemiBold";
            font-size: 40px;
            line-height: 40px;
            text-align: center;
            color: #051424;

            &__small {
              font-size: 21px;
            }
          }

          .info-text {
            font-family: "SourceSansPro SemiBold";
            font-size: 24px;
            text-align: center;
            line-height: 24px;
            color: #051424;
            &__red {
              color: #e63946;
              font-size: 36px;
              line-height: 36px;
            }
          }
        }

        hr {
          margin: 39.5px 0;
          height: 1px;
          background-color: #c0c4c8;
        }

        &__benefits {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 16px;
          padding: 0;

          li {
            height: 18px;
            list-style-type: none;
            font-family: "SourceSansPro Regular";
            color: #051424;
            font-size: 18px;
            display: flex;
            align-items: center;
            gap: 7px;
          }
        }

        &__note {
          font-family: "SourceSansPro Italic";
          font-size: 16px;
          line-height: 20px;
          color: #051424;
          width: 100%;
          max-width: 300px;
          height: auto;
        }
      }
      .active-card-default {
        border: 2px solid #0f7ef8;
        @media (max-width: 690px) {
          border: 1px solid #0f7ef8;
        }
        @media only screen and (min-width: 768px) and (max-width: 1200px) {
          width: 380px !important;
         }
        
        &__most-popular {
          font-size: 21px;
          line-height: 21px;
          color: #fff;
          font-family: "SourceSansPro SemiBold";
          text-align: center;
          background-color: #0f7ef8;
          padding: 12px 30px;
          border-radius: 100px;
          position: absolute;
          top: -3.5%;
          left: 50%;
          transform: translateX(-50%);
          width: max-content;
        }
      }
    }

    @media (max-width: 1260px) {
      &__cards {
        display: flex;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    @media (max-width: 970px) {
      padding: 0;
      &__cards {
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      padding: 0;
      &__cards {
        display: flex;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      &__heading {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 16px;
        width: 343px;
      }

      &__text {
        margin-bottom: 32px;
      }

      &__cards {
        // flex-direction: column;
        width: 100%;
        margin: 0;
        .plan-card {
          width: 100%;
          height: 100%;
          padding: 32px 15px 32px 16px
        }
        .active-card {
          // margin-top: 23px;

          &__most-popular {
            font-size: 18px;
            line-height: 18px;
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 0;
      &__cards {
        display: flex;
        grid-template-columns: repeat(1, 1fr);
        .plan-card {
          width: 100%;
          height: auto;
          padding: 32px 15px 32px 16px;
        }
        .safe-card {
          margin-top: 20px;
        }
      }
    }
  }
  #planError{
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      margin-left: 72px;
    }

  }
  .Billing {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-bottom: 60px;
    }
    @media (max-width: 690px) {
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 80px;
    }
    #Billing {
      @media (max-width: 690px) {
        width: 100%;
      }
    }
  }
  #Billing {
    margin: 0 !important;
  }

  .fid-plans__cards{
    width: 100%;
    cursor: pointer;
  }

  .container-card-active{
    border: 2px solid #0f7ef8;
    position: relative;
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
     width: 380px !important;
    }
  
    @media (max-width: 690px) {
      border: 1px solid #0f7ef8;
    }
  }

  .container-card-active::before {
    content: "\2713"; /* Unicode for checkmark */
    font-size: 20px;
    color: white;
    background-color: #0f7ef8;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -16px;
    left: -20px;
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
      font-size: 15px;
      top: -10px;
      left: -10px;
    }
  
  }

  @media (max-width: 1200px) and (min-width: 768px) {
    .Display {
        display: none; // Ensure this class is hidden
    }

    .fid-plans__cards {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        overflow: hidden; 
        padding: 76px 0 0; 
        flex-direction: row;
        width: 100%;

        .plan-card {
            width: 376px; 
            height: 673px;
            flex: 0 0 auto; 
            scroll-snap-align: start; 
            transition: transform 0.3s ease; 
            display: none; 
        }

        .active-card-index {
            display: block; 
            transform: translateY(-50px); 
            z-index: 1; 
            transition: transform 0.3s ease; 
        }
        .visible {
            display: block; 
            position: relative; 
            z-index: 0; 
        }

        /* Optional: Hide scrollbar */
        &::-webkit-scrollbar {
            display: none; // Hide scrollbar for a cleaner look
        }
    }
}

  .Wizard-accountInforamtion-container {
    input::-webkit-contacts-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
    max-width: 1176px;
    margin: auto;
    margin-top: 56px;
    @media only screen and (min-width: 690px) and (max-width: 1200px) {
      max-width: 100%;
      margin-left: 72px;
      margin-right: 72px;
      margin-top: 32px;
    }
    @media (max-width: 690px) {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 24px;
    }
    .accountInforamtion-progressbar {
      display: flex;
      gap: 14px;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      @media (max-width: 690px) {
        gap: 8px;
      }
      .wizard-progress {
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #828991;
        @media only screen and (min-width: 690px) and (max-width: 1200px) {
          font-size: 20px;
        }
        @media (max-width: 690px) {
          font-size: 14px;
          line-height: 1.14;
        }
      }
      .Wizard-account {
        color: #0f7ef8;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
      }
      .Wizard-checked-circle::before {
        content: "✓";
        font-size: 12px;
        color: #fff;
        border: solid 1px #828991;
        background-color: #828991;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (min-width: 690px) and (max-width: 1200px) {
          font-size: 10px;
          width: 18px;
          height: 18px;
        }
        @media (max-width: 690px) {
          font-size: 8px;
          width: 14px;
          height: 14px;
        }
      }
      .bar {
        width: 25px;
        height: 1px;
        flex-grow: 0;
        background-color: #828991;
        @media (max-width: 690px) {
          width: 16px;
        }
      }
    }

    .navigation {
      margin-bottom: 16px;
      font-family: "SourceSansPro Regular", helvetica, sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #1d3557;
      .back-container {
        display: flex;
        gap: 8px;
        justify-content: left;
        align-items: center;
        cursor: pointer;
      }
      .gobackImage {
        width: 14px;
        height: 14px;
        transform: rotate(270deg);
      }
    }

    .Wizard-accountInforamtion-account-form {
      padding-bottom: 8px;
      @media (max-width: 690px) {
        padding-bottom: 0;
      }
    }

    .Wizard-accountInforamtion-account-form,
    .Wizard-accountInforamtion-inforamtion-form {
      border-radius: 12px;
      box-shadow: 0 4px 8px 2px rgba(55, 67, 79, 0.04),
        0 -2px 8px 2px rgba(55, 67, 79, 0.05);
      background-color: #fff;
      margin-bottom: 16px;
      flex-grow: 0;
      .waaf-form-header {
        align-self: stretch;
        flex-grow: 0;
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        font-size: 21px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        margin-bottom: 8px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24px;
        @media (max-width: 690px) {
          font-size: 18px;
          padding-left: 16px !important;
          padding-right: 16px !important;
          padding-top: 16px;
        }
      }
      .waaf-description {
        flex-grow: 0;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #051424;
        padding-right: 24px;
        padding-left: 24px;

        @media (max-width: 690px) {
          font-size: 14px;
          padding-left: 16px !important;
          padding-right: 16px !important;
        }
      }
      .line {
        height: 1px;
        align-self: stretch;
        flex-grow: 0;
        margin: 15px 0 16px;
        background-color: #e5e7e8;
      }
      .waaf-box {
        padding-left: 24px !important;
        padding-right: 24px !important;
        padding-bottom: 16px !important;
        display: flex;
        gap: 16px;
        @media (max-width: 690px) {
          padding-left: 16px !important;
          padding-right: 16px !important;
          padding-bottom: 16px !important;
          gap: 16px;
        }

        .col {
          position: relative;
          @media (max-width: 690px) {
            width: 100%;
            flex: unset;
          }
        }
        #togglePassword {
          cursor: pointer;
          position: absolute;
          right: 10px;
          top: 15px;
        }
        .box {
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          height: 51px !important;
          padding: 16px;
          border-radius: 8px;
          border: solid 1.5px #c0c4c8;
          background-color: #fff;
          font-family: "SourceSansPro Regular", helvetica, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #051424;
          width: 100%;
          :focus {
            border-color: #457c9d !important;
          }
          :active {
            border-color: #457c9d !important;
          }
        }
        input:focus {
          border: solid 1.5px #457c9d;
          outline: none !important;
        }
        input::placeholder {
          color: #c0c4c8 !important;
        }
        /* Hide the spinners in WebKit browsers (Chrome, Edge, Safari) */
        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Hide the spinners in Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
          appearance: textfield;
        }

        select {
          color: #c0c4c8 !important;
          box-shadow: unset;
          cursor: pointer;
          line-height: normal !important;
          height: auto !important;
          outline: unset;
          align-items: flex-end !important;
          align-items: center;
          appearance: none; /* Removes the default arrow */
          -moz-appearance: none;
          -webkit-appearance: none;
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" height="16" width="16"><polyline points="6 9 12 15 18 9"/></svg>')
            no-repeat right 16px center;
          background-size: 16px 16px; /* Set the size of the arrow to 16px by 16px */
          background-color: #fff; /* Background color */
        }
        select:focus {
          border-color: #457c9d !important;
        }
        select.clicked {
          color: #051424 !important;
        }
        #country {
          color: #051424 !important;
        }
        .password-verify-container {
          margin-top: 16px;
          display: none; /* Hidden by default */
          margin-left: 20px;
          @media only screen and (min-width: 690px) and (max-width: 1200px) {
            margin-top: 10px;
          }
          .requirement {
            list-style-type: disc;
          }

          .requirement.valid {
            color: #15b118;
            list-style-type: none;
            margin-left: -16px;
          }

          .requirement.not-valid {
            color: #ff0004;
            list-style-type: none;
            margin-left: -16px;
          }

          .requirement.not-valid::before {
            content: "✗ "; /* Checkmark */
            color: #ff0004;
          }

          .requirement.valid::before {
            content: "✓ "; /* Checkmark */
            color: #15b118;
          }

          .password-requirements,
          .password-requirements-header {
            font-family: "SourceSansPro Regular", helvetica, sans-serif;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: #434e5a;
            margin-bottom: 0;
            @media (max-width: 690px) {
              font-size: 14px;
              line-height: 1.29;
            }
          }
          .valid-section {
            color: #15b118;
            list-style-type: none;
            margin-left: -16px;
          }
          .valid-section::before {
            content: "✓ "; /* Checkmark */
            color: #15b118;
          }

          .not-valid-section {
            color: #ff0004;
            list-style-type: none;
            margin-left: -16px;
          }
          .not-valid-section::before {
            content: "✗ "; /* Checkmark */
            color: #ff0004;
          }
        }
        .password-verify-container.show {
          display: block; /* Show when required */
        }
      }
    }
    .Wizard-accountInforamtion-inforamtion-form {
      margin-bottom: 16px;
      padding-bottom: 8px;
      @media (max-width: 690px) {
        padding-bottom: 0px;
      }
    }

    .Wizard-accountInforamtion-inforamtion-form .waaf-box {
      @media only screen and (min-width: 690px) and (max-width: 1200px) {
        padding-bottom: 16px !important;
      }
    }
    .Wizard-accountInforamtion-inforamtion-form .waaf-box-lastRow {
      @media only screen and (min-width: 690px) and (max-width: 1200px) {
        padding-bottom: 24px !important;
      }
    }

    
  }
  .error-message {
    color: #ff0004;
    font-size: 14px;
    display: none;
    font-family: "SourceSansPro Regular", helvetica, sans-serif;
    margin: 0;
  }
}
