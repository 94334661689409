.plp-promo-banner{
    padding: 0;
    position: relative;
    display: inline-block;
    img{
        border-radius: 14px;
        max-width: 100%;
    }
    .promo-image-desktop-large-text{
        font-size: 1.125rem; 

    }
    .text-overlay-large {
        position: absolute;
        top: 40px;
        left: 20px;
        color: #051424;
        background-color: transparent; 
        padding: 10px; 
        font-size: 1.875rem; 
        line-height: 1.2;
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        @media only screen and (min-width: 840px) and (max-width: 1260px) {
            font-size: 1.3125rem; 
            top: 1.25rem; 
        }
        @media only screen and (min-width: 640px) and (max-width: 840px) {
            font-size: 1rem; 
            top: 1.25rem; 
            left: 0.625rem; 
        }
        @media only screen and (min-width: 440px) and (max-width: 640px) {
            font-size: .8rem;
            top: 0.625rem; 
            left: 0.625rem; 
        }
        @media only screen and (max-width: 440px) {
            font-size: 0.825rem;  
            top: 0.8125rem; 
            left: 0.3125rem; 
        }
        @media only screen and (max-width: 345px) {
            font-size: 0.725rem; 
            top: 0.8125rem; 
            left: 0.3125rem; 
        }
    }
    .promo-title-large {
        font-family: "SourceSansPro Semibold", helvetica, sans-serif;
        margin-bottom: 8px;
        @media only screen and (max-width: 560px) {
            margin-bottom: 5px;
        }
    }
}

.plp-promo-card-desktop {
    position: relative;
    display: inline-block;

    .promo-title {
        color: #051424;
    }


    .text-overlay-right{
        right: 20px;
        text-align: right;
    }

    .text-overlay {
        position: absolute;
        top: 40px; /* Define common values outside media queries */
        left: 20px;
        color: #457c9d; /* Change the color as needed */
        background-color: transparent; /* Optional: Add a background color with transparency */
        padding: 10px; /* Optional: Add padding for better readability */
        font-size: 1.3125rem; /* Default font size */
        font-family: "SourceSansPro Regular", helvetica, sans-serif;
        line-height: 1.5;

        /* Media queries */
        @media only screen and (min-width: 640px) and (max-width: 970px) {
            font-size: 1.5625rem;
        }
        @media only screen and (max-width: 640px) {
            font-size: 1.3125rem; 
        }
        @media only screen and (max-width: 540px) {
            font-size: 1rem;
            top: 20px;
            left: 10px;
        }
        @media only screen and (max-width: 430px) {
            font-size: 0.875rem;
        }
        @media only screen and (max-width: 370px) {
            font-size: 0.75rem;
        }
    }

    .promo-image-desktop-medium{
        @media only screen and (min-width: 375px) and (max-width: 410px) {
            font-size: .75rem;
        }
        @media only screen and (max-width: 370px) {
            font-size: 0.65rem;
        }
    }
    .promo-image-desktop-medium-top-text{
        top: 20px;

        @media only screen and (max-width: 540px) {
            top: 10px;
            right: 10px;
        }

    }
    .promo-image-desktop-large-text{
        font-size: 1.125rem; 
        top: 30px;
        @media only screen and (min-width: 768px) and (max-width: 970px) {
            font-size: 1.5625rem;
        }
        @media only screen and (min-width: 540px) and (max-width: 590px) {
            font-size: 1rem;
            top: 20px;
        }
        @media only screen and (min-width: 440px) and (max-width: 540px) {
            font-size: .8rem;
            left: 20px;
            top: 20px;
        }
        @media only screen and (min-width: 400px) and (max-width: 440px) {
            font-size: .75rem;
            left: 20px;
            top: 20px;
        }
        @media only screen and (max-width: 400px) {
            font-size: .65rem; 
            left: 10px;
            top: 10px;
        }
        
    }
}


@media only screen and (max-width: 659px) {
    
    .plp-promo-card-mobile,
    .plp-promo-card-desktop{
        max-width: auto; 
        max-height: auto;
        grid-column: span 2;
        // display: grid;
        margin-bottom: 16px;
        img{
            width: 100%;
            border-radius: 14px;
        }
    }
    .card-image-mobile{
        border-radius: 14px;
        width: 100%;
        height: 100%;
    }

    .plp-promo-banner{
        width: auto; 
        height: auto; 
        // display: grid;
        grid-column: span 2;
        margin-bottom: 16px;
        img{
            width: 100%;
            height: 142px;
            object-fit: cover;
            border-radius: 14px;
        }
    }
    .banner-promo{
        width:100%;
        height:100%;
    }
}
@media only screen and (min-width: 660px) and (max-width: 970px) {
    .plp-promo-banner{
        max-width: 100%;
        max-height: 100%;
        display: flex;
        justify-content: center;
    }
    .plp-promo-card-mobile{
        display: none;
    }
    .banner-promo{
        width:100%;
        height:100%;
        border-radius: 10px;
        border: solid 1px #f0f9f9;
    }
    .plp-promo-card-desktop{
        max-width: 100%;
        max-height: 100%;
        grid-column: span 2;
        display: grid;
        margin-bottom: 32px;
    }
    .promo-image-desktop{
        border-radius: 14px;
        width: 100%;
        height: 100%;
    }
    .plp-promo-banner-mobile{
        display:none;
    }
}

@media only screen and (min-width: 660px) and (max-width: 1260px) {
    .plp-promo-banner{
        max-width: 1876px;
        max-height: 240px;
        width: auto; 
        height: auto; 
        grid-column: span 3;
        margin-bottom: 32px;
    }
    .banner-promo{
        width: 100%;
        height: 100%;
    }
    .plp-promo-banner-mobile{
        display:none;
    }
}
@media only screen and (min-width: 1261px) {
    .plp-promo-banner{
        max-width: 1176px;
        max-height: 240px;
        width: auto; 
        height: auto; 
        grid-column: span 4;
        margin-bottom: 32px;
    }
    .plp-promo-banner-mobile{
        display:none;
    }
}
@media only screen and (min-width: 660px) and (max-width: 970px) {
    .plp-promo-banner{
    grid-column: span 2;
    }
}
@media only screen and (min-width: 971px) {
        .plp-promo-card-desktop{
            max-width: 572px;
            max-height: 438px;
            width: auto; 
            height: auto; 
            grid-column: span 2;
            display: grid;
        }
        .plp-promo-card-mobile{
            display: none;
        }
        .promo-image-desktop{
            border-radius: 14px;
            width: 100%;
            height: 100%;
        }

}
