aside.maf-offcanvas.maf-sidebar-donation-new{
    max-width: 328px;
    @include media-breakpoint-up(lg) {
        max-width: 599px;
    }
    
    .offcanvas-header{
        h2{
            &.offcanvas-title{
                // text-transform: capitalize;
            //     font-family: "SourceSansPro Semibold";
            //     font-size: 1.75em;
            //     font-weight: initial;            
            //     font-style: normal;
            //     line-height: 1;
            //     letter-spacing: normal;
            //     text-align: left;
            //     color: #051424;
            //     @include media-breakpoint-up(lg) {
            //         font-size: 2.25em;
            //     }
            }
        }
    }
    .offcanvas-body{
        .offcanvas-subtitle{
            font-family: "SourceSansPro Semibold";
            font-size: 1.3125em;
            font-weight: initial;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            margin-bottom: 16px;
            color: #457c9d;
            @include media-breakpoint-up(lg) {
                font-size: 1.3125em;
                margin-bottom: 24px;
            }

        }
        .maf-donation-component{
            #pills-tab{
                margin-bottom: 16px;
                .nav-item{
                    width: auto;
                    a{
                        font-family: "SourceSansPro Regular";
                        font-size: 1em;
                        text-align: center;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                        max-width: 113px;
                        min-width: 113px;  
                        padding: 12px 0;
                        border: solid 1px var(--bs-nav-pills-link-active-bg);
                        border-radius: 100px;
                        color: var(--bs-nav-pills-link-active-bg);
                        &.active{
                            background: var(--bs-nav-pills-link-active-bg);
                            color: #ffffff;
                        }
                        @include media-breakpoint-up(lg) {
                            max-width: 145px;
                            min-width: 145px;  
                            padding: 14px 0;
                            cursor: pointer;
                        }
                    }
                    &:last-of-type{
                        margin-left: 12px;
                        @include media-breakpoint-up(lg) {
                            margin-left: 24px;
                        }  
                    }
                }
                @include media-breakpoint-up(lg) {
                    margin-bottom: 24px;
                }
            }

            .maf-donation-component-default-form{
                input[type="radio"] {
                    opacity: 0;
                    position: fixed;
                    width: 0;
                }
                label {
                    display: inline-block;
                    padding: 14px 40px;
                    border-radius: 100px;
                    border: solid 1px #1d3557;
                    font-family: "SourceSansPro Regular";
                    font-size: 1em;
                    font-weight: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    text-align: left;
                    color: #1d3557;
                    text-align: center;
                    max-width: 72px;
                    min-width: 72px;
                    padding: 12px 0;
                    margin-left: 12px;
                    margin-bottom: 12px;
                    &:first-of-type,
                    &:nth-of-type(4){
                        margin-left: 0;                     
                    }
                    @include media-breakpoint-up(lg) {
                        max-width: 104px;
                        min-width: 104px;  
                        padding: 14px 0;
                        cursor: pointer;
                        margin-left: 24px;
                        margin-bottom: 24px;
                        &:last-of-type{
                            margin-left: 0;                     
                        }
                        &:nth-of-type(4){
                            margin-left: 24px;                     
                        }
                    }                    
                }
                input[type="radio"]:checked + label {
                    background-color: #1d3557;
                    color:#ffffff;
                }
                label:hover {
                    background-color: var(--color-light-blue);
                    cursor:pointer;
                }
                .maf_other_amount{
                    p{
                        font-family: "SourceSansPro Regular";
                        font-size: 1em;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1;
                        letter-spacing: normal;
                        text-align: left;
                        color: #051424;
                    }
                    input#onetime_donation_other_field,
                    input#monthly_donation_other_field{
                        width: 100%;
                        max-width: 280px;
                        padding: 16px;
                        border-radius: 8px;
                        border: solid 1.5px #c0c4c8;
                        background-color: #fff;
                        max-height: 50px;
                        @include media-breakpoint-up(lg) {
                            max-width: 520px;
                        }
                        &#onetime_donation_other_field,
                        &#monthly_donation_other_field{
                            padding: 16px;
                        }
                    }
                    div{
                        display: flex;
                        flex-direction: row;
                        vertical-align: middle;
                        align-items: center;
                        gap: 0.25em;
                    }
                }
                .maf-donate-button{
                    margin: 40px 0;
                    text-align: right;
                    input{
                        font-family: "SourceSansPro Semibold";
                        font-size: 1em;
                        font-weight: initial;
                        font-style: normal;
                        line-height: normal !important;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                        padding: 16px 0 !important;
                        width: 100%;
                        max-width: 131px;
                        max-height: 52px;
                        border: none;                        
                    }

                }                            
            }

        }

        .member-quote{
            padding: 40px 24px;
            border-radius: 16px;
            background-color: #e9eff3;
            margin-bottom: 40px;
            blockquote{                
                font-family: "SourceSansPro Regular";
                font-size: 1em;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: center;
                color: #051424;
                margin: 0;
                @include media-breakpoint-up(lg) {
                    font-size: 1.5em;
                }
                span{
                    font-family: "SourceSansPro Bold";
                    font-size: 2.5em;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #051424;
                    display: block;
                    margin-bottom: 8px;
                    @include media-breakpoint-up(lg) {
                        font-size: 2.6666666em;
                        margin-bottom:16px;
                    }
                    
                }
            }     
            figure{
                text-align: center;
                margin: 8px auto;
                @include media-breakpoint-up(lg) {                 
                    margin: 16px auto;
                }
                img{
                    max-width: 58px;                    
                    @include media-breakpoint-up(lg) {
                        max-width: 120px;
                    }
                }           
            }
            p{
                font-family: "SourceSansPro Regular";                            
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                font-size: 0.875em;                
                color: #051424;
                @include media-breakpoint-up(lg) {
                    font-size: 1.25em;
                }
            }               
        }
        

    }    
}
